/* eslint-disable no-irregular-whitespace */
'use strict';

var compareWidget = require('../compare-widget'),
    productTile = require('../product-tile'),
    progress = require('../progress'),
    util = require('../util'),
    wlStatus = require('../wishlist_status'),
    isStudio = require('../interaction-studio-events'),
    bannerCarousel = require('./bannerCarousel'),
    globalComponents = require('../modules'),
    stickybits = require('stickybits/dist/stickybits'); // specifically target the commonJS entry point ('main') because webpack picks default es6 entry point which gives `Uncaught TypeError: stickybits is not a function` on usage

// Redesign flag MWW-149: switch the scroll to top event on ajax call for this ticket (UX improve)

var isPLPEnabled = $('.displayColumns').data('columncount');
var enableRefresh = $('.displayColumns').data('enablerefresh');
var isPriceBucketed = $('#priceBucket').data('pricebucket');

function loadTileSwatches() {
    $('.product-tile-swatches:not(".loaded"):not(".await")').each(function() {
        var $this = $(this);
        if (util.elementInViewport($this.get(0), 250)) {
            $this.addClass("await");
            $this.load($this.data("url"), function () {
                $this.addClass("loaded").removeClass("await");
                productTile.initSwatchEvents($this);
            });
        }
    });
}

function initSortByDropdown() {
    var $main = $('#main');

    $main.off('click.sort-by').on('click.sort-by', '.btn-sort', function (e) {
        e.preventDefault();
        $main.find('.sort-by select').val($(this).attr('data-value')).trigger('change');
        $main.find('.btn-toggle-sort-by').html($(this).attr('data-method'));
    }).off('click.toggle-sortby').on('click.toggle-sortby', '.btn-toggle-sort-by', function () {
        var $parent = $(this).parent();
        $parent.toggleClass('selected');

        var isExpanded = $parent.hasClass('selected');
        $(this).attr('aria-expanded', isExpanded);
        if (isExpanded) {
            $(document).off('click.close-sortby').on('click.close-sortby', function(e) {
                if ($(e.target).closest('.select-style').length === 0) {
                    $parent.removeClass('selected');
                    $(document).off('click.close-sortby');
                }
            });
        }
    });

    if ($main.find('.sort-by select option:eq(1)').length > 0) {
        var url = new URL($main.find('.sort-by select option:eq(1)').val());
        url.searchParams.delete('srule');

        $main.find('.sort-by select option:eq(0)').attr('value', url.toString());
        $main.find('.btn-sort:eq(0)').attr('data-value', url.toString());
    }

    if ($main.find('.js-plp-sorting-selector').length > 0) {
        var selectedSortBy = $main.find('.js-plp-sorting-selector').val();
        if ($main.find('.btn-sort').length > 0) {
            var selectedSortLabel = $main.find('.btn-sort').filter(function(idx, elem) {
                return $(elem).attr('data-value') === selectedSortBy;
            }).eq(0).text().trim()

            if (!selectedSortLabel) {
                selectedSortLabel = $main.find('.btn-sort').eq(0).text().trim();
            }

            if ($main.find('.btn-toggle-sort-by').length > 0) {
                var sortby = $main.find('.btn-toggle-sort-by');
                sortby.html(sortby.data('sortbyresource') + ': ' + selectedSortLabel);
            }
        }
    }
}

function infiniteScroll() {
    // return if another page is already loading
    if ($('.infinite-scroll-loading').length > 0) {
        return;
    }
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        loadSearchPage(loadingPlaceHolder);
    }
}

function infiniteUpScroll() {
    // return if another page is already loading
    if ($('.infinite-scroll-loading').length > 0) {
        return;
    }
    // getting the hidden div, which is the placeholder for the next page
    var upscrollPlaceHolder = $('.infinite-upscroll-placeholder[data-loading-state="unloaded"]');

    if (upscrollPlaceHolder.length === 1 && util.elementInViewport(upscrollPlaceHolder.get(0), 250)) {
        loadSearchPage(upscrollPlaceHolder, true);
    }
}

// focus on the first element of the new list after a click on the 'Load more' button
function focusFirstElement() {
    $('div#search-result-content, div#new-search-result-content')
        .append('<span id="span-for-intermediate-focus" class="visually-hidden"></span>');

    $('#search-result-content .search-result-items, div#new-search-result-content .search-result-items').last().find('.js-first-prod-tile .thumb-link').focus();

    setTimeout(function () {
        $('#span-for-intermediate-focus').remove();
    }, 500);
}

function loadSearchPage(loadingPlaceHolder, upScroll) {
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    // switch state to 'loading'
    // - switches state, so the above selector is only matching once
    // - shows loading indicator
    loadingPlaceHolder.attr('data-loading-state', 'loading');
    loadingPlaceHolder.addClass('infinite-scroll-loading');

    // add a scroll triggered class to prevent prevent the Load More button from re-appearing on refinement
    $('.pt_product-search-result').addClass('scroll-triggered');

    // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
    var fillEndlessScrollChunk = function (html) {
        loadingPlaceHolder.removeClass('infinite-scroll-loading');
        loadingPlaceHolder.attr('data-loading-state', 'loaded');
        if (!upScroll) {
            loadingPlaceHolder.parent('div#search-result-content, div#new-search-result-content').append(html);
            $('.infinite-upscroll-placeholder:not(:first-child)').remove();
        } else {
            loadingPlaceHolder.parent('div#search-result-content, div#new-search-result-content').prepend(html);
            $('.infinite-scroll-placeholder:not(:last-child)').remove();
            if (window.scrollY < 300) {
                $(window).off('scroll', this, infiniteUpScroll);
                window.scrollTo({top: 500, left: 0, behavior: 'smooth'});
                setTimeout(function() {
                    $(window).on('scroll', infiniteUpScroll);
                }, 300);
            }
        }
    };

    $.ajax({
        type: 'GET',
        dataType: 'html',
        url: gridUrl,
        success: function (response) {
            fillEndlessScrollChunk(response);
            isStudio.getLoadMoreProductIds(response);
            productTile.init();
            loadTileSwatches();
            if ($('.product-usd').length) {
                $('.product-usd').removeClass('hide-USD');
            }

            var loadMoreBtns = $('.loadMore-button');
            var loadMoreBtnCount = loadMoreBtns.length;
            // remove "Load More" button from new pages loaded
            loadMoreBtns.each(function(index) {
                if (index !== (loadMoreBtnCount - 1) || $('ul.search-result-items.last').length > 0) {
                    $(this).remove();
                } else {
                    $(this).show();
                }
            });

            var $placeholders = $('.infinite-scroll-placeholder');
            var $placeholdersCount = $placeholders.length;
            $placeholders.each(function(index) {
                if (index !== ($placeholdersCount - 1)) {
                    $(this).remove();
                }
            });

            // fire a custom event
            if (pageContext.ns !== 'product') {
                $(document).trigger('newproductsloaded');
            }

            if (!upScroll) {
                focusFirstElement();
            } else {
                // scroll to the previous first products chunk
                var $previousChunk = $('.search-result-items').eq(1); // get the 2nd instance. on upscroll, the 1st instance would be the newly loaded chunk.
                if ($previousChunk.length > 0) {
                    window.scrollTo({top: $previousChunk.offset().top - 150, left: 0, behavior: 'auto'});
                }
            }
            toggleRedesignClassname();
            $(document).trigger('after:hybridTilesLoad');
        }  
    });
}

/**
 * @private
 * @function
 * @description exposes a refinement group submenu in mobile
 * @param el {object} a DOM object representing the refinement's clicked toggle trigger
 */
function exposeMobileMenuLayer(el) {
    if (util.mediaBreakpointUp('lg')) {
        return; // is desktop viewport
    }
    $(el).siblings('ul').addClass('mobile-menu-exposed');
}

/**
 * @private
 * @function
 * @description closes a refinement group submenu in mobile
 * @param el {object} a DOM object representing the refinement's clicked toggle trigger
 */
function hideMobileMenuLayer(el) {
    if (util.mediaBreakpointUp('lg')) {
        return; // is desktop viewport
    }
    $(el).closest('ul').removeClass('mobile-menu-exposed');
}

/**
 * @private
 * @function
 * @description opens the side-bar refinement slide-out menu in mobile
 */
function openSideBarRefinmentMenu() {
    if (util.mediaBreakpointUp('lg')) {
        return; // is desktop viewport
    }
    $('body').find('#secondary').addClass('in');
    $('body').find('#secondary').attr('tabindex', '1');
    $(document).trigger('freezeBackground');
    util.smartResize(function() {
        if (util.mediaBreakpointUp('lg')) {
            closeSideBarRefinementMenu();
        }
    });
}

/**
 * @private
 * @function
 * @description opens the side-bar refinement slide-out menu in desktop
 */
function openSideBarRefinmentMenuDesktop() {
    $('body').find('.primary-secondary-wrapper #secondary').addClass('in');
    $('body').find('#secondary').attr('tabindex', '1');
    $(document).trigger('freezeBackground');
    $('body').find('#primary').addClass('freeze-grid');
    $('body').find('#overlay-filter-open').addClass('overlay-drawer-open');
}

/**
 * @private
 * @function
 * @description closes the entire side bar refinement slide-out menu in mobile
 */
function closeSideBarRefinementMenu() {
    $('body').find('#secondary.in').removeClass('in').find('ul').removeClass('mobile-menu-exposed');
    $('body').find('#secondary.in').attr('tabindex', '-1');
    $(document).trigger('unfreezeBackground');
}

/**
 * @private
 * @function
 * @description closes the entire side bar refinement slide-out menu in desktop
 */
function closeSideBarRefinementMenuDesktop() {
    $('body').find('.primary-secondary-wrapper #secondary.in').removeClass('in');
    $('body').find('.primary-secondary-wrapper #secondary.in').attr('tabindex', '-1');
    $(document).trigger('unfreezeBackground');
    $('body').find('#primary').removeClass('freeze-grid');
    $('body').find('#overlay-filter-open').removeClass('overlay-drawer-open');
}

/**
 * @private
 * @function
 * @description expands a refinement group
 * @param el {object} a DOM object representing the refinement's clicked toggle trigger
 */
function expandRefinement(el) {
    if ($(el).hasClass('desktop-only-toggle') && !util.mediaBreakpointUp('lg')) {
        return; // is mobile viewport
    }
    $(el).addClass('expanded')
        .attr('aria-expanded', 'true')
        .siblings('ul').addClass('expanded');
}

/**
 * @private
 * @function
 * @description collapses a refinement group
 * @param el {object} a DOM object representing the refinement's clicked toggle trigger
 */
function collapseRefinement(el) {
    if ($(el).hasClass('desktop-only-toggle') && !util.mediaBreakpointUp('lg')) {
        return; // is mobile viewport
    }
    $(el).removeClass('expanded')
        .attr('aria-expanded', 'false')
        .siblings('ul').removeClass('expanded');
}

/**
 * @private
 * @function
 * @description expands a refinement group
 * @param el {object} a DOM object representing the refinement's clicked toggle trigger
 */
function expandDenim(el) {
    $(el)
        .addClass('expanded')
        .attr('aria-expanded', 'true');
}

/**
 * @private
 * @function
 * @description collapses a refinement group
 * @param el {object} a DOM object representing the refinement's clicked toggle trigger
 */
function collapseDenim(el) {
    $(el)
        .removeClass('expanded')
        .attr('aria-expanded', 'false');
}

/**
 * @private
 * @function
 * @description display the price range values
 * @param {string} ajax url to retrieve query string parameters
 */
function getPriceRangeValues(url) {
    var $container = $('.refinement-price-slider-slider');
   
    var pmin, pmax;

    if (typeof url === 'string' && url !== '' && url.indexOf('pmin') !== -1 && url.indexOf('pmax') !== -1) {
        var searchParams = new URLSearchParams(url.split('?')[1]);
        if($container.length > 0 && !isPriceBucketed) {
            $container.each(function() {
                var $priceRangeValues = $(this).find('.price-range-value');
                pmin = searchParams.get('pmin') || $priceRangeValues.eq(0).attr('min');
                pmax = searchParams.get('pmax').split(",").shift() || $priceRangeValues.eq(1).attr('max');
            });
        } else {
            pmin = Math.floor(searchParams.get('pmin'));
            pmax = Math.ceil(searchParams.get('pmax').split(",").shift());
        }
    } else {
        var url = window.location.href;
        var searchParams = new URLSearchParams(url.split('?')[1]);
        if(searchParams.get('pmin') && searchParams.get('pmax')) {
            pmin = Math.floor(searchParams.get('pmin'));
            pmax = Math.ceil(searchParams.get('pmax').split(",").shift());
        } else {
            $container.each(function() {
                var $priceRangeValues = $(this).find('.price-range-value');
                pmin = $priceRangeValues.eq(0).val();
                pmax = $priceRangeValues.eq(1).val();
            });
        }
    }
    updatePriceBreadcrumb(pmin, pmax);

    $container.each(function() {
        var $priceRangeValues = $(this).find('.price-range-value');
        $priceRangeValues.eq(0).val(pmin);
        $priceRangeValues.eq(1).val(pmax);
    })
    $(".value.p-min").html("$" + Number(pmin)).attr("data-pmin", Number(pmin).toFixed(2));
    $(".value.p-max").html("$" + Number(pmax)).attr("data-pmax", Number(pmax).toFixed(2));
    $container.find('.price-range-value').each(function() {
        $(this).attr('value', $(this).val())
    });
}

/**
 * @private
 * @function
 * @description update price range values on input
 * @param {element} receivedContainer to find the correct sliders elements
 */
function updatePriceDisplay($container, el) {
    if($container.length > 1) return
    var $minSlider = $container.find('.min-slider');
    var $maxSlider = $container.find('.max-slider');
    var minValueSelected = Number($minSlider.val());
    var maxValueSelected = Number($maxSlider.val());
    var minValueTotal = Number($('.global-value.min').text().split('$')[1]);
    var maxValueTotal = Number($('.global-value.max').text().split('$')[1]);
    if (minValueSelected >= minValueTotal && maxValueSelected <= maxValueTotal){
        $(".value.p-min").html("$" + minValueSelected).attr("data-pmin", minValueSelected.toFixed(2));
        $(".value.p-max").html("$" + maxValueSelected).attr("data-pmax", maxValueSelected.toFixed(2));
    }
}

/**
 * @private
 * @function
 * @param {string} value takes the price range coming from the ajax url
 * @description update price breadcrumds 
*/
function updatePriceBreadcrumb(pmin, pmax) {
    if(isNaN(pmin) || isNaN(pmax)) return
    var $breadcrumbs = $('.breadcrumb-refinement.attribute-refinement');
    $breadcrumbs.each(function() {
        var isPriceBreadcrumb = $(this).find('.breadcrumb-refinement-name').text().replace(/\n/g, '').trim().toLowerCase().includes('price');
        if(isPriceBreadcrumb) {
            var $linkElement = $(this).find('.breadcrumb-relax');
            var min = Number(pmin).toFixed();
            var max = Number(pmax).toFixed();
            $(this).find('.breadcrumb-refinement-value').text(`$${min} - $${max}`).append($linkElement);
        }
    });
}

/**
 * @private
 * @function
 * @params {element} the container element
 * @description update the track selection highlighted 
*/
// function priceTrackSelection($container) {
//     var $trackSelection = $('.track-selection');

//     var minTotal = Number($('.global-value.min').text().split('$')[1]);
//     var maxTotal = Number($('.global-value.max').text().split('$')[1]);
//     var minSelected = Number($('.value.p-min').text().split('$')[1]);
//     var maxSelected = Number($('.value.p-max').text().split('$')[1]);

//     var range = maxTotal - minTotal;
//     var minOffset = Number(((minSelected - minTotal) / range) * 100);
//     var maxOffset = Number(((maxTotal - maxSelected) / range) * 100);

//     $trackSelection.each(function() {
//         $(this).css({
//             'left': minOffset + '%',
//             'right': maxOffset + '%',
//         })
//     });
    
//     $('.track-selection-min').css({
//         'left': minOffset + '%',
//         'right': 'auto',
//     })
//     $('.track-selection-max').css({
//         'left': 'auto',
//         'right': maxOffset + '%',
//     })
// }

/**
 * @private
 * @function
 * @description takes snapshot of the current state of refinement (pre-ajax request), whether they are open or closed
 * @returns {Object} a manifest of refinement elements and their current UX states
 */
function copyRefinementsState() {
    var $secondary = $('body').find('#secondary');
    var refinementStates = {
        mobile: [],
        desktop: [],
        isDrawerOpened: $secondary.hasClass('in'),
        headerSlider: $('.category-banner').eq(0),
        openedFilter: $('.refinement.active').eq(0).find('button').eq(0).text().trim(),
        storefilter: $('#filterstoreid').prop('checked'),
    };
    // Mobile
    $secondary.find('.refinement .mobile-menu-exposed').each(function() {
        refinementStates.mobile.push($(this).closest('.refinement').data('refinement-id'));
    });
    // Desktop
    $secondary.find('.refinement .refinement-header.toggle.expanded').each(function() {
        refinementStates.desktop.push($(this).closest('.refinement').data('refinement-id'));
    });
    return refinementStates;
}

/**
 * @private
 * @function
 * @param {Array} refinementStates a manifest of refinement elements and individual exposed/hidden or expanded/collapsed states for each
 * @description applies a copy of the previous state of refinements (pre-ajax load), whether they are open or closed
 */
function reApplyRefinementsState(refinementStates) {
    var $secondary = $('body').find('#secondary');
    var $overlayFilter = $('#overlay-filter-open');
    // Mobile + Desktop (reopen drawer)
    if (isPLPEnabled) {
        $secondary.find('.refinement').each(function () {
            if (refinementStates.isDrawerOpened) {
                $secondary.addClass('no-animation').addClass('in');
                $overlayFilter.addClass('overlay-drawer-open');
                $(document).trigger('freezeBackground');
                setTimeout(function () {
                    $secondary.removeClass('no-animation');
                }, 100);
            }
        });
        // store title
        $('.category-banner').replaceWith(refinementStates.headerSlider);
        bannerCarousel.initHeaderBannerCategory();
        bannerCarousel.initDrawerBannerCategory();
        var $bannerTitle = $('.banner-title .denim-category-banner-title');
        if ($('[x-ref=container]').hasClass('expanded')) {
            $bannerTitle.addClass('expanded');
        } else {
            $bannerTitle.removeClass('expanded');
        }
    }

    // Mobile
    $secondary.find('.refinement').each(function() {
        var refId = $(this).data('refinement-id');
        if (refinementStates.mobile.indexOf(refId) > -1) {
            exposeMobileMenuLayer($(this).find('.refinement-header.toggle'));
            $secondary.addClass('in');
            return false; // break out of loop (because we only want ONE mobile menu layer exposed)
        }
    });

    // Desktop
    $secondary.find('.refinement').each(function() {
        var refId = $(this).data('refinement-id');
        if (refinementStates.desktop.indexOf(refId) > -1) {
            expandRefinement($(this).find('.refinement-header.toggle'));
        } else {
            collapseRefinement($(this).find('.refinement-header.toggle'));
        }
    });

    // Restore opened filter
    $('.refinement').filter(function(index, elem) {
        return $(elem).find('button').eq(0).text().trim() === refinementStates.openedFilter
    }).addClass('active');

    //Restore store filter state
    if(refinementStates.storefilter) {
        $('#filterstoreid').prop('checked', true);
    }
}

/**
 * Toggle the redesign classname 
 */
function toggleRedesignClassname() {
    $(window).off('resize.mobile').on('resize.mobile', function(e) {
        if ($(window).width() <= 1024) {
            $('.redesign').addClass('redesign-mobile').removeClass('redesign');
        } else {
            $('.redesign-mobile').removeClass('redesign-mobile').addClass('redesign');
        }  
    }).trigger('resize');
}

/**
 * Add the sticky filters navbar styles when the element is fixed on scroll
 */
var viewportWidth = $(window).width();
function filtersNavUpdates(){
    toggleRedesignClassname();
    if(viewportWidth >= 1025){
        var $stickyEl = $('.search-result-options.sticky-filters');
        var $stickyDetect = $('.sticky-fold-detect');
        $(window).off('scroll.sticky resize.sticky').on('scroll.sticky resize.sticky', function() {
            if ($stickyEl.length === 0) {
                return;
            }
            
            if ($(window).scrollTop() + $('.sticky .top-banner').height() >= $stickyDetect.offset().top) {
                $stickyEl.addClass('is-pinned');
                $('#search-result-items').addClass('nav-filters-fixed');
                $('.top-banner').addClass('filters-navbar-pinned');
                $stickyEl.css('top', $('.sticky .top-banner').height());
            } else {
                $stickyEl.removeClass('is-pinned');
                $('#search-result-items').removeClass('nav-filters-fixed');
                $('.top-banner').removeClass('filters-navbar-pinned');
                $stickyEl.removeAttr('style');
            }
        }).trigger('scroll.sticky');
    }
}
$(filtersNavUpdates);

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url, focusElement, isFilterBarOpened) {
    if (!url || url === window.location.href) {
        return;
    }

    
    if ($('.pt_product-search-result').hasClass('scroll-triggered')) {
        $('.loadMore-button').hide();
    }
    progress.show($('.search-result-content, #secondary.in'));
    var refinementsOpened = $('.search-refinements-section').is(':visible');
    var savedRefinementState = copyRefinementsState();

    if($('#filterstoreid').prop('checked')) {
        url = util.appendParamToURL(url, 'filterstoreid', 'true')
    }

    $.get(util.appendParamToURL(url, 'format', 'ajax'), function (data) {
        if ($(data).find('.no-results').length) {
            $($(data).find('.no-results').clone()).insertBefore('#new-search-result-content');
            $('.refinement-breadcrumbs .breadcrumb-refinement-price').remove();
            $($(data).find('.breadcrumb-refinement-price').eq(0).clone()).insertBefore('.refinement-breadcrumbs .clear-all-filters');
            $('#new-search-result-content').remove();
            $('.pagination-container .results-hits').html($('.no-results').attr('data-results'));
        } else {
            $('#main').html(data);
        }
        reApplyRefinementsState(savedRefinementState);
        compareWidget.init();
        productTile.init();
        progress.hide();
        checkCurrentSubCategory();
        filtersNavUpdates();
        initSortByDropdown();
        globalComponents.initStickyHeader();
        history.pushState(undefined, '', url);
        util.setVirtualPage();
        loadTileSwatches();
        if (($($.parseHTML(data)).filter(".canonicalURL")).length) {
            $('link[rel=canonical]').attr('href' , $($.parseHTML(data)).filter(".canonicalURL").attr('data-canonicalURL'));
        } else {
            $('link[rel=canonical]').attr('href' , url);
        }
        // Focus the given element to make sure that focus is retained through the Ajax call
        if (focusElement) {
            if (focusElement === '.price-slider') {
                $('.refinements-inner').scrollTop($('.price-slider').offset().top);
            } else {
                $(focusElement).eq(0).focus();
            }
        }

        if (isFilterBarOpened) {
            if (refinementsOpened) {
                $('.search-refinements-section').slideToggle();
            }
        } else {
            $('.search-refinements-section').slideToggle();
        }

        // fire a custom event
        $(document).trigger('productgridupdate');
        // MWW-634 hide the category filter if there is no values to select
        if ($('.pt_product-search-result').length > 0
            && $('.refinement.category.category-refinement').is(':visible')
            && $('.refinement.category.category-refinement li a').length === 0) {
            $('.refinement.category.category-refinement').addClass('hide');
        }

        if(!isPLPEnabled){
            $('.back-to-top-btn').trigger('click');
        }
        if ($('.product-usd').length) {
            $('.product-usd').removeClass('hide-USD');
        }

        // Scroll top PLP when finish ajax call
        var $filtersNav = $('.is-pinned');
        var $topOfGridProducts = $('#new-search-result-content');
        var $drawer = $('#secondary');
        if ($filtersNav.length > 0 && !$drawer.hasClass('in')) {
            $('html, body').animate({
              scrollTop: $topOfGridProducts.offset().top - 200
            }, 500,  'easeInOutCubic'); 
        }

        //Update page title
        if ($('h1[data-pagetitle]').length) {
            document.title = $('h1[data-pagetitle]').data('pagetitle');
        } else {
            document.title = $('title').data('title');
        }

        $('body').off('click', 'ul li a.js-ref-link, .btn-toggle-refinement.size ul li.swatch, .toplevel-filters-unit ul li.swatch a').on('click', 'ul li a.js-ref-link, .btn-toggle-refinement.size ul li.swatch, .toplevel-filters-unit ul li.swatch a', function() {
            var resultCount = $('.refinement-bar-section.pagingbar > div > .pagination .results-hits').text();
            var statusMessage = resultCount + ' results found';
            
            var $statusMessageContainer = $('body .search-result-options.sticky-filters .status-message-filter');
            if ($statusMessageContainer.length === 0) {
                $statusMessageContainer = $('<div class="status-message-filter vissually-hidden" aria-live="assertive" role="alert">');
                setTimeout(function() {
                    $('body .search-result-options.sticky-filters').append($statusMessageContainer);
                }, 1500);
            }
            $statusMessageContainer.empty().text(statusMessage);
            
            setTimeout(function() {
                $statusMessageContainer.fadeOut('slow', function() {
                    $(this).remove();
                });
            }, 3000);
        });

        getPriceRangeValues(url);
        // priceTrackSelection();
    });
}

/**
 * @private
 * @function
 * @description reorders the segments of a product results grid that has been pre-sorted and grouped into
 * segments (e.g. Denim Subfits) to place a specific group at the top of the grid.
 * @param segmentID {String} the data-sorted-segment-id value of all elements to move to the top of the grid
 */
function moveGridSegmentToTop(segmentID) {
    if (!segmentID || segmentID.indexOf('"') > -1) return;
    $('[data-sorted-segment-id="' + segmentID + '"]').insertBefore($('[data-sorted-segment-id]:not([data-sorted-segment-id="' + segmentID + '"]')[0]);
    $('.grid-segment-header').removeClass('first').first().addClass('first');
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $('#main');
    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });

    });

    // handle scroll of content anchor - issue with sticky
    $('.search-result-bookmarks a').on('click', function(e) {
        e.preventDefault();
        $('html,body').animate({scrollTop: ($('#results-content').offset().top)-77}, 500);
    });

    // handle toggle refinement blocks expand in desktop
    $main.on('toggle:expanded', '.refinement > a.toggle, .refinement > h3.toggle', function () {
        expandRefinement(this);
    });

    // handle toggle refinement blocks collapse in desktop
    $main.on('toggle:collapsed', '.refinement > a.toggle, .refinement > h3.toggle', function () {
        collapseRefinement(this);
    });

    // handle toggle DenimBar carousel and refinement Header accordion expand in desktop
    $main.on('click', '.denim-category-banner-title.refinement-carousel', function () {
        expandDenim(this);
        $main.find('.drawer-refinement-carousel').addClass('expanded');
        setTimeout(() => {
            bannerCarousel.initDrawerBannerCategory();
        }, 10);
    });

    // handle toggle DenimBar carousel and refinement Header accordion collapse in desktop
    $main.on('click', '.denim-category-banner-title.refinement-carousel.expanded', function() {
        collapseDenim(this);
        $main.find('.drawer-refinement-carousel').removeClass('expanded');
    });

    // handle toggle refinement blocks expand in mobile
    $main.on('click', '.refinement > a.toggle, .refinement > h3.toggle', function (e) {
        e.preventDefault();
        exposeMobileMenuLayer(this);
    });

    // handle toggle refinement blocks collapse in mobile
    $main.on('click', '.refinement .mobile-refinement-menu-header .back', function () {
        hideMobileMenuLayer(this);
    });

    // handle close refinement menu in mobile
    $main.on('click', '.search-result-options .filter-by', function () {
        openSideBarRefinmentMenu();
    });

    // handle close refinement menu in desktop
    $main.on('click', '.search-result-options .filter-by-redesign', function () {
        $(this).addClass('open-filters-disabled');
        openSideBarRefinmentMenuDesktop();
        setTimeout(() => {
            $(this).removeClass('open-filters-disabled');
        }, 1000);
    });

    // handle close refinement menu in mobile
    $main.on('click', '.mobile-refinement-menu-header .close-btn', function () {
        closeSideBarRefinementMenu();
    });

    // handle close refinement menu in desktop
    $main.on('click', '.mobile-refinement-menu-header .close-btn', function () {
        closeSideBarRefinementMenuDesktop();
    });

    // handle close refinement menu in desktop when click outside the modal
    $main.on('click', '#overlay-filter-open', function() {
        closeSideBarRefinementMenuDesktop();
    });

    //set url for BV read review link
    $('body').on('click', '#QuickViewDialog #BVRRRatingSummaryLinkReadID a', function(){
        var url = $('.product-col-1 a').attr('href') + '#BVReviewsContainer';
        window.location.href = url;
    });

    // handle events for updating grid
    $main.on('click', '.refinements a, .pagination a, .breadcrumb-refinement-value a, .reset-value', function (e) {
        // don't intercept for folder refinements, persona category links, or unselectable links
        if ($(this).is('.cat-link') || $(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable') || $(this).parents('.ispersona').length > 0) {
            return;
        }
        e.preventDefault();

        var focusElement = $(this).attr('id');

        if (focusElement) {
            focusElement = '#' + focusElement;
        }

        var isFilterBarOpened = false;
        if ($(this).hasClass('breadcrumb-relax')) {
            isFilterBarOpened = true;
        }
        if ($(this).hasClass('pagination-element')) {
            isFilterBarOpened = true;
        }
        updateProductListing(this.href, focusElement, isFilterBarOpened);
    });

    //grid layout change (2\4\6)
    $(document).on('click keypress', '.toggle-grid span', function(){
        var element = $(this),
            option = element.data('option'),
            url = window.location.href;
        url = util.removeParamFromURL(url, 'gridtype');
        url = util.appendParamToURL(url, 'gridtype', option);
        //quick fix for bad URL issue.  should eventually be fixed in util.
        if (url.indexOf('?&') != -1) {
            url = url.replace('?&','?');
        }

        history.pushState(undefined, '', url);
        util.setVirtualPage();

        $('#search-result-content, #new-search-result-content').attr('class', option);
        $('.toggle-grid span').removeClass('active');
        Cookies.set('dw_gridviewpreference', option, {expires: 30});
        element.addClass('active');

        if (window.lazySizes) {
            window.lazySizes.autoSizer.checkElems();
        }
        //apply setPosition to carousel on grid toggle preventing slider from breaking
        $('.js-product-tile-carousel').slick('setPosition');
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not("#quickviewbutton")', function () {
        var a = $(this);
        if (a.hasClass('swatch') || a.hasClass('quickview')) return true;
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);

        // get the index of the selected item and save as start parameter
        params.start = a.closest('li.grid-tile').data('index');
        // if there's a scroll parameter from a previous return to PLP, remove it
        if (params.scroll) {
            delete params.scroll;
        }

        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on('change', '.sort-by select', function (e) {
        e.preventDefault();
        var isFilterBarOpened = true;
        updateProductListing($(this).find('option:selected').val(), '.sort-by select', isFilterBarOpened);
    })
    .on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            var isFilterBarOpened = true;
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl, '.items-per-page select', isFilterBarOpened);
        }
    });

    // redesign binding only
    initSortByDropdown();

    // Close Refinements on escape keyup
    $(document).keyup(function(e) {
        if (e.keyCode == 27 || e.keyCode == 13) {
            $('.search-refinements-section').slideToggle();
        }
    });

    /**
     * @listener
     * @desc Listen for the click event on the refinement section toggle
     */
    $('body').on('click', '.refinement-select, .close-refinements', function () {
        $('.search-refinements-section').slideToggle();
    });

    /**
     * @listener
     * @desc Listen for the focus event and attach space and enter controls
     */
    $('body').on('focus', '.refinement-select, .close-refinements', function () {
        $(this).keyup(function(e) {
            //spacebar and enter click
            if (e.which === 32 || e.which === 13) {
                e.stopPropagation();
                $('.search-refinements-section').slideToggle();
            }
            //prevent scroll on space bar
            $(this).keydown(function(e) {
                if (e.which === 32) {
                    e.preventDefault();
                }
            });
        });
    });

    /**
      * @listener
      * @desc Listen for the blur on show more control and remove keyup events
      */
    $('body').on('blur', '.refinement-select, .close-refinements', function () {
        $(this).unbind('keyup');
        $(this).unbind('keydown');
    });


    /**
     * @listener
     * @desc Listen for the click event on the show hide link
     */
    $main.on('click', '.refinement .show-more', function () {
        showHideRefinements($(this));
        updateRefinementsTabbing($(this));
    });

    /**
     * @listener
     * @desc Listen for the focus event and attach space and enter controls
     */
    $main.on('focus', '.refinement .show-more', function() {
        $(this).keyup(function(e) {
            //spacebar and enter click
            if (e.which === 32 || e.which === 13) {
                e.stopPropagation();
                showHideRefinements($(this));
                updateRefinementsTabbing($(this));
            }

        });
        //prevent scroll on space bar
        $(this).keydown(function(e) {
            if (e.which === 32) {
                e.preventDefault();
            }
        });
    });

    /**
     * @listener
     * @desc Listen for the blur on show more control and remove keyup events
     */
    $main.on('blur', '.refinement .show-more', function() {
        $(this).unbind('keyup');
        $(this).unbind('keydown');
    });

    $main.on('click', '.save-refinements button', function () {
        $('.search-refinements-section').slideToggle();
        closeSideBarRefinementMenu();
        closeSideBarRefinementMenuDesktop();
    });
    //@TODO uncomment in sprint 3
    //setProperHeight();
    //$(window).resize(function() {
    //    setProperHeight();
    //})

    $('body').on('click', 'li.grid-tile a', function() {
        try {
            var scroll = $(this).closest('.grid-tile').data('index');
            if (scroll) {
                var url = window.location.href;
                url = util.removeParamFromURL(url, 'scroll');
                url = util.appendParamToURL(url, 'scroll', scroll);
                history.replaceState(null,null, url);
            }
        } catch (e) {
            return true;
        }
    });

    // STICKY SIDEBAR

    if ($('.refinements-inner').length && !enableRefresh) {
        var stickySidebarTopPadding = 10;
        var stickySidebarBottomPadding = 5;
        var sidebar = initStickySidebar(stickySidebarTopPadding, stickySidebarBottomPadding);

        $(document).on('stickyheaderchange', function () {
            if ($('html').hasClass('sticky')) {
                sidebar.options.topSpacing = util.getStickyHeaderHeight() + stickySidebarTopPadding;
                sidebar.updateSticky();
            }
        })

        $(document).on('newproductsloaded', function () {
            sidebar.updateSticky();
        })

        $(document).on('productgridupdate', function () {
            sidebar.destroy();
            sidebar = initStickySidebar(stickySidebarTopPadding, stickySidebarBottomPadding);
        });

        // Setup a new observer to update StickySidebar on sidebar height change.
        if (!util.isIE11()) {
            var sidebarObserver = new ResizeObserver(function() { // eslint-disable-line no-undef
                sidebar.updateSticky();
            });
            sidebarObserver.observe($('.refinements-sidebar')[0]);
        }
    }

    // STICKY GRIDWALL FOOTER
    stickybits('.gridwall-sticky-footer', {
        useStickyClasses: true,
        verticalPosition: 'bottom'
    });

    // Initial Grid Segment Button presentation
    let activeFound = false;
    $('[data-segment-to-sort]').each(function () {
        const segmentID = $(this).data('segment-to-sort');
        var $matchingSegments = $('.search-result-items').find('[data-sorted-segment-id="' + segmentID + '"]');
        if ($matchingSegments.length === 0) {
            // button represents a segment that did NOT render (assume due to 0 matching products)
            $(this).addClass('no-segment-found');
        } else {
            // button represents a segment that DID render
            $(this).addClass('segment-found');
            if (!activeFound) {
                // button represents the first rendered segment in the grid
                $(this).addClass('active');
                activeFound = true;
            }
        }
    });

    // Reordering Grid Segments
    $('[data-segment-to-sort]').on('click', function () {
        const segmentID = $(this).data('segment-to-sort');
        $('[data-segment-to-sort]').removeClass('active');
        $('[data-segment-to-sort="' + segmentID + '"]').addClass('active');
        moveGridSegmentToTop(segmentID);
    });

    if($('.breadcrumb-refinement-value').length > 0) {
        var drawer = $('#secondary.redesign');
        drawer.find('.refinements-inner').addClass('filters-applied');
    }
    // MWW-457 : hiding the price refinement for International Users
    if ($('.pt_product-search-result').length > 0 && $('.refinement.price').length > 0 && document.cookie.indexOf('esw.InternationalUser') !== -1) {
        if (util.getCookie('esw.InternationalUser') === "true") {
            $('.refinement.price').addClass('hide');
        } else {
            $('.refinement.price').removeClass('hide');
        }
    }

    // MWW-634 hide the category filter if there is no values to select
    if ($('.pt_product-search-result').length > 0
        && $('.refinement.category.category-refinement').is(':visible')
        && $('.refinement.category.category-refinement li a').length === 0) {
        $('.refinement.category.category-refinement').addClass('hide');
    }

    //Load visible product tile swatches
    loadTileSwatches();
    $(window).on('scroll', loadTileSwatches);

    var timeout;
    $main.off("change.price-slider", "input[type='range'].price-range-value").on("change.price-slider", "input[type='range'].price-range-value", function(){
        var $self = $(this);
        var value = $self.val();
        var pmin = $self.parents().find('.value.p-min').attr('data-pmin');
        var pmax = $self.parents().find('.value.p-max').attr('data-pmax');
        var url = $self.attr('data-href');
        url = url.replace(/pmin=\d+(\.\d+)?/, 'pmin=' + pmin).replace(/pmax=\d+(\.\d+)?/, 'pmax=' + pmax);
        $self.attr('data-priceSliderValue', value);
        $self.attr('data-href', url);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            var url = $self.attr('data-href');
            var focusElement = $self.attr('data-priceSliderValue');
            if(focusElement) {
                focusElement = '.price-slider';
            }
            var isFilterBarOpened = true;
            updateProductListing(url, focusElement, isFilterBarOpened);
        }, 50);
    });
    $main.off("input.price-slider", "input[type='range'].price-range-value").on("input.price-slider", "input[type='range'].price-range-value", function() {
        var $self = $(this),
            priceGap = $self.attr('step') ? parseInt($self.attr('step')) : 1,
            rangeInput = $self.closest('.refinement-price-slider-slider').find('input'),
            minVal = parseInt(rangeInput.eq(0).val()),
            maxVal = parseInt(rangeInput.eq(1).val()),
            $sliderContainer = $(this).parents('.refinement-price-slider-slider');

        if (maxVal - minVal < priceGap) {
            if ($self.hasClass('min-slider')) {
                rangeInput.eq(0).val(maxVal - priceGap).attr('value', maxVal - priceGap);
            } else {
                rangeInput.eq(1).val(minVal + priceGap).attr('value', minVal + priceGap);
            }
        }
        updatePriceDisplay($sliderContainer, $self);
    });
    $(getPriceRangeValues);
    $(updatePriceDisplay);
    // $(priceTrackSelection);

    if ($('.category-curvy-toggle').length && $('.category-curvy-toggle').data('hide-elements')) {
        $($('.category-curvy-toggle').data('hide-elements')).addClass('d-none');
    }

    // add constructors data to data-cnstrc-num-results
    if($('.search-result-header').length > 0) {
        $('#search-result-items').attr('data-cnstrc-num-results', $('.results-hits').data('num-results'));
        $('#search-result-items').attr('data-cnstrc-search', 'true');
    }else{
        $('#search-result-items').attr('data-cnstrc-num-results', $('.results-hits').data('num-results'));
        $('#search-result-items').attr('data-cnstrc-browse', 'true');
        $('#search-result-items').attr('data-cnstrc-filter-name', 'group_id');
        var categoryId = $('#search-result-items').attr('data-category-id');
        $('#search-result-items').attr('data-cnstrc-filter-value', categoryId ? categoryId : '');
    }
}

/**
 * Initialize the Sticky Sidebar (PLP sticky left side filters panel)
 * @param {Number} t top padding of the sticky sidebar
 * @param {Number} b bottom padding of the sticky sidebar
 * @returns StickySidebar instance
 */
function initStickySidebar (t, b) {
    var topPadding = t;
    var bottomPadding = b;
    var sidebar = new StickySidebar('.refinements-inner', { // eslint-disable-line no-undef
        topSpacing: util.getStickyHeaderHeight() + topPadding,
        bottomSpacing: bottomPadding,
        containerSelector: '#secondary',
        innerWrapperSelector: '.refinements-sidebar',
        resizeSensor: false,
        stickyClass: 'is-affixed',
        minWidth: util.getViewports('desktop') - 1
    });
    return sidebar;
}

/**
 * Shows/Hides the refinments
 */
function showHideRefinements(el) {
    var item = el,
        parent = item.parent().find('.hidden-items');
    parent.removeClass('visually-hidden');

    if (item.hasClass('displayed')) {
        item.text(Resources.SHOW_MORE);
        item.removeClass('displayed');
        parent.addClass('visually-hidden');
    } else {
        item.text(Resources.SHOW_LESS);
        item.addClass('displayed');
        parent.removeClass('visually-hidden');
    }
}

/**
 * Toggles tabindex so hidden refinements cannot be 'tabbed' through
 */

function updateRefinementsTabbing($el) {
    const $hiddenCntr = $el.parent().find('.hidden-items');
    if (!$hiddenCntr.hasClass('visually-hidden')) {
        $hiddenCntr.find('a').removeAttr('tabindex');
    } else {
        $hiddenCntr.find('a').attr('tabindex', '-1');
    }
}

function checkCurrentSubCategory() {
    $('.js-subcat-nav .js-subcat-nav-item').each(function(i,el){
        var href = $('a',el).attr('href');
        $(el).toggleClass('subcategory-nav__item--active', window.location.href === href || window.location.href.includes(href))
    });
}

/**
 * @private
 * @function
 * @description reorders the search results grid according to data attributes on particular tiles. Warning: call this BEFORE other js listeners are bound to the grid tiles.
 * @example a tile <li> with attribute `data-content-tile-grid-index="3"` means that tile should be repositioned to be the 3rd visible tile in the grid
 */
function repositionGridContentTiles() {
    // sort content tiles based on their own grid index data attribute values
    const $gridContentTiles = $('[data-content-tile-grid-index]').sort(function(a, b) {
        return $(a).data('content-tile-grid-index') - $(b).data('content-tile-grid-index') - 1;
    });
    const $grid = $('.tiles-container');
    $gridContentTiles.each(function() {
        const desiredIndex = $(this).data('content-tile-grid-index');
        const $tileToInsert = $(this).detach();
        if ($tileToInsert.children().length === 0) {
            return; // weed out empty slots
        }
        const $visibleTiles = $grid.find('.grid-tile').filter(':visible');
        if (desiredIndex <= $visibleTiles.length) {
            const $tileAtDesiredIndex = $visibleTiles.eq(desiredIndex - 1);
            $tileToInsert.insertBefore($tileAtDesiredIndex);
        } else {
            // the desired index is larger than the number of tiles in the page. Just put the tile at the end.
            const $lastTile = $visibleTiles.last();
            $tileToInsert.insertAfter($lastTile);
        }
    });
    // Also remove empty Feature Tile wrappers
    $('.feature-tile').each(function() {
        if ($(this).children().length === 0) {
            $(this).hide(0); // weed out empty slots
        }
    });
}

function intitializeFilterDropdowns() {
    $(document).off('click.cnt-refinement-dropdown').on('click.cnt-refinement-dropdown', '.btn-toggle-refinement', function() {
        var $activeRefinement = $('.refinement.active'); 
        var $parent = $(this).parent();
        var $refinement = $(this).parents('.refinement');
        $parent.toggleClass('active');
        $(this).attr('aria-expanded', $parent.hasClass('active'));
        setTimeout(function() {
            var tabsWidth = $parent.find('.switch-category-list').length > 0 ? $parent.find('.switch-category-list')[0].scrollWidth : 0;
            var $ul = $parent.find('ul');
            if ($ul.width() < tabsWidth) {
                $ul.css('min-width', tabsWidth);
            }
        }, 0);
        
        if ($activeRefinement[0] !== $refinement[0]) { 
            $activeRefinement.removeClass('active');
        }
    });
    $(document).off('click.close-refinement').on('click.close-refinement', function(e) {
        if ($(e.target).closest('.refinement').length === 0) {
            $('.refinement.active').removeClass('active');
        }
    });
}

function updateWishListItems() {
    window.addEventListener('load', function () {
        if ($('.new-search-result-content') || $('.search-result-content')) {
            $.ajax({
                type: 'GET',
                url: window.Urls.getWishListItems,
                success: function (result) {
                    sessionStorage.setItem('favItems', result.wishList);
                    if (result && result.isLoggedIn) {
                        $('.add-to-favorites').removeClass('login-modal');
                        $('.add-to-favorites').addClass('logged')
                    }
                    if (result && result.wishList && result.wishList.length > 0) {
                        for (var k = 0; k <result.wishList.length; k++) {
                            if ($('.add-to-favorites[data-pid='+result.wishList[k]+']').length) {
                                $('.add-to-favorites[data-pid='+result.wishList[k]+']').addClass('added');
                            }
                        }
                    }
                }
            });
        }
    });
}

function intiLazyloadPlpProducts() {
    /**
     * @listener
     * @desc Listen for the click event on the load more button
     */
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $(document).on('click', '.loadMoreButton', function () {
            if ($(this).hasClass("load-cached-tiles")) {
                return false;
            }
            var $placeholder = $('.infinite-scroll-placeholder');
            loadSearchPage($placeholder);
            var enabledInfiniteScroll = false;
            if (enabledInfiniteScroll) {
                $(window).one('scroll.infinite', function () {
                    infiniteScroll();
                    infiniteUpScroll();
                });
            }
            $('.loadMore-button').hide();
        });
    }

    // window.location.hash
    if (SitePreferences.LISTING_INFINITE_SCROLL && $('#scrollTile').length && $('#lazyLoadFirstPageSize').length) {
        var scrollTile = parseInt($('#scrollTile').val(), 10);
        var ourLi = $('li.grid-tile[data-index=' + scrollTile + ']');
        if (ourLi.length) {
            window.scrollTo({top: ourLi.offset().top - 150, left: 0, behavior: 'smooth'});
        }
        setTimeout(function() {
            $(window).on('scroll.infinite', infiniteUpScroll);
        }, 500);
    }
}

exports.init = function () {
    repositionGridContentTiles();
    compareWidget.init();
    productTile.init();
    initializeEvents();
    wlStatus.init();
    checkCurrentSubCategory();
    bannerCarousel.initHeaderBannerCategory();
    bannerCarousel.initDrawerBannerCategory();
    intitializeFilterDropdowns();
    updateWishListItems();
    intiLazyloadPlpProducts();
};

exports.intiLazyloadPlpProducts = intiLazyloadPlpProducts;
exports.productTile = productTile;
