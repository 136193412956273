'use strict';

var ajax = require('./ajax'),
    fluidconfigure = require('fluidconfigure/fluidconfigure'),
    progress = require('./progress'),
    util = require('./util'),
    Promise = require('promise'),
    removeProductToast = require('./removeProductToast'),
    eswPrice = require('./eswPrice');


var minicart = {
    $el: $('#mini-cart'),
    init: function () {
        // suppress minicart in Cart page
        if (window.pageContext.ns === 'cart') return false;

        this.$content = this.$el.find('.mini-cart-content');
        this.$mobileContent = this.$el.find('#product-add-to-cart-modal-container');
        this.triggerTimeout = null;
        this.delayInHeader = 750;
        this.delayInStickyHeader = 1500;
        this.closeTimeout = null;
        this.justAddedCloseDelay = 6000;
        this.maxQty = 7;


        this.$el.find('.mini-cart-link').on('mouseenter.minicart', function () {
            if (util.mediaBreakpointUp('md')) {
                var delay = minicart.delayInHeader;
                if ($('html').hasClass('sticky')) delay = minicart.delayInStickyHeader;
                minicart.triggerTimeout = setTimeout(function() {
                    minicart.slide();
                }, delay);
            }
            // if($('.mini-cart-content').find('.toast').length > 0) {
            //     $('.toast').remove();
            // }
        });

        this.$content.on('mouseleave.minicart', minicart.onMouseLeave);
        $(document).on('click.outsideminicart', minicart.onClickOutside);

        this.$el.find('.mini-cart-link').on('click touchstart', function () {
            clearTimeout(minicart.triggerTimeout);
        });

        $(document).on('click', '.mini-cart-close-btn', function() {
            minicart.close();
        });

        // Mobile Events
        minicart.close();

        $(document).off('click', '.cart-coupon-code > .coupon-header').on('click', '.cart-coupon-code > .coupon-header', function () {
            $(this).toggleClass('expanded');
        });

        $(document).on('click', '.mini-cart-pli-remove', function () {
            var id = $(this).data('uuid');
            var pid = $(this).data('productid');
            var url = util.appendParamsToUrl(Urls.minicartRemoveProudct, {id: id, pid: pid, format: 'ajax'});
            var parentProduct = $(this).parents('.mini-cart-product');
            var prodPrice = Number(parentProduct.find('.mini-cart-price').attr('data-price'));
            var gtmData = parentProduct.find('.mini-cart-name').data('gtmdata');
            var originalPrice = Number(gtmData.original_price);
            var localCurrency = gtmData.local_currency;
            var prodQuantity = Number(parentProduct.find('.mini-cart-pricing .value').val());
            var salePrice = prodPrice / prodQuantity;
            var variantData = parentProduct.find('input[name=variantData]').val() || '';     
            var prodName = parentProduct.find('.mini-cart-name');
            if (prodName.find('a').length > 0) {
                prodName = prodName.find('a').text().trim();
            } else {
                prodName = prodName.text().trim();
            }
            var pdpURL = parentProduct.find('.mini-cart-name > a').attr('href');
            var masterPId = parentProduct.find('.mini-cart-pli-remove').attr('data-masterproductid');
            var sku = parentProduct.find('[name="variantData"]').val();
            var styleColor = sku == 'null' || null ? '' : JSON.parse(sku).styleColor;
            var upc = styleColor.replace('_', '');
            var colorCode = styleColor.split('_')[1];
            var colorName = parentProduct.find('[data-attribute="color"] > .value').text();
            var size = parentProduct.find('[data-attribute="size"] > .value').text();
            var product = {};
            var isGiftCard = parentProduct.hasClass('gift-item');
            if(isGiftCard) {
                if(prodName == "Classic Gift Card") {
                    var giftItem = parentProduct.find('.gift-item-data').children('.product-list-item');
                    var recipient = giftItem.find('.attribute').eq(0).find('.value').text();
                    var fromName = giftItem.find('.attribute').eq(1).find('.value').text();
                    var giftMsg = null;
                    var classicMsg = giftItem.find('.attribute').eq(3).find('.value');
                    var msgClassic1 = classicMsg.contents().filter(function() {
                        return this.nodeType === 3;
                      }).first().text().trim();
                    var msgClassic2 = classicMsg.contents().filter(function() {
                        return this.nodeType === 3;
                      }).last().text().trim();
                    var sendDate = null;
                    var recipientEmail = null;
                    var fromEmail = giftItem.find('.attribute').eq(2).find('.value').text();
                    var giftImage = parentProduct.find('.mini-cart-image').find('img').attr('src');
                    var giftName = null;
                    var giftId = null;
                    var cardDesign = {id:giftId, name:giftName, image:giftImage};
                } else {
                    var giftItem = parentProduct.find('.gift-item-data').children('.product-list-item');
                    var recipient = giftItem.find('.attribute').eq(0).find('.value').text();
                    var fromName = giftItem.find('.attribute').eq(2).find('.value').text();
                    var giftMsg = giftItem.find('.attribute').eq(4).find('.value').text();
                    var msgClassic1 = null;
                    var msgClassic2 = null;
                    var sendDate = giftItem.find('.attribute').eq(1).find('div').eq(1).find('.value').text();
                    var recipientEmail = giftItem.find('.attribute').eq(1).find('div').eq(0).find('.value').text();
                    var fromEmail = giftItem.find('.attribute').eq(3).find('.value').text();
                    var giftImage = parentProduct.find('.mini-cart-image').find('img').attr('src');
                    var giftName = giftItem.find('.attribute').eq(1).find('div').eq(2).find('.value').text();
                    var giftId = giftName.toLowerCase().replace(/\s+/g, '-').replace(/^-+|-+$/g, '');
                    var cardDesign = {id:giftId, name:giftName, image:giftImage};
                }
                product = {
                    name: prodName,
                    dwfrm_giftcert_purchase_recipient: recipient,
                    dwfrm_giftcert_purchase_from: fromName,
                    dwfrm_giftcert_purchase_messageEGift: giftMsg,
                    dwfrm_giftcert_purchase_messageClassic1:  msgClassic1, 
                    dwfrm_giftcert_purchase_messageClassic2: msgClassic2,
                    dwfrm_giftcert_purchase_sendDate: sendDate,
                    dwfrm_giftcert_purchase_recipientEmail: recipientEmail,
                    dwfrm_giftcert_purchase_confirmRecipientEmail: recipientEmail,
                    dwfrm_giftcert_purchase_fromEmail: fromEmail,
                    pid: pid,
                    quantity: prodQuantity,
                    amount: prodPrice,
                    cardDesign: cardDesign,
                    containerIndex: parentProduct.index()
                }
            } else {
                var isPersonalizedValue = parentProduct.find('.personalization-details-mini-li').length > 0 ? "Yes" : "No";
                var isPersonalizedKey = '';
                var recipeIdValue = '';
                if (isPersonalizedValue == "Yes") {
                    isPersonalizedKey = 'dwopt_'+pid+'_isPersonalized';
                    recipeIdValue = parentProduct.find('.mini-cart-image > img').attr('src').split('recipe/')[1].split('/')[0];
                }
                product = {
                    name: prodName,
                    price: salePrice,
                    id: id,
                    pid: pid,
                    masterpid: masterPId,
                    url: url,
                    variation: variantData,
                    quantity: prodQuantity,
                    pdpurl: pdpURL,
                    upc: upc, 
                    colorcode: colorCode,
                    colorname: colorName,
                    size: size,
                    containerIndex: parentProduct.index(),
                    isPersonalized: isPersonalizedKey,
                    isPersonalizedValue: isPersonalizedValue,
                    recipeId: recipeIdValue,
                    originalPrice: originalPrice,
                    localCurrency: localCurrency
                }
            }
            product = minicart.cleanObjectValues(product);

            if (variantData !== null && variantData !== undefined && variantData !== '') {
                try {
                    variantData = JSON.parse(variantData);
                } catch (error) {
                    variantData = {};
                }
            } else {
                variantData = {};
            }
            var sku ='';
            var styleColor ='';
            var priceLocal = '';
            var variant = '';
            if (variantData && variantData.sku) {
                sku = variantData.sku;
            }
            if (variantData && variantData.priceLocal) {
                priceLocal = variantData.priceLocal;
            }
            if (variantData && variantData.variant) {
                variant = variantData.variant;
            }
            if (variantData && variantData.styleColor) {
                styleColor = variantData.styleColor;
            }

            progress.show($('.mini-cart-content'));
            $.getJSON(url, function (data) {
                if (data.success) {
                    minicart.loadInnerContent({
                        'recalculate': true
                    }, product);    

                    var isEvents = require('./interaction-studio-events');
                    isEvents.removeCartItem(pid, prodQuantity, prodPrice, false, priceLocal, sku, variant, styleColor);
                } else if (data.message) {
                    window.console.error('Error removing PLI "' + id + '": ' + data.message);
                    progress.hide();
                }
                if (typeof data.newQtyTotal !== 'undefined') {
                    minicart.$el.find('.minicart-quantity').text(data.newQtyTotal);
                }
            }).fail(function () {
                window.console.error('Error removing PLI "' + id + '"');
                progress.hide();
            });
        });

        $(document).on('click', '#minicart-items-form #add-coupon', function (e) {
            e.preventDefault();
            var code = $('#minicart-items-form input[name$="_couponCode"]').val();
            if (code.length === 0) {
                // Field is empty
                minicart.showCouponError(Resources.COUPON_CODE_MISSING);
                return;
            }

            var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
            progress.show($('.mini-cart-content'));
            $.getJSON(url, function (data) {
                if (data.success) {
                    $(document).trigger('addCouponSucess', [ {data: data} ]);
                    minicart.loadInnerContent({
                        'recalculate': true,
                        'showPromoCodes': true 
                    });
                } else if (data.status in Resources) {
                    $(document).trigger('addCouponError', [ {data: data} ]);
                    minicart.showCouponError(Resources[data.status], code);
                    progress.hide();
                } else {
                    $(document).trigger('addCouponError', [ {data: data} ]);
                    minicart.showCouponError(Resources.SERVER_ERROR);
                    progress.hide();
                }
            });
        });

        $(document).on('click', '#minicart-items-form .delete-coupon', function (e) {
            e.preventDefault();
            var code = $(this).data('code');

            if (code.length === 0) {
                // Field is empty
                return;
            }

            var url = util.appendParamsToUrl(Urls.deleteCoupon, {couponCode: code, format: 'ajax'});
            progress.show($('.mini-cart-content'));
            $.getJSON(url, function (data) {
                if (data.success) {
                    minicart.loadInnerContent({
                        'recalculate': true,
                        'showPromoCodes': true
                    });
                } else {
                    minicart.showCouponError(Resources.SERVER_ERROR);
                    progress.hide();
                }
            });
        });

        $(document).off('click.undo').on('click.undo', '.toast-flex-undo.add-to-cart',  function() {
            var $self = $(this);

            var product;
            var isGiftCard = $self.attr('data-gtmdata') && JSON.parse($self.attr('data-gtmdata')).item_name.includes('Gift Card');
            var monogrammingKey = $self.attr('data-personalized');
            var monogrammingValue = $self.attr('data-personalizedvalue');
            var recipeIdValue = $self.attr('data-recipeid');
            if (monogrammingKey !== '') {
                product = {
                    [monogrammingKey]: monogrammingValue
                };
            }
            if (isGiftCard) {
                product = {
                    dwfrm_giftcert_purchase_recipient: $self.attr('data-dwfrm_giftcert_purchase_recipient'),
                    dwfrm_giftcert_purchase_from: $self.attr('data-dwfrm_giftcert_purchase_from'),
                    dwfrm_giftcert_purchase_messageEGift: $self.attr('data-dwfrm_giftcert_purchase_messageegift'),
                    dwfrm_giftcert_purchase_messageClassic1: $self.attr('data-dwfrm_giftcert_purchase_messageclassic1'), 
                    dwfrm_giftcert_purchase_messageClassic2: $self.attr('data-dwfrm_giftcert_purchase_messageclassic2'),
                    dwfrm_giftcert_purchase_sendDate: $self.attr('data-dwfrm_giftcert_purchase_senddate'),
                    dwfrm_giftcert_purchase_recipientEmail: $self.attr('data-dwfrm_giftcert_purchase_recipientemail'),
                    dwfrm_giftcert_purchase_confirmRecipientEmail: $self.attr('data-dwfrm_giftcert_purchase_recipientemail'),
                    dwfrm_giftcert_purchase_fromEmail: $self.attr('data-dwfrm_giftcert_purchase_fromemail'),
                    pid: $self.attr('data-productid'),
                    quantity: $self.attr('data-quantity'),
                    amount: $self.attr('data-amount'),
                    cardDesign: $self.attr('data-carddesign'),
                    name: $self.attr('data-productname')
                }
            } else {
                product = {
                    quantity: $self.attr('data-quantity'),
                    cartAction: $self.attr('data-cartAction'),
                    addtocartlocation: $self.attr('data-addtocartlocation'),
                    pid: $self.attr('data-productid'),
                    masterPid: $self.attr('data-masterproductid'),
                    upc: $self.attr('data-upc'),
                    isMasterProduct: $self.attr('data-isMasterProduct'),
                    variationData: $self.attr('data-variationData'),
                    name: $self.attr('data-productname'),
                    recipeId: recipeIdValue,
                };
            }
            if (monogrammingKey !== '') {
                product[monogrammingKey] = monogrammingValue;
            }
            product = minicart.cleanObjectValues(product);
            minicart.undo(product);
        })

        // reduce quantity on click
        $(document).off('click', '.quantity-button.minus').on('click', '.quantity-button.minus', function() {
            var $self = $(this);
            var qty = Number($self.siblings('input[name="quantity"]').val());
            qty -= 1;
            if(qty == 0) {
                $(this).parent().parent().parent().siblings('.mini-cart-pli-remove').trigger('click');
            } else {
                var $closeBtn = $self.parent().parent().parent().siblings('.mini-cart-pli-remove');
                minicart.updateCartQuantity(qty, $closeBtn, minicart.returnMonogrammingData($self));
            }
        });
        // increase quantity on click
        $(document).off('click', '.quantity-button.plus').on('click', '.quantity-button.plus', function() {
            var $self = $(this);
            var qty = Number($self.siblings('input[name="quantity"]').val());
            var $closeBtn = $self.parent().parent().parent().siblings('.mini-cart-pli-remove');
            if (qty < minicart.maxQty) {
                qty += 1;
                minicart.updateCartQuantity(qty, $closeBtn, minicart.returnMonogrammingData($self));
            } else {
                return
            }
        });
        // handle input change
        $(document).off('change.type-quantity').on('change.type-quantity', 'input[name="quantity"]', function(){
            var $self = $(this);
            var qty = Number($(this).val());
            var $closeBtn = $(this).parent().parent().parent().siblings('.mini-cart-pli-remove');
            if(this.value == 0) {
                $(this).parent().parent().parent().siblings('.mini-cart-pli-remove').trigger('click');
            } else if (this.value > minicart.maxQty) {
                this.value = minicart.maxQty;
                qty = minicart.maxQty;
                minicart.updateCartQuantity(qty, $closeBtn, minicart.returnMonogrammingData($self));
            } else {
                minicart.updateCartQuantity(qty, $closeBtn, minicart.returnMonogrammingData($self));
            }
        });
        // avoid writing text
        $(document).off('input.type-quantity').on('input.type-quantity', 'input[name="quantity"]', function(){
            this.value = this.value.replace(/[^\d]/g, '');
        });
        // highlight state
        $(document).off('focus').on('focus', 'input[name="quantity"]', function() {
            $(this).parent('.quantity').addClass('input-highlight');
        });
        $(document).off('blur').on('blur', 'input[name="quantity"]', function() {
            $(this).parent('.quantity').removeClass('input-highlight');
        });
        $(document).off('click', '#minicart-items-form div.minicart-top-wrapper div.cart-coupon-offers .mobile-coupon div.coupon-header.expanded .delete-coupon').on('click', '#minicart-items-form div.minicart-top-wrapper div.cart-coupon-offers .mobile-coupon div.coupon-header.expanded .delete-coupon', function() {
            $('.mini-cart-products').css('height', 'auto');
        });
        $(document).on('click', '.coupon-header', function() {
            if($(this).hasClass('expanded')){
                var miniCartTopWrapperHeight = $('.minicart-top-wrapper').outerHeight();
                var cartCouponOffersHeight = $('.cart-coupon-offers').outerHeight();
                var newHeight = miniCartTopWrapperHeight - cartCouponOffersHeight;
                $('.mini-cart-products').css('height', newHeight);
            } else {
                $('.mini-cart-products').css('height', 'auto');
            }  
        })
    },
    returnMonogrammingData: function(el) {
        var $close = el.parent().parent().parent().siblings('.mini-cart-pli-remove');
        var pid = $close.attr('data-productid');
        var isPersonalized = 'dwopt_'+pid+'_isPersonalized';
        var isPersonalizedValue = el.closest('.mini-cart-pricing').siblings('.personalization-details-mini-li').length > 0 ? "Yes" : "No";
        if(isPersonalizedValue == "Yes") {
            var recipeId = el.closest('.mini-cart-pricing').siblings('.mini-cart-image').find('img').attr('src').split('recipe/')[1].split('/')[0];
        } else {
            var recipeId = "";
        }
        var prodPersonalized = [isPersonalized, recipeId, isPersonalizedValue];
        return prodPersonalized
    },
    undo: function (product) {
        return Promise.resolve($.ajax({
            type: 'POST',
            url: util.ajaxUrl(Urls.addProduct),
            data: product
        })).then(function (response) {
            minicart.show(response, true);
            return response;
        });
    },
    updateCartQuantity: function (qty, $closeBtn, monogrammingData) {
        if (!isNaN(qty)) {
            var pid = $closeBtn.data('productid');
            var uuid = $closeBtn.data('uuid');
            var url = Urls.updateQty;
            var param = {
                pid: pid,
                Quantity: qty
            };
            if(monogrammingData.length > 0) {
                param[monogrammingData[0]] = 'Yes';
                param.recipeId = monogrammingData[1];
            }
            param.uuid = uuid;
            param.format = 'ajax';
            url = util.appendParamsToUrl(url, param);
            progress.show();
            $.ajax({
                url: url,
                success: function(response) {
                    minicart.loadInnerContent({
                        'recalculate': true
                    });
                    if($(".mini-cart-link .minicart-quantity").length > 0){
                        $(".mini-cart-link .minicart-quantity").text($(response).find(".minicart-quantity")[0].innerText.trim())
                    }
                    minicart.slide();
                    progress.hide();
                }
            });
        }
    },
    onMouseLeave: function (e) {
        // using e.relatedTarget determines where the pointer ended up, rather than what element it left.
        // value can sometimes be null though.
        if (e.relatedTarget !== null && !$(e.relatedTarget).is('.mini-cart-content') && !$(e.relatedTarget).parents('.mini-cart-content').length) {
            minicart.close();
        }
        // if($('.mini-cart-content').find('.toast').length > 0) {
        //     $('.toast').remove();
        // }
    },
    onClickOutside: function (e) {
        if (e.target !== null && !$(e.target).is('.mini-cart-content') && !$(e.target).parents('.mini-cart-content').length) {
            minicart.close();
        }
    },
    isLoaded: false,
    /**
     * @function
     * @description Loads the given content in the mini cart and triggers a slide
     * @param {String} html A HTML string with the content which will be shown
     * @param {Boolean} justadded A boolean flag indicating whether this was triggered by an Add To Cart button click
     * @param {Boolean} isMultiSell A boolean flag indicating whether this was triggered from a "Shop the look" item quickview
     */
    show: function (html, justadded, isMultiSell) {
        // suppress minicart in Cart page
        if (!html) {
            window.console.error('No html parameter or html null');
            return false;
        }
        this.$el.html(html);
        if (window.pageContext.ns === 'cart') return false;

        this.init();
        this.slide(true, isMultiSell);             
        $('.mini-cart-link').focus();
        $(document).trigger('minicart:show');

        if (justadded) {
            $(document).on('minicart:loaded.justadded', function() {
                if (util.mediaBreakpointUp('md')) {
                    minicart.closeTimeout = setTimeout(function() {
                        minicart.close(); // wait 6 sec then close
                        clearTimeout(minicart.closeTimeout);
                    }, minicart.justAddedCloseDelay);
                    $(document).off('minicart:loaded.justadded');
                    minicart.disableQtyButtons();
                }
            });
        }
        // if($('.mini-cart-content').find('.toast').length > 0) {
        //     $('.toast').remove();
        // }
    },
    /**
      * @function
      * @description Load or refresh the inner content of the minicart panel.
      *
      * @param {Object} options
      * @param {Boolean} options.showPromoCodes Indicate that the promo code area should be expanded (shown) on load
      * @param {Boolean} options.recalculate Indicate that minicart contents should be recalculated on the back end before being rendered.
      * @param {Boolean} options.initAfterLoad Indicate that the minicart is being newly opened, and bindings should be initialized.
      */
    loadInnerContent: function (options, product) {
        var option = options ? options : {};
        var showPromoCodeArea = option.showPromoCodes || false;
        var recalculate = option.recalculate || false;
        var initAfterLoad = options.initAfterLoad || false;
        var ajaxurl = util.appendParamToURL(window.Urls.minicartcontent, 'format', 'ajax');
        if (recalculate) ajaxurl = util.appendParamToURL(ajaxurl, 'recalculate', 'true');
        progress.show($('.mini-cart-content'));
        ajax.load({
            url: ajaxurl,
            callback: function (response) {
                $('.mini-cart-content').html(response);
                fluidconfigure.initMiniLineItems();
                //check to see if mini cart product contains oos message and if so add class oos to minicart product
                $('.mini-cart-product').has($('.oos')).addClass('oos');
                //check to see if we should expose the promo code fields (if a coupon was just added, for ex)
                if (showPromoCodeArea) {
                    $('.cart-coupon-code .coupon-header').addClass('expanded');
                }
                progress.hide();
                minicart.isLoaded = true;
                $(document).trigger('minicart:loaded');
                var itemsAddedToBag;
                var lastAddedItemToCartQty = (window.location.pathname.includes('favorites') && window.screen.width < 768) ? 1 : $('.mini-cart-content').data('just-added-qty');
                if (lastAddedItemToCartQty !== 1) {
                    itemsAddedToBag = lastAddedItemToCartQty + ' items';
                    $('.mini-cart-header .count-items-added-to-bag').text(itemsAddedToBag);
                }
                if (initAfterLoad) {
                    minicart.reinitMinicartListeners();
                }
				$('body').find('#minicart-items-form').focus();
                if ($('.mini-cart-products').find('.toast').length == 0 && product) {
                    removeProductToast.init($('.mini-cart-products'), product);
                }
                minicart.disableQtyButtons();
                eswPrice();
            }
        });
    },
    /**
     * @function
     * @description Slides in and shows the contents of the mini cart
     * @param {Boolean} reload Ajax reload the minicart content
     * @param {Boolean} isMultiSell A boolean flag indicating whether this was triggered from a "Shop the look" item quickview
     */
    slide: function (reload, isMultiSell) {
        if (!$('#mini-cart').find('.mini-cart-empty').length) {
            if (!isMultiSell) {
                $('#mini-cart').addClass('active');
            }
            if (!minicart.isLoaded || reload) {
                minicart.loadInnerContent({initAfterLoad: true});
            } else {
                minicart.reinitMinicartListeners();
            }
            $('.mini-cart-link').attr('aria-expanded', 'true');
            minicart.disableQtyButtons();
            $('html').css('overflow', 'hidden');
        }
    },
    disableQtyButtons: function () {
        var $product = $('.mini-cart-product');
        if($product.length > 0) {
            $product.each(function() {
                var quantityInput = $(this).find('input[name="quantity"]');
                var plusButton = $(this).find('button.quantity-button.plus');
                if (quantityInput.val() == minicart.maxQty) {
                    plusButton.prop('disabled', true).addClass('disabled');
                }
            })
        }
    },
    /**
     * @function
     * @description Reinitialize minicart close handlers (mouseout, clickout) and minicart interaction listener (suppresses minicart close on anything but clicking X)
     */
    reinitMinicartListeners: function () {
        // Re init mouseleave handler
        minicart.$content.on('mouseleave.minicart', minicart.onMouseLeave);
        // Re init click outside to close
        $(document).on('click.outsideminicart', minicart.onClickOutside);
        // Listen for user interaction with cart contents.
        // On click, focus, or scroll, disable the mouseout handler so minicart stays open until the close X is clicked.
        $('#mini-cart *').one('click.minicartinteraction focus.minicartinteraction scroll.minicartinteraction', function () {
            minicart.$content.off('mouseleave.minicart');
            clearTimeout(minicart.closeTimeout);
        });
    },
    /**
     * @function
     * @description Closes the mini cart
     */
    close: function () {
        $('#mini-cart').removeClass('active');
        $('.mini-cart-link').attr('aria-expanded', 'false');
        $(document).off('click.outsideminicart');
        clearTimeout(minicart.triggerTimeout);
        clearTimeout(minicart.closeTimeout);
        $('html').css('overflow', 'scroll');
    },
    /**
     * @function
     * @description Sets up the focus events for the mini cart
     */
    setFocus: function() {
        // suppress minicart in Cart page
        if (window.pageContext.ns === 'cart') return false;

        /**
         * @listener
         * @description Set the aria-expanded value to true, show the mini cart and focus on the mini cart header when the mini cart icon is focused or moused over
         */
        $('.mini-cart-link').on('focus', function() {
            const miniCartContent = document.querySelector('.mini-cart-content .mini-cart-name a');
            if (!$(this).parent().next('.mini-cart-content').is(':visible') && util.mediaBreakpointUp('md')) {
                minicart.slide();

                setTimeout(function() {
                    miniCartContent.focus();
                }, 500);
            }

            $('.mini-cart-header').focus();
        });

        /**
         * @listener
         * @description Set the aria-expanded value to true or false, show/hide the mini cart on focusin/focusout for ADA accessibility
         */
        $('.mini-cart-content, .mini-cart-link').on('focusin',
            function(){
                if (util.mediaBreakpointUp('md')) {
                    minicart.slide();
                }
            }).on('focusout',
            function(e){
                if (util.mediaBreakpointUp('md') && !$(e.target).parents('.mini-cart-content').length) {
                    minicart.close();
                }
            });

        /**
         * @listener
         * @description Set the aria-expanded value to false when the mini cart is hidden
         */
        $('.mini-cart-content').on('blur', function(e) {
            if (!$(e.target).parents($(this)).length) {
                minicart.close();
            }
        });

        /**
         * @listener
         * @description Allow the user to exit out of the mini cart via keyboard via the escape key
         */
        $('.mini-cart-link, .mini-cart-content').on('keyup', function(e) {
            if (e.keyCode == 27) {
                $('.mini-cart-link').focus();
                minicart.close();
            }
        });
    },
    showCouponError: function (msg, code) {
        $('#minicart-items-form #coupon-error').text(String.format(msg, code));
    },
    cleanObjectValues: function (obj) {
        for (var key in obj) {
            if (typeof obj[key] === 'string') {
              obj[key] = obj[key].trim();
            } else if (typeof obj[key] === 'object') {
              obj[key] = minicart.cleanObjectValues(obj[key]);
            }
        }
        return obj;
    }
};

module.exports = minicart;
