'use strict';

var util = require('./util'),
    ConstructorioClient = require('@constructor-io/constructorio-client-javascript'),
    Promise = require('promise');

var currentQuery = null,
    lastQuery = null,
    runningQuery = null,
    listTotal = -1,
    listCurrent = -1,
    delay = 30,
    showLoader = true,
    $resultsContainer;
/**
 * @function
 * @description Handles keyboard's arrow keys
 * @param keyCode Code of an arrow key to be handled
 */
function handleArrowKeys(keyCode) {
    switch (keyCode) {
        case 38:
            // keyUp
            listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
            break;
        case 40:
            // keyDown
            listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
            break;
        default:
            // reset
            listCurrent = -1;
            return false;
    }

    $resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
    var selectedText = $resultsContainer.find('.selected .suggestionterm').first().text();
    $('input[name="q"]').val(selectedText);
    $('#search-status').text('Suggestion selected: ' + selectedText);
    return true;
}


/**
 * @function
 * @description Handles Constructor response
 * @param data - Response from the constructor
 */
function getConstructorResults(data) {
    var $suggetions = {};
    if (data && data.hasOwnProperty('sections')) {
        Object.keys(data.sections).forEach(function(key) {
            var $key = key.toLowerCase();
            if ($key.indexOf('suggestions') !== -1) {
                $suggetions = data.sections[key];
            }
        });
    }
    return $suggetions;
}

var searchsuggest = {
    /**
     * @function
     * @description Configures parameters and required object instances
     */
    init: function (container, defaultValue) {
        var $searchContainer = $(container);
        var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
        var $searchField = $searchForm.find('input[name="q"]');

        // disable browser auto complete
        $searchField.attr('autocomplete', 'off');

        // on focus listener (clear default value)
        $searchField.focus(function () {
            if (!$resultsContainer) {
                // create results container if needed
                $resultsContainer = $('<div/>').attr('id', 'search-suggestions').appendTo($searchContainer);
            }
            if ($searchField.val() === defaultValue) {
                $searchField.val('');
            }
        });

        this.initKeyUpEvents($searchField,$searchContainer);
        this.onSubmit($searchForm, $searchField);

        $searchContainer.on('change', '.search-select-gender [name="search-gender"]', function (e) {
            $(this).closest('form').find('[name="context"]').val($(this).val());
        });
    },
    
    /**
     * @function
     * @description reusable function to handle key up events for search and mobile nav search functions
     */
    initKeyUpEvents: function ($searchField, $searchContainer) {
        $(document).on('click', function (e) {
            if (!$searchContainer.is(e.target) && !$('.menu-toggle').is(':visible')) {
                setTimeout(this.clearResults, 200);
            }
        }.bind(this));
        // On click of clear button clear the results
        $(document).on('click', '.clear-search-field', function (e) {
            setTimeout(this.clearResults, 200);
            $('.clear-search-field').removeClass('active');
        }.bind(this));
        // on key up listener
        $(document).on('keyup', 'input[name="q"]', function (e) {

            // get keyCode (window.event is for IE)
            var keyCode = e.keyCode || window.event.keyCode;

            // check and treat up and down arrows
            if (handleArrowKeys(keyCode)) {
                return;
            }
            // check for an ENTER or ESC
            if (keyCode === 13 || keyCode === 27) {
                this.clearResults();
                return;
            }

            currentQuery = e && e.target && e.target.value ? e.target.value.trim() : '';
            if (currentQuery.length >= 2) {
                $('.clear-search-field').addClass('active');
            } else {
                $('.clear-search-field').removeClass('active');
            }

            if (currentQuery.length > 1 && $(e.target).closest('.search-overlay').find('#search-suggestions').length) {
                $(e.target).addClass('active');
            } else {
                $(e.target).removeClass('active');
            }

            // no query currently running, init an update
            if (!runningQuery) {
                runningQuery = currentQuery;
                setTimeout(this.suggest.bind(this), delay);
            }
        }.bind(this));

        $(document).on('blur', 'input[name="q"]', function (e) {
            if ($('.submit-search-placeholder').is(':visible') && $('.submit-search-placeholder').hasClass('active')) {
                this.clearResults();
                runningQuery = null;
                setTimeout(function() {
                    $(e.target).removeClass('active');
                    $(e.target).val('');
                    $('.submit-search-placeholder').removeClass('active');
                }, 200);
            }
        }.bind(this));
    },

    /**
     * @function
     * @description trigger suggest action
     */
    suggest: function () {
        // Check the constructor is enabled
        var isConstructor = SitePreferences.CONSTRUCTORENABLED;
        var $from = $('.search-overlay:visible').find('form');

        // check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
        // (we had a little delay)
        if (runningQuery !== currentQuery) {
            // update running query to the most recent search phrase
            runningQuery = currentQuery;
        }

        // if it's empty clear the results box and return
        if (runningQuery.length === 0 || (isConstructor && runningQuery.length < 3)) {
            this.clearResults();
            runningQuery = null;
            return;
        }

        // if the current search phrase is the same as for the last suggestion call, just return
        if (!isConstructor && lastQuery === runningQuery) {
            runningQuery = null;
            return;
        }

        // build the request url
        var site = $('.header-microsite-tabs .active').data('menu');
        if ($('.search-overlay.active').length && $('.search-overlay.active [name="search-gender"]').length) {
            site = $('.search-overlay.active [name="search-gender"]:checked').val()
        }
        var reqUrl = util.appendParamsToUrl(Urls.searchsuggest, {q: runningQuery, site: site});
        if (isConstructor) {
            if (SitePreferences.CONSTRUCTORENDPOINT && SitePreferences.CONSTRUCTORKEY) {
                reqUrl = `${SitePreferences.CONSTRUCTORENDPOINT}/${runningQuery}?key=${SitePreferences.CONSTRUCTORKEY}`;
            } else {
                runningQuery = null;
                return;
            }
            if (showLoader) {
                $resultsContainer.html($('.constructor-results-template').eq(0).html()).fadeIn(200);
                showLoader = false;
            }
            var constructorio = new ConstructorioClient({
                apiKey: SitePreferences.CONSTRUCTORKEY,
            });

            var constructorResponse = function(data) {
                var $results = getConstructorResults(data);
                var eswLocale = $('.ship-to-country-name').data('shipToCountry');
                if (!$results.length) {
                    this.clearResults();
                } else {
                    $resultsContainer.find('ul').empty();
                    $.each($results, function(i, item) {
                        if (i > 7) {
                            return;
                        }
                        var currentItem = item;
                        var $suggestedTerm = currentItem.hasOwnProperty('value') ? currentItem.value : '';
                        var $matechedTerms = currentItem.hasOwnProperty('matched_terms') ? currentItem.matched_terms : [];
                        var $count = currentItem.hasOwnProperty('data') && currentItem.data.hasOwnProperty('total_num_results') ? currentItem.data.total_num_results : '';
                        var constructorUrl = window.location.protocol + '//' + window.location.host + (eswLocale ? '/' + eswLocale : '') + $from.attr('action') + '?q=' + $suggestedTerm;
                        $matechedTerms.forEach(function(term) {
                            $suggestedTerm = $suggestedTerm.replace(term, `<>${term}</>`);
                        });
                        $resultsContainer.find('ul').append(`<li class="suggested-term" data-cnstrc-item-section="Search Suggestions" data-cnstrc-item-name="${$suggestedTerm.replaceAll('<>', '').replaceAll('</>', '')}"><a href="${constructorUrl}">${$suggestedTerm.replaceAll('<>', '<span>').replaceAll('</>', '</span>')}<span class="suggested-term-count">${$count}</span></a></li>`);
                    });
                    $resultsContainer.fadeIn(200);
                }

                // record the query that has been executed
                lastQuery = runningQuery;
                // reset currently running query
                runningQuery = null;

                // check for another required update (if current search phrase is different from just executed call)
                if (currentQuery !== lastQuery) {
                    // ... and execute immediately if search has changed while this server call was in transit
                    runningQuery = currentQuery;
                    setTimeout(this.suggest.bind(this), delay);
                }
                this.hideLeftPanel();
            }.bind(this);

            Promise.resolve(constructorio.autocomplete.getAutocompleteResults(runningQuery, {})).then(function(response) {
                constructorResponse(response);
            });
        } else {
            // execute server call
            $.get(reqUrl, function (data) {
                var suggestionHTML = data,
                    ansLength = suggestionHTML.trim().length;

                // if there are results populate the results div
                if (ansLength === 0) {
                    this.clearResults();
                } else {
                    // update the results div
                    $resultsContainer.html(suggestionHTML).fadeIn(200);

                    var numResults = $resultsContainer.find('.product-suggestion, .hit').length;
                    $('#search-status').text(numResults + ' suggestions available.');
                }

                // record the query that has been executed
                lastQuery = runningQuery;
                // reset currently running query
                runningQuery = null;

                // check for another required update (if current search phrase is different from just executed call)
                if (currentQuery !== lastQuery) {
                    // ... and execute immediately if search has changed while this server call was in transit
                    runningQuery = currentQuery;
                    setTimeout(this.suggest.bind(this), delay);
                }
                this.hideLeftPanel();
            }.bind(this));
        }
    },
    /**
     * @function
     * @description
     */
    clearResults: function () {
        if ($resultsContainer) { 
            $resultsContainer.fadeOut(200, function () {$resultsContainer.empty();});
            $('input[name="q"]').removeClass('active');
            showLoader = true;
        }
    },
    /**
     * @function
     * @description
     */
    hideLeftPanel: function () {
        //hide left panel if there is only a matching suggested custom phrase
        if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
            $('.search-suggestion-left-panel').css('display', 'none');
            $('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper');
            $('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
        }
    },
    
    onSubmit: function ($searchForm) {
        $searchForm.on('submit', function (e) {
            var $form = $(e.currentTarget);
            var $q = $form.find('[name="q"]').val();
            var eswLocale = $('.ship-to-country-name').data('shipToCountry');
            if ($q === '') {
                e.preventDefault();
                return false;
            }
            if ($q !== '' && (SitePreferences.CONSTRUCTORENABLED || SitePreferences.CONSTRUCTORSEARCH)) {
                e.preventDefault();
                var $url = window.location.protocol + '//' + window.location.host + (eswLocale ? '/' + eswLocale : '') + $form.attr('action') + '?q=' + $q;
                if (!SitePreferences.CONSTRUCTORENABLED) {
                    $url += '&context=' + $form.find('[name="context"]').val();
                }
                window.location.href = $url
                return false;
            }
        });
    }
};

module.exports = searchsuggest;
