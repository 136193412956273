'use strict';
var abCampaigns = require('../../../org_jcrew/cartridge/js/ab-campaigns');
var tls = require('../../../org_jcrew/cartridge/js/tls');

/**
 * Events are divided up by name space so only the
 * events that are needed are initialized.
 */
var events = {
    fulfillments: window.Constants.fulfillments,
    account: function () {},
    // events that should happen on cart page
    cart: function () {
        $(document).on('click', '.cart-row .save-later', function (e) {
            if (window.User && window.User.authenticated) {
                var link = $(this);
                var opts = {};
                opts.listID = 'madewell:cart:shopping bag';
                opts.listName = 'shopping bag';
                var container = link.parents('.cart-row');
                var pid = link.data('masterproductid');
                var productElement = link.closest('.full-top').find('.product-list-item');
                var productName = productElement.find('.name a').text().trim();
        
                if (!pid || pid.length === 0) {
                    pid = container.find('.cart-pid').val();
                }
        
                var obj = {
                    'event': 'add_to_saved_for_later',
                    'item_name_event': productName,
                    'product_id': pid,
                    'item_id_event': pid,
                    'can_be_fulfilled': 'yes',
                    'fulfillment_issue': undefined,
                };
        
                var method = container.find('input[name^="delivery_option"]:checked').data('fulfillMethod');
                if (method) {
                    obj.fulfillment_method = method;
                }
        
                if (container.hasClass('full-out-of-stock')) {
                    obj.fulfillment_issue = 'out of stock';
                    obj.can_be_fulfilled = 'no';
                }
        
                var data = link.next().val();
                productClick(JSON.parse(data), opts);
                pushToDataLayer(obj);
            }
        });
        $(document).off('click.remove_from_saved_for_later', '.sfl-item .remove').on('click.remove_from_saved_for_later', '.sfl-item .remove',  function() {
            var productName = $(this).parents('.sfl-item-details').find('.sfl-product-name a').text().trim();
            var obj = {
                'event': 'remove_from_saved_for_later',
                'product_id': $(this).parents('.sfl-item').attr('data-pid'),    
                'item_id_event': $(this).parents('.sfl-item').attr('data-pid'),
                'item_name_event': productName,         
                'fulfillment_method': 'Ship to Home',
                'can_be_fulfilled': 'yes',
                'fulfillment_issue': undefined,
            };
            var isOutOfStock = $(this).parents('.sfl-item-details').children('.oos');
            if (isOutOfStock.length) {
                obj.fulfillment_issue = 'out of stock';
                obj.can_be_fulfilled = 'no';
            }
            pushToDataLayer(obj);
        });
        var storeSelected = false;
        $(document).on('click', '.js-set-preferred-store', function (e) {
            if ($(this).text().includes('Change')) {
                storeSelected = true;
            }
        });
        $(document).on('click', '.ispu-select-store .store-list .select-store-button', function (e) {
            storeCompleted(this, 'cart', storeSelected);
            storeSelected = false;
        });
        var gaData = $('#ga360Data').length ? $('#ga360Data').val() : null;
        var ga360CODetails = $('#ga360CODetails').length ? $('#ga360CODetails').val() : null;
        if(gaData && gaData.length && ga360CODetails && ga360CODetails.length) {
            try {
                gaData = JSON.parse(gaData);
            } catch (e) {
                gaData = null;
            }
            try {
                ga360CODetails = JSON.parse(ga360CODetails);
            } catch (e) {
                ga360CODetails = null;
            }
            if (gaData && gaData !== null && ga360CODetails && ga360CODetails !== null) {
                pushToDataLayer({ecommerce: null});
                var methods = findFulFillMethods(gaData.items);
                ga360CODetails.fulfillment_method = methods;
                ga360CODetails.fulfillment_issue_count = $('.full-out-of-stock').length;
                ga360CODetails.ecommerce.currency = gaData.currency ? gaData.currency : '';

                if (gaData.items) {
                    gaData.items.forEach(fillUndefinedParameters);
                }
                ga360CODetails.ecommerce.items = gaData.items ? gaData.items : [];

                pushToDataLayer(ga360CODetails);
            }
        }
        $(document).on('click', '.paypal-button', function (e) {
            pushCheckoutStartEvent('paypal');
        });
        $(document).on('click', '.dw-apple-pay-button', function (e) {
            pushCheckoutStartEvent('apple_pay');
        });
        $(document).on('click', '#btnCheckout', function (e) {
            pushCheckoutStartEvent('regular_checkout');
        });
        //when a quantity input from a cart product is chaged
        $(document).on('focusin', '.cart-row .quantity .value', function(){
            $(this).attr('data-oldval', $(this).val());
        }).on('change', '.cart-row .quantity .value', function () {
            $(document).trigger('quantityChange', {select: $(this), isMinicart: false});
        });
        //when clicing on minus or plus simbol in a cart product
        $(document).on('click', '.cart-row .quantity .quantity-button', function(){
            $(document).trigger('quantityChange', {select: $(this), isMinicart: false, isPlusMinus: true});
        });
        $('[name$=_deleteProduct]').on('click', function () {
            var select = $(this);
            var container = select.parents('.cart-row');
            var data = container.find('a[data-gtmdata]').data('gtmdata');
            if (data) {
                var fulfillmentMethod = container.find('input[name^="delivery_option"]:checked').data('fulfillMethod');
                var opts = {
                    clickLocation: 'cart',
                    fulfillmentMethod: fulfillmentMethod,
                    qty: data.quantity
                };
                if (container.hasClass('full-out-of-stock')) {
                    data.fulfillment_issue = 'out of stock';
                    data.can_be_fulfilled = 'no';
                } else {
                    data.can_be_fulfilled = 'yes';
                }
                removeFromCart(data, opts);
            }
        });
        $('.item-delivery-options .home-delivery').each(function() {
            try {
                var spanSelector = $(this).find('span');
                var bannerText = spanSelector.text() ? spanSelector.text().toLowerCase().trim() : '';
                var fulfillmentMethod = $(this).parents('.cart-row').find('input.delivery-option:checked')[0].dataset.fulfillMethod;
        
                if (bannerText.length > 0) {
                    pushToDataLayer({
                        'event': 'fulfillment_banner_impression',
                        'fulfillment_method': fulfillmentMethod,
                        'banner_message': bannerText
                    });
                }
            } catch (error) {
                // We dont need to send an error this try catch is for the case delivery-option is null and dont break the js
            }
        });
        $('.item-delivery-options .instore-delivery').each(function() {
            var spanSelector = $(this).find('span');
            var bannerText = spanSelector.text() ? spanSelector.text().toLowerCase().trim() : '';
            var fulfillmentMethod = $(this).parents('.cart-row').find('input.delivery-option:checked')[0].dataset.fulfillMethod;
            if (bannerText.length > 0) {
                pushToDataLayer({
                    'event': 'fulfillment_banner_impression',
                    'fulfillment_method': fulfillmentMethod,
                    'banner_message': bannerText
                })
            }
        });
        $('.item-quantity .product-availability-list .not-available').each(function() {
            var bannerText = $(this).text().toLowerCase().trim();
            var fulfillmentMethod = $(this).parents('.cart-row').find('input.delivery-option:checked')[0].dataset.fulfillMethod;
            if (bannerText.length > 0) {    
                pushToDataLayer({
                    'event': 'fulfillment_banner_impression',
                    'fulfillment_method': fulfillmentMethod,
                    'banner_message': bannerText
                })
            }
        });
        $('.cart-availability-messaging-row .product-availability-list .on-order').each(function() {
            var bannerText = $(this).text() ? $(this).find('.on-order-label').text() + ' ' + $(this).find('.on-order-msg').text() + $(this).find('.on-order-body > .content-asset > div').text() : '';
            var fulfillmentMethod = $(this).parents('.cart-availability-messaging-row').prev().find('input.delivery-option:checked')[0].dataset.fulfillMethod;
            if (bannerText.length > 0) {
                pushToDataLayer({
                    'event': 'fulfillment_banner_impression',
                    'fulfillment_method': fulfillmentMethod,
                    'banner_message': bannerText.toLowerCase().trim()
                })
            }
        });
        $('.egiftcard .item-delivery-options').each(function() {
            var spanSelector = $(this).find('span');
            var bannerText = spanSelector.text() ? spanSelector.text().toLowerCase().trim() : '';
            if (bannerText.length > 0) {
                pushToDataLayer({
                    'event': 'fulfillment_banner_impression',
                    'fulfillment_method': 'virtual giftcard shipment',
                    'banner_message': bannerText
                })
            }
        });
        var urlParams = new URLSearchParams(window.location.search);
        var error = urlParams.get('error');
        if (error) {
            pushToDataLayer({
                'event': 'error_occurred',
                'error_code': error
            });
        }
    },
    checkout: function () {
        var gaData = $('#ga360Data').length ? $('#ga360Data').val() : null;
        var ga360CODetails = $('#ga360CODetails').length ? $('#ga360CODetails').val() : null;
        if(gaData && gaData.length && ga360CODetails && ga360CODetails.length) {
            try {
                gaData = JSON.parse(gaData);
            } catch (e) {
                gaData = null;
            }
            try {
                ga360CODetails = JSON.parse(ga360CODetails);
            } catch (e) {
                ga360CODetails = null;
            }
            if (gaData && gaData !== null && ga360CODetails && ga360CODetails !== null) {
                pushToDataLayer({ecommerce: null});
                ga360CODetails.ecommerce.currency = gaData.currency ? gaData.currency : '';
                
                if (gaData.items) {
                    gaData.items.forEach(fillUndefinedParameters);
                }
                ga360CODetails.ecommerce.items = gaData.items ? gaData.items : []
                pushToDataLayer(ga360CODetails);
            }
        }
        $(document).on('click', '.js-checkout-guest-btn', function (e) {
            var customerTier = e.target.dataset.customerTier;
            pushToDataLayer({
                'event': 'continue_as_guest_tapped',
                'user_status': e.target.dataset.customerStatus || undefined,
                'user_loyalty_member_tier': customerTier && !customerTier.toLowerCase().includes('guest') ? customerTier : undefined
            });
        });
    },
    compare: function () {},
    product: function (event) {
        $(document).on('click', '.js-set-preferred-store', function (e) {
            if (User.storeId) {
                pushToDataLayer({
                    'event': 'change_store',
                    'store_id': User.storeId
                });
            }
        });
        $(document).on('customFisModalOpened', function (e, data) {
            pushToDataLayer({
                'event': 'product_find_in_store',
                'product_id': data.pid
            });
        });
        $(document).on('change', '.js-delivery-option-store', function () {
            pushToDataLayer({
                'event': 'product_in_store_filter',
                'filter_key': 'being_in_store',
                'filter_value': $(this).is(':checked')
            });
        });
        var container = $('#pdpMain');
        if (container) {
            var gtmdata = container.find('.product-detail').find('[data-gtmdata]').data('gtmdata');
            $(document).trigger('productView', {
                data: gtmdata,
                container: container
            });
            $(document).on('click', '.ispu-select-store .store-list .select-store-button', function (e) {
                var storeSelected = $(container).find('.selected-store-name').length > 0;
                storeCompleted(this, 'PDP', storeSelected);
            });
            $(document).on('click', '.BVRRRatingSummaryNoReviewsWriteImageLink a, .BVRRRatingSummaryLinkWriteFirst a, .BVRRRatingSummaryLinkWrite a', function (e) {
                pushToDataLayer({
                    'event': 'product_write_review',
                    'product_id': gtmdata.item_id
                });
            });
            $(document).on('click', '.BVRRRatingSummaryLinkRead a', function (e) {
                pushToDataLayer({
                    'event': 'product_see_reviews',
                    'product_id': gtmdata.item_id
                });
            });
            $(document).on('click', '.BVDI_QTFilterList .BVDI_QTFilterHover label', function (e) {
                pushToDataLayer({
                    'event': 'product_filter_reviews',
                    'product_id': gtmdata.item_id,
                    'filter_key': $(this).parents('.BVDIBody').siblings('.BVDIHeader').find('.BVDILinkSpan').text().trim(),
                    'filter_value': $(this).children('.BVDIValue').text().trim()
                });
            });
            $(document).on('change', '.BVRRDisplayContentSort .BVRRDisplayContentSelect', function (e) {
                pushToDataLayer({
                    'event': 'product_sort_review',
                    'product_id' : gtmdata.item_id,
                    'method': $(this).children('option:selected').text().trim()
                });
            });
            $(document).on('click', '.BVDI_FVVotesHelpfulness .BVDI_FVPositive a', function (e) {
                pushToDataLayer({
                    'event': 'product_review_helpful_yes',
                    'product_id': gtmdata.item_id
                });
            });
            $(document).on('click', '.BVDI_FVVotesHelpfulness .BVDI_FVNegative a', function (e) {
                pushToDataLayer({
                    'event': 'product_review_helpful_no',
                    'product_id': gtmdata.item_id
                });
            });
            var deliveryOption = container.find('input[name^="delivery_option"]:checked').val();
            if (deliveryOption === 'home') {
                pushToDataLayer({
                    'event': 'product_ship_to_home',
                    'product_id': gtmdata.item_id
                });
            } else if (deliveryOption === 'store') {
                pushToDataLayer({
                    'event': 'product_store_pickup',
                    'product_id': gtmdata.item_id
                });
            }
        }
    },
    search: function () {
        $(document).on('change', '.js-plp-sorting-selector', function () {
            pushToDataLayer({
                'event': 'product_list_sort',
                'method': $(this).find('option:selected').data('method')
            });
        });
        $(document).on('click', '.refinements-sidebar, .toplevel-filters, .priority-filters', function(e) {
            plpApplyFilter(e)
        });
    },
    storefront: function () {
        addPromoIdToHomePageItems();
    },
    wishlist: function () {
        $('[name$=_addToCart]').on('click', function () {
            var container = $(this).parents('.option-add-to-cart').first();
            var isVariant = container.find('input[name="isVariant"]').val();
            if (isVariant != 'false') {
                var fulfillmentMethod = events.fulfillments.home;
                var qty = 1;
                var clickLocation = 'wishlist';
                var opts = {
                    qty: qty,
                    clickLocation: clickLocation,
                    fulfillmentMethod: fulfillmentMethod
                };
                addToCart(JSON.parse($(this).attr('data-gtmdata')), opts);
            }
        });
        $(document).on('click', 'button.remove-from-favorites', function(e) {
            var pid = $(this).data('pid');
            $(document).trigger('customRemoveFromFavorites', [ {pid: pid} ]);
        });
    },
    // events that should happen on every page
    all: function () {
        var ga360Error = $('#ga360Error').val();
        if (ga360Error && ga360Error.length) {        
            ga360Error = JSON.parse(ga360Error);
            pushToDataLayer({ecommerce: null});
            pushToDataLayer({
                'event': 'error_occurred',
                'error_code': ga360Error.code,
                'error_msg_tech': ga360Error.message
            });
        }
        $(document).on('productView', function (e, obj) {
            var productObject = obj.data;
            var opts = {};
            if (obj.container) {
                var methods = obj.container.find('input[name^="delivery_option"]');
                if (methods.length > 0) {
                    var parsedMethod = obj.container.find('input[name^="delivery_option"]').map(function () {
                        return $(this).data('fulfillMethod');
                    }).toArray().join('|');
                    opts.fulfillmentMethod = parsedMethod;
                }
                if (productObject) {
                    // push pdp_interaction when select new fit
                    handleProductInteraction('fit', '.extended-sizing-tiles', obj.container, productObject);
                    // push pdp_interaction when select new color
                    handleProductInteraction('swatch', '.swatches.color', obj.container, productObject);
                    //push pdp_interaction when select new size
                    handleProductInteraction('size', '.swatches.size', obj.container, productObject);
                }
            }

            if (productObject) {
                var isGiftcard = obj.container.find('.add-to-cart.giftcard');
                if (isGiftcard.length) {
                    //set item_category if isGiftcard
                    productObject.item_category = 'Giftcard';
                } else {
                    var isSoldOut = obj.container.find('.pdp-soldout');
                    if (isSoldOut.length === 0) {
                        setProductValues(productObject, obj.container);
                    }
                }

                var queryParams = new URLSearchParams(window.location.search);
                var listId;
                var utmMedium = queryParams.get('utm_medium');
                var utmSource = queryParams.get('utm_source');
                if ( utmMedium && utmSource && utmMedium.length > 0 && utmSource.length > 0) {
                  listId = utmMedium + ' - ' + utmSource;
                }

                var pdpBreadcrumb = $('.breadcrumb-element.breadcrumb-cat a').map(function() {
                    return $(this).text();
                }).get().join(':').toLowerCase().trim();

                var pageViewDataLayer = dataLayer.filter(pageView => pageView.event === 'page_view');

                if (pageViewDataLayer) {
                    var itemListName = pageViewDataLayer[0].page_breadcrumb.split(pageViewDataLayer[0].page_division + ':').pop();
                    var itemListId = 'madewell:' + pageViewDataLayer[0].page_breadcrumb;
                    
                    productObject.item_list_id = listId || itemListName;
                    productObject.item_list_name = listId || itemListId;

                    setProductFindingMethod(productObject.item_id, itemListId, itemListName);
                } else if (pdpBreadcrumb) {
                    productObject.item_list_id = listId || 'madewell:' + pdpBreadcrumb;
                    productObject.item_list_name = listId || pdpBreadcrumb;
                } else {
                    productObject.item_list_id = listId || ('madewell:' + (opts.clickLocation || getClickLocation()));
                    productObject.item_list_name = listId || opts.clickLocation || getClickLocation();
                }
                productView(productObject, opts);
            }
        });
        //when a quantity input from a minicart product is chaged
        $(document).on('focusin', '.mini-cart-product .quantity .value', function(){
            $(this).attr('data-oldval', $(this).val());
        }).on('change', '.mini-cart-product .quantity .value', function (e) {
            console.log(e.oldValue);
            $(document).trigger('quantityChange', {select: $(this), isMinicart: true});
        });
        //when clicing on minus or plus simbol in a minicart product
        $(document).on('click', '.mini-cart-product .quantity .quantity-button', function(){
            $(document).trigger('quantityChange', {select: $(this), isMinicart: true, isPlusMinus: true});
        });
        //when quantity changes on cart or minicart product
        $(document).on('quantityChange', function (e, {select, isMinicart, isPlusMinus}) {
            var container = isMinicart ? select.parents('.mini-cart-product:first') : select.parents('.cart-row');
            var data = container.find('[data-gtmdata]').data('gtmdata');
            if (data) {
                var fulfillmentMethod = container.find('input[name^="delivery_option"]:checked').data('fulfillMethod') || 'Ship to Home';
                var opts = {
                    clickLocation: getClickLocation() === 'cart' ? 'cart' : 'minicart',
                    fulfillmentMethod: fulfillmentMethod,
                };

                if (container.hasClass('full-out-of-stock') || container.hasClass('oos')) {
                    data.fulfillment_issue = 'out of stock';
                    data.can_be_fulfilled = 'no';
                } else {
                    data.can_be_fulfilled = 'yes';
                }

                //set price if minicart
                var miniCartPrice = container.find('.mini-cart-price');
                if (miniCartPrice.length > 0) {
                    var prodQtySelector = container.find('.mini-cart-pricing .value');
                    var prodQuantity = Number(prodQtySelector.data('oldval') ? prodQtySelector.data('oldval') :  prodQtySelector.val());
                    data.price = (Number(miniCartPrice.attr('data-price')) / prodQuantity).toFixed(2);
                    data.discount = (Number(data.original_price) - Number(data.price)).toFixed(2);
                }

                if (isPlusMinus) {
                    opts.qty = 1;
                    if (select.hasClass('plus')) {
                        addToCart(data, opts);
                    } else if (select.hasClass('minus')) {
                        removeFromCart(data, opts);
                    }
                } else {
                    var currentQty = Number(select.data('oldval'));
                    var newQty = Number(select.val());
                    opts.qty = Math.abs(currentQty - newQty);
                    currentQty > newQty ? removeFromCart(data, opts) : addToCart(data, opts);
                }
            }
        });
        // when removing a minicart product
        $(document).on('click', '.mini-cart-pli-remove', function(){
            var container = $(this).parents('.mini-cart-product:first');
            var data = $(this).data('gtmdata');
            if (data) {
                var fulfillmentMethod = container.find('input[name^="delivery_option"]:checked').data('fulfillMethod') || 'Ship to Home';
                var opts = {
                    clickLocation: 'minicart',
                    fulfillmentMethod: fulfillmentMethod,
                };

                //set price if minicart
                var miniCartPrice = container.find('.mini-cart-price');
                if (miniCartPrice.length > 0) {
                    var prodQtySelector = container.find('.mini-cart-pricing .value');
                    var prodQuantity = Number(prodQtySelector.data('oldval') ? prodQtySelector.data('oldval') :  prodQtySelector.val());
                    data.price = (Number(miniCartPrice.attr('data-price')) / prodQuantity).toFixed(2);
                    data.discount = (Number(data.original_price) - Number(data.price)).toFixed(2);
                }

                if (container.hasClass('full-out-of-stock') || container.hasClass('oos')) {
                    data.fulfillment_issue = 'out of stock';
                    data.can_be_fulfilled = 'no';
                } else {
                    data.can_be_fulfilled = 'yes';
                }
                
                removeFromCart(data, opts);
            }
        });
        $(document).on('productImpression', function () {
            var plpGrid = $('#new-search-result-content #search-result-items');
            if (plpGrid.length > 0) {
                var listID = 'madewell:' + $(location).attr('pathname').replace('/', '').replaceAll('/', ':');
                var gender = getGender();
                var listName = listID.split(gender + 's:').pop();
                pushImpression(plpGrid, listName, listID);
            };

            var recentlyViewed = $('#IsRecentlyViewed').siblings('[id^="mt-grid-"]');
            if (recentlyViewed.length > 0) {
                var listName = recentlyViewed.find('.mt-grid-head')?.text()?.toLowerCase().trim();
                var listID = 'madewell:' + getClickLocation() + ':' + listName;
                pushImpression(recentlyViewed, listName, listID);
            }

            var cartRecommendations = $('.cart-recommendations');
            if (cartRecommendations.length > 0) {
                var listName = cartRecommendations.find(".recommendation-tab.selected")?.text()?.toLowerCase().trim();
                var listID = 'madewell:' + getClickLocation() + ':' + listName;
                pushImpression(cartRecommendations, listName, listID);
            } else {
                var productListing = $(".product-listing");
                if (productListing.length > 0) {
                    var listName = productListing.children("h3")?.text()?.toLowerCase().trim();
                    var listID = 'madewell:' + (window.pageContext.ns === 'product' ? 'pdp:product recs:' : 'product recs:') + listName;
                    pushImpression(productListing, listName, listID);
                }
            }
        });
        $(document).on('click', '.add-to-cart', function () {
            var button = $(this);
            var container = button.parents('.product-detail');
            var isSaveForLater = button.parents('.sfl-item-buttons');
            var isMinicart = button.hasClass('toast-flex-undo');
            var clickLocation = isMinicart ? 'minicart' : (isSaveForLater.length > 0 ? 'Save for Later' : ( container.find('input[name="addtocartlocation"]').val() || window.pageContext.ns ));
            var valid = true;
            var isWl = ('pageContext' in window && 'ns' in window.pageContext && window.pageContext.ns === 'wishlist');
            if (isWl) {
                // only trigger event on variant
                valid = button.hasClass('atc-valid');
                // check if we on qv
                if (!valid && clickLocation === 'quickview') {
                    valid = true;
                } else // prevent duplicated call
                if (clickLocation === 'wishlist') {
                    valid = false;
                }
            }
            if (valid) {
                var fulfillmentMethod = events.fulfillments.home;
                var tmpMethod = container.find('input[name^="delivery_option"]:checked').data('fulfillMethod');
                if (tmpMethod) {
                    fulfillmentMethod = tmpMethod;
                }
                var obj = JSON.parse(button.attr('data-gtmdata'));
                var qty = Number(container.find('[name="Quantity"]').val()) || 1;
                var currency = obj.local_currency;
                var opts = {
                    qty: qty,
                    currency: currency,
                    clickLocation: clickLocation,
                    fulfillmentMethod: fulfillmentMethod
                };
                setProductValues(obj, container);
                if ('styliticsWidget' in window && 'trackAddItemToCart' in window.styliticsWidget) {
                    window.styliticsWidget.trackAddItemToCart(obj.style_color, obj.price);
                }
                addToCart(obj, opts);
            }
        });
        //Save data into selected_item when selecting a product at plp or carousel that have thumb-link class
        $(document).on('click', '.name-link, .thumb-link, .grid-tile .product-tile .thumb-link, .grid-tile .product-tile .product-tile-details', function () {
            var pageViewDataLayer = dataLayer.filter(pageView => pageView.event === 'page_view');
            var data = JSON.parse($(this).attr('data-gtmdata'));
            var opts = {
                listID: null
            };
            if($(this).parents(".product-listing").length > 0){
                opts.listName = $(this).parents(".product-listing").children("h3").text().toLowerCase().trim();
                if (window.pageContext.ns === 'product') {
                    opts.listID = 'madewell:pdp:product recs:' + opts.listName;
                } else {
                    opts.listID = 'madewell:product recs:' + opts.listName;
                }
            }else{
                opts.listName = pageViewDataLayer[0].page_breadcrumb.split(pageViewDataLayer[0].page_division + ':').pop();
                opts.listID = 'madewell:' + pageViewDataLayer[0].page_breadcrumb;
            }
            
            productClick(data, opts);
        });
        //Save data into selected_item in cart when user when selecting a product at "shopping bag" or "saved for later" at cart page
        $(document).on('click', '.sfl-product-name>a, #cart-table .product-list-item .name a', function () {
            var data, opts = {};
            if($(this).parents().hasClass("sfl-product-name")){
                data = $(this).parents(".sfl-item-details").find('.sfl-item-buttons .button.add-to-cart').data('gtmdata');
            }else{
                data = $(this).data('gtmdata');
                opts.listID = 'madewell:cart:shopping bag'
                opts.listName = 'shopping bag'
            }
            
            productClick(data, opts);
        });
        $(document).on('submit', '#email-alert-signup', function (e) {
            e.preventDefault();
            var footerSignUp = 'footer - email signup';
            var contentSignUp = 'content - email signup';
            var isContent = $(this).closest('.footer-item').length > 0;

            pushToDataLayer({
                'event': 'lead',
                'action_type': isContent ? footerSignUp : contentSignUp,
            });
        });
        $(document).on('click', '.mini-cart-name a', function () {
            var data, opts = {};
            if($(this).parent().attr("data-gtmdata")){
                data = JSON.parse($(this).parent().attr("data-gtmdata"));
            }
            opts.listID = 'madewell:mini-cart:bag'
            opts.listName = 'mini-cart'
            
            productClick(data, opts);
        });
        $(document).on('click', '[id^="mt-grid-"] .mt-link', function () {
            var merchItem = $(this).parents('.mt-merch-item');

            var data = {
                item_id: merchItem.data('pid')?.split('_')[0] || undefined,
                color_code: merchItem.data('pid')?.split('_')[1] || undefined,
                style_color: merchItem.data('pid') || undefined,
                item_name: merchItem.find('.mt-title')?.text()?.trim() || undefined,
                item_variant: merchItem.data('sku')?.toString().trim() || undefined,
            }

            var opts = {
                listName: 'recently viewed',
                listID: 'madewell:' + getClickLocation() + ':recently viewed',
            }
            
            productClick(data, opts);
        });
        $('.has-sub-menu').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', $(this).html());
        });
        $('.primary-logo').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', 'Home Link');
        });
        $(document).on('click', '.login-modal', function (e) {
            signInTapped(e);
        });
        $(document).on('click', '.registration-cta .buttonstyle', function(e) {
            selectNewArrival(e);
        });
        $(document).on('click', '.create-account, #create-account', function () {
            pushToDataLayer({
                event: 'join_now_tapped'
            });
        });
        $(document).on('click', '.login-account', function (e) {
            accountSignEvent(e, 'account_sign_in');
        });
        $(document).on('click', '.js-registration-form-container', function (e) {
            accountSignEvent(e, 'account_sign_up');
        });
        $(document).on('click', '#guest-create-account .guest-create-account-submit', function (e) {
            accountSignEvent(e, 'account_sign_up');
        });
        /* Navigation Events - BEGIN */
        $('.breadcrumb-element').on('click', function (e) {
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'breadcrumb nav',
                'navigation_category': undefined,
                'navigation_item_selected': (e.target.innerText || '').toLowerCase().trim()
            });
        });
        $('.header-microsite-tabs').on('click', function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'toggle gender';
            var navigation_item = (e.target.innerText.toLowerCase().trim() || '');
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'global nav',
                'navigation_category': (category != '' ? category : undefined),
                'navigation_item_selected': (category != '' ? category + ':' : '') + navigation_item
            });
        });
        $('.footer-item .menu-footer li a').on('click', function (e) {
            var gender = getGender();
            var menuFooter = $(this).attr("id") != 'footer-item-first' ? $(e.target).parents("ul.menu-footer").prev("h3.footer-header:first").text().toLowerCase().trim() : '';
            var category = (gender != '' ? gender + ':' : '') + (menuFooter != '' ? menuFooter : '');
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'footer nav',
                'navigation_category': (category != '' ? category : undefined),
                'navigation_item_selected': (category != '' ? category + ':' : '') + (e.target.innerText.toLowerCase().trim() || '')
            });
        });
        $('.footer-contact-us a').on('click', function () {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'contact us';
            var navigation_item = ($(this).text().toLowerCase().trim() || '');
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'footer nav',
                'navigation_category': category,
                'navigation_item_selected': (category != '' ? category + ':' : '') + navigation_item
            });
        });
        $('#footer-item-first .footer-social-links__item a').on('click', function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'social links';
            var navigation_item = ($(e.target).attr("alt").toLowerCase().trim() || '');
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'footer nav',
                'navigation_category': category,
                'navigation_item_selected': (category != '' ? category + ':' : '') + navigation_item
            });
        });
        $('.jcrew-logos a').on('click', function () {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'jcrew-logos';
            var svg = $(this).children();
            var navigation_item = '';
            if(svg[0].classList.contains("jcrew-logo-footer")){
                navigation_item = 'jcrew logo';
            }else{
                navigation_item = 'jcrew factory logo';
            }
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'footer nav',
                'navigation_category': category,
                'navigation_item_selected': (category != '' ? category + ':' : '') + navigation_item
            });
        });
        $('.copyright-container .copyright a').on('click', function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'copyright container';
            var navigation_item = (e.target.innerText.toLowerCase().trim() || '');
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'footer nav',
                'navigation_category': category,
                'navigation_item_selected': (category != '' ? category + ':' : '') + navigation_item
            });
        });
        $('.footer-country .esw-country-selector.selectCountry.footer-select').on('click', function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'country selection';
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'footer nav',
                'navigation_category': category,
                'navigation_item_selected': (category != '' ? category + ':' : '') + 'ship-to'
            });
        });
        $('.has-sub-menu').on('click', function (e) {
            var gender = getGender();
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'top flyout nav',
                'navigation_category': gender != '' ? gender : undefined,
                'navigation_item_selected': (gender != '' ? gender + ':' : '') + (e.target.innerText || '').toLowerCase().trim()
            });
        });
        $('ul[class^=level-2]>li>a').on('click', function (e) {
            var gender = getGender();
            var subMenuCategory = $(e.target).parents('.nav-item').children('.has-sub-menu').text().toLowerCase().trim();
            var titleCategory = $(e.target).parents('ul').prev().text().toLowerCase().trim();
            var category = (gender != '' ? gender + ':' : '') + subMenuCategory + (titleCategory != '' && titleCategory !== subMenuCategory ? ':' + titleCategory : '');
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'top flyout nav',
                'navigation_category': category,
                'navigation_item_selected': category + (':' + e.target.innerText.toLowerCase().trim() || '')
            });
        });
        $('div[class^=level-2]>a.level-2__tile').on('click', function (e) {
            var gender = getGender();
            var subMenuCategory = $(e.target).parents('.nav-item').children('.has-sub-menu').text().toLowerCase().trim();
            var text = $(this).text().toLowerCase().trim();
            var itemText = text ? text : $(this).next().text().toLowerCase().trim();
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'top flyout nav',
                'navigation_category': (gender != '' ? gender + ':' : '') + subMenuCategory,
                'navigation_item_selected': (gender != '' ? gender + ':' : '') + subMenuCategory + ':' + itemText
            });
        });
        $(document).off('click.nav').on('click.nav', '#wrapper .cnt-mega-menu .cnt-sub-category a, #wrapper .mnav-title a', function (e) {
            const category = ($(this).closest(".gtm-nav-category").getAttribute("data-gtm-category") || '').toLowerCase().trim() || "";
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'top flyout nav',
                'navigation_category': category,
                'navigation_item_selected': (category != '' ? category + ':' : '') + (e.target.innerText || '').toLowerCase().trim(),
            });
        });
        $('.primary-logo > a').on('click', function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'header nav';
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'global nav',
                'navigation_category': category,
                'navigation_item_selected': category + ':madewell logo'
            });
        });
        $('.header-search .launch-search').on('click', function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'header nav';
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'global nav',
                'navigation_category': category,
                'navigation_item_selected': category + ':search'
            });
        });
        $('.header-favorites-icon').on('click', function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'header nav';
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'global nav',
                'navigation_category': category,
                'navigation_item_selected': category + ':favorites'
            });
        });
        $('.user-account').on('click', function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'header nav';
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'global nav',
                'navigation_category': category,
                'navigation_item_selected': category + ':account'
            });
        });
        $('.mini-cart-link').on('click', function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'header nav';
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'global nav',
                'navigation_category': category,
                'navigation_item_selected': category + ':bag'
            });
        });
        $(document).off('click.denim_bar', '.ref-slide').on('click.denim_bar', '.ref-slide',  function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + $('.hidden-title').text().toLowerCase().trim();
            var navigation_item = ($(this).children('button').data('refinement-value') || $(this).find('p').text()).toLowerCase().trim();
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'plp header navigation',
                'navigation_category': category,
                'navigation_item_selected': (category != '' ? category + ':' : '') + navigation_item
            });
        });
        $('.user-panel a, .user-panel button').on('click', function (e) {
            var gender = getGender();
            var category = (gender != '' ? gender + ':' : '') + 'profile';
            var navigation_item = (category != '' ? category + ':' : '') + e.target.innerText.toLowerCase().trim();
            pushToDataLayer({
                'event': 'navigation_click',
                'navigation_type': 'loyalty nav',
                'navigation_category': category,
                'navigation_item_selected': navigation_item
            });
        });
        $('.category-curvy-toggle .curvy-btn').on('click', function () {
            pushToDataLayer({
                'event': 'plp_interaction',
                'click_location': 'curvy toggle'
            });
        });
        /* Navigation Events - END */

        /* Loyalty Events - BEGIN */
        $('.user-logout').on('click', function (e) {
            accountSignOutEvent('account_sign_out');
        });
        $(document).off("click.select_apply_now", ".primary-content [href*='/register'], .primary-content [href*='/account']").on("click.select_apply_now", ".primary-content [href*='/register'], .primary-content [href*='/account']", function() {
            dataLayer.push({
                'event': 'select_apply_now'
            });
        });
        var selectBirthdayChange = false;
        $(document).off("change.select_add_birthday", "#dwfrm_profile_customer_bMonth, #dwfrm_profile_customer_bDay").on("change.select_add_birthday", "#dwfrm_profile_customer_bMonth, #dwfrm_profile_customer_bDay", function () {
            selectBirthdayChange = true;
        });
        $(document).on("click", "[name='dwfrm_profile_confirm']", function () {
            if (selectBirthdayChange) {
                pushToDataLayer({
                    'event': 'select_add_birthday',
                });
            }
        });
        var applyLoyaltySelector = 'input[name^="applyAllRewards"]:checked, .reward-button:not(.offer-applied)';
        $('body').off("click.apply_loyalty_offer", applyLoyaltySelector).on("click.apply_loyalty_offer", applyLoyaltySelector,  function() {
            var promoCode = $(this).data('rewardid') || $(".reward-button:not(.offer-applied)").map((index, e) => $(e).attr('data-rewardid')).toArray().join(',');
            pushToDataLayer({
                'event': 'apply_loyalty_offer',
                'promo_code': promoCode
            });
        });
        /* Loyalty Events - END */
        $(document).on('addCouponSucess', function(e, data) {
            pushToDataLayer({
                'event': 'apply_promo_success',
                'promo_code': data.data.CouponCode
            });
        });
        $(document).on('addCouponError', function(e, data) {
            pushToDataLayer({
                'event': 'apply_promo_failed',
                'promo_code': data.data.CouponCode,
                'promo_error': data.data.status
            });
        });
        $(document).on('customAddToFavorites', function(e, data) {
            pushToDataLayer({
                'event': 'add_to_favorite',
                'product_id': data.pid,
                'item_id_event': data.pid,
                'item_name_event': data.name || undefined,
                'sku': data.sku || undefined,
            });
        });
        $(document).on('customRemoveFromFavorites', function(e, data) {
            pushToDataLayer({
                'event': 'remove_from_favorite',
                'product_id': data.pid,
                'item_id_event': data.pid,
                'item_name_event': data.name || undefined,
                'sku': data.sku || undefined,
            });
        });
        $(document).on('click', '.pdp-main .product-image-zoom', function () {
            pushToDataLayer({
                'event': 'product_image_zoom',
                'product_id': $('.pdp-main').data('pid')
            });
        });
        $(document).on('click','a.footer-contact-us-item, ul.menu-footer li a, #main #loyalty-dashboard .activity h3 a', function() {
            if($(this).attr("href").includes("madewell-insider-faq.html")){
             pushToDataLayer({
                    'event': 'contact_us',
                    'method': 'click'
                })
            }
        });
        $(document).on('click','.footer-item a', function() {
            if($(this).text().toLowerCase().includes("contact")){
                pushToDataLayer({
                    'event': 'contact_us',
                    'method': 'click'
                })
            }
        });
        $(document).on('click','.menu-footer li a, .content-asset .footer-contact-us-item, #main #loyalty-dashboard .activity h3 a', function() {
            if($(this).attr("href").includes("madewell-insider-faq.html")){
                pushToDataLayer({
                    'event': 'select_learn_more'
                });
            }
        });
        $(document).on('click', '.form-row.home-delivery label', function(e) {
            pushToDataLayer({
                'event': 'select_fulfillment_method',
                'fulfillment_method': 'ship to home'
            });
        });
        $(document).on('click', '.form-row.instore-delivery label', function(e) {
            pushToDataLayer({
                'event': 'select_fulfillment_method',
                'fulfillment_method': 'pick up in-store'
            });
        });
        $(document).on('click', '.shipping-type .shipping-type-switcher .form-row label', function(e) {
            pushToDataLayer({
                'event': 'select_fulfillment_method',
                'fulfillment_method': $(this).find('.desktop-label').text().toLowerCase()
            });
        });
        $(document).off('mouseleave.mini_cart', '.mini-cart-total').on('mouseenter.mini_cart', '.mini-cart-total', function (e) {
            checkMinicartProducts();
        });
        $(document).off('click.quickview_minicart', '.add-to-cart.quickview').on('click.quickview_minicart', '.add-to-cart.quickview', function (e) {
            checkMinicartProducts();
        });
        if ($(location).attr('pathname').includes('fall-denim-drop.html')) {
            $(document).on('click', 'a.builder-block', function(e) {
                var pathname = $(location).attr('pathname').replace(/\/|.html/g,'');
                var href = $(this).attr('href').replace(/\/|.html/g,'');
                var productLinkRegex = /([A-Z]{2}[0-9]{3})/gi;
                setProductFindingMethod(href, pathname, pathname);
                if (productLinkRegex.test(href)) {
                    pushToDataLayer({
                        'event': 'select_item',
                        'ecommerce': {
                            'items': [
                                {
                                    'item_id': href,
                                    'item_name': $(this).parent().find('.builder-text').text().toLowerCase().replace('shop now','').trim(),
                                    'item_list_name': pathname,
                                    'item_list_id': pathname,
                                },
                            ]
                        }
                    });
                }
            });
        }
        setTimeout(function () {
            var pageViewDataLayer = dataLayer.filter(pageView => pageView.event === 'page_view');
            if(pageViewDataLayer.length > 0){
                pageViewDataLayer[0].user_sheerid_verification ??= undefined;
                pageViewDataLayer[0].user_id ??= undefined;
                pageViewDataLayer[0].user_loyalty_member_tier ??= undefined;
            }
        });

        abCampaigns.initAbCampaignTagging();

        const menuContainer = $('.menu-category-container');
        if (menuContainer.length > 0) {
            const experiment_id = 'dual_gender_nav';
            const variant_id = menuContainer.hasClass('variant_1') ? 'variant_1' :
            menuContainer.hasClass('variant_2') ? 'variant_2' : 'control';

            abCampaigns.pushABEvent(experiment_id, variant_id);
        }

        window.gtm_impression_products = window.gtm_impression_products || {};
        $(document).ajaxComplete(function() {
            $(document).trigger('productImpression');
        });
    }
};

function checkMinicartProducts() {
    const minicartProducts = $('.mini-cart-name');
    if (minicartProducts.length > 0) {
        const items = minicartProducts.map(function(index, minicartProduct) {
            return $(minicartProduct).data('gtmdata');
        }).get();
        const currency = items[0]?.local_currency || undefined;
        pushToDataLayer({
            'event': 'view_cart',
            'click_location': "minicart",
            'ecommerce': {
                currency,
                items,
            },
        });
    } else {
        setTimeout(checkMinicartProducts, 500);
    }
}

function setProductValues(productObject, container){
    fillUndefinedParameters(productObject);

    if (container.hasClass('gcform__details')) {
        productObject.item_category = 'Giftcard';
    }
    if (productObject.item_category != 'Giftcard' && productObject.item_category != 'Virtual') {
        //set default GA360 price parameters
        var productPrice = container.find('.product-price .price-sales');
        var originalPrice = container.find('.product-price .regularPrice, .product-price .price-standard, .product-price .price-sales').eq(0);
        if (productPrice.length) {
            var localPrice = productPrice.contents().last()[0];
            if (localPrice.length) {
                productObject.price = Number(localPrice.wholeText.replace(/[^\d.]/g, "").trim()).toFixed(2);
            }
        }

        var selectedSwatch = container.find('.swatches.color .selectable.selected');
        if (selectedSwatch.length) {
            //set GA360 color parameters
            productObject.color_code = selectedSwatch.data('value');
            productObject.color_name = selectedSwatch.children('.swatchanchor').attr('title').split(':').pop().trim();
            productObject.style_color = productObject.item_id + '_' + productObject.color_code;
            if (container.find('.salePrice.range')) {
                productPrice = selectedSwatch.prevAll('.multiPrice:first');
            }
        }

        if (productPrice.length) {
            //mutation Observer configuration
            var config = { childList: true, subtree: true };

            function capturePriceValue() {
                productObject.price = Number(productPrice.contents().last()[0].wholeText.replace(/[^\d.]/g, "").trim()).toFixed(2);
                productObject.original_price = Number(originalPrice.text().replace(/[^\d.]/g, "").trim()).toFixed(2);
            }

            var observer = new MutationObserver(function(mutationsList, observer) {
                for (var mutation of mutationsList) {
                    if (mutation.type === 'childList' && mutation.target === productPrice[0]) {
                        capturePriceValue();
                        break;
                    }
                }
            });
            //start observing changes on productPrice using the configuration
            observer.observe(productPrice[0], config);
        }

        //set GA360 size
        var selectedSize = container.find('.swatches.size .selectable.selected');
        if (selectedSize.length) {
            productObject.size = selectedSize.data('value');
        }

        //set GA360 pricing_cadence
        var isFinalSale = container.find('.is-final-sale');
        if (isFinalSale.length) {
            productObject.pricing_cadence = 'final sale';
        } else if (productObject.pricing_cadence != 'final sale') {
            if (productObject.original_price > productObject.price) {
                var priceString = productObject.price.toString();
                if (priceString.indexOf('.5') > -1 || priceString.indexOf('.') == -1) {
                    productObject.pricing_cadence = 'promo';
                } else {
                    productObject.pricing_cadence = 'sale';
                }
            } else {
                productObject.pricing_cadence = 'full price';
            }
        }

        //set GA360 item_variant
        var variantData = $('#variantData').length > 0 && $('#variantData').val() !== '' ? JSON.parse($('#variantData').val()) : {};
        productObject.item_variant ??= variantData?.sku || undefined;

        //set GA360 personalization product
        if($(".personalization-details").css("display") === "block"){
            productObject.is_personalized = 'yes';
            var personalizationObject = {
                color: $(".personalization-details-color.personalization-details-options-value").text(),
                font: $(".personalization-details-font.personalization-details-options-value").text(),
                message: $(".personalization-details-message.personalization-details-options-value").text()
            }
            productObject.personalization = personalizationObject;
        }else{
            productObject.is_personalized = 'no';
            productObject.personalization = undefined;
        }

    } else if (productObject.item_category === 'Giftcard') {
        var productPrice = container.find('.gcform__option-button-grid--amounts .js-amount.selected');
        if (productPrice.length) {
            var localPrice = productPrice.contents().last()[0];
            if (localPrice.length) {
                productObject.price = Number(localPrice.wholeText.replace(/[^\d.]/g, "").trim()).toFixed(2);
                productObject.original_price = Number(localPrice.wholeText.replace(/[^\d.]/g, "").trim()).toFixed(2);
            }
        }
    }

    //set GA360 discount
    productObject.discount = (Number(productObject.original_price) - Number(productObject.price)).toFixed(2);
}

function getGender() {
    const gender = document.querySelector(".header-microsite-tabs a.active");
    return gender ? gender.innerText.toLowerCase().trim() : '';
}

/**
 * @description Push data to dataLayer or log error to console if dataLayer is not defined.
 * @param {Object} dataObj - data for dataLayer
 */
function pushToDataLayer (dataObj) {
    if (window.SitePreferences.GA360_ENABLED) {
        dataLayer.push(dataObj);
    }
}

function findFulFillMethods(items) {
    var methods = [];
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var container = $('.cart-row[data-uuid="' + item.lineUUID + '"]');
        var method = 'Ship to Home';
        if (item.item_category != 'Giftcard' && item.item_category != 'Virtual') {
            method = container.find('input[name^="delivery_option"]:checked').data('fulfillMethod');
            item.fulfillment_method = method;
        }
        item.can_be_fulfilled = 'yes';
        item.fulfillment_issue = undefined;
        if (container.hasClass('full-out-of-stock')) {
            item.can_be_fulfilled = 'no';
            item.fulfillment_issue = 'out of stock';
        }
        if (methods.indexOf(method) === -1) {
            methods.push(method);
        }
    }
    return methods.join('|');
}

function pushCheckoutStartEvent (checkoutType) {
    var gaData = $('#ga360Data').length ? $('#ga360Data').val() : null;
    if(gaData && gaData.length) {
        try {
            gaData = JSON.parse(gaData);
            var fulfillmentMethod = findFulFillMethods(gaData.items);
            if (gaData.items) {
                gaData.items.forEach(fillUndefinedParameters);
            }
            pushToDataLayer({ecommerce: null});
            pushToDataLayer(
                {
                    'event': 'begin_checkout',
                    'fulfillment_method': fulfillmentMethod,
                    'checkout_option': checkoutType, // regular checkout, pay pal, etc
                    'ecommerce': {
                        'currency': gaData.currency ? gaData.currency : '',
                        'items': gaData.items ? gaData.items : []
                    }
                }
            );
        } catch (e) {
            window.console.error(e);
        }
    }
}

function changeStore (storeData, selectStoreClicked) {
    pushToDataLayer({ecommerce: null}); // Clear previous ecommerce object
    pushToDataLayer({
        'event': selectStoreClicked === true ? 'set_store_completed' : 'change_store_completed',
        'location': storeData.location,
        'method': getUserStatus().includes('guest') ? 'visit' : 'account',
        'store_locale': storeData.name,
        'store_city': storeData.city,
        'store_address': storeData.address1
    });
}

function productView(productObject, opts) {
    try {
        if (opts && opts.fulfillmentMethod) {
            productObject.fulfillment_method =  opts.fulfillmentMethod;
        } else if (!productObject.fulfillment_method) {
            productObject.fulfillment_method = 'Ship to Home';
        }

        var obj = {
            event: 'view_item',
            fulfillment_method: opts.fulfillmentMethod || events.fulfillments.home,
            ecommerce: {
                currency: opts.currency || productObject.local_currency,
                items: [productObject]
            }
        };

        //setTimeout needed to prevent interfering with page_view event
        setTimeout(function () {
            pushToDataLayer({ ecommerce: null })
            pushToDataLayer(obj);
        });

        $(document).on('click','.product-variations .attribute .size-chart-link a', function() {
            pushToDataLayer({
                'event': 'product_size_chart',
                'product_id': productObject.item_id
            });
        });

    }catch (error) {
        console.log('Error: ' + error + ' - Trace: ' + error.stack);
    }
}

/**
 * @param {Object} productObject The product data
 * @description gets the data for a product click
 */
function productClick(productObject, opts) {
    var searchData = $('#search-result-items').data('searchdata');
    if (productObject && productObject.items && productObject.items.length > 0) {
        if (searchData.searchQuery) {
            productObject.items[0].listId = searchData.searchQuery;
            productObject.items[0].listName = 'search results';
        } else if (searchData.searchCategory) {
            productObject.items[0].listId = searchData.searchCategory;
            productObject.items[0].listName = 'assigned products';
        }

        Object.keys(searchData.categoryPath).forEach(function(key) {
            productObject.items[0][key] = searchData.categoryPath[key];
        });
    }

    var obj = {
        'event': 'productClick',
        'ecommerce': {
            'click': {
                'actionField': {'list': 'SearchResults'},
                'products': []
            }
        }
    };
    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);

    if (productObject) {
        if (!productObject.item_list_name) {
            productObject.item_list_name = opts.listName;
            productObject.item_list_id = opts.listID;
        }
        setProductFindingMethod(productObject.item_id, productObject.item_list_id, productObject.item_list_name);
        fillUndefinedParameters(productObject);
        var obj = {
            event: 'select_item',
            ecommerce: {
                currency: opts.currency || productObject.local_currency,
                items: [productObject]
            }
        };
        pushToDataLayer({ ecommerce: null })
        pushToDataLayer(obj);
    }
}

/**
 * Where the click occurred, quick view, pdp, saved for later, cart, etc
 * @returns {String} location
 */
function getClickLocation() {
    var location = '';
    if ('pageContext' in window && 'ns' in window.pageContext) {
        location = window.pageContext.ns;
    }
    return location;
}

/**
 * @description Click event for add product to cart
 * @param {Object} productObject The product data
 * @param {String} quantity
 */
function addToCart (productObject, opts) {
    //deprecated this is GAv1
    var quantityObj = {'quantity': opts.qty},
        obj = {
            'event': 'addToCart',
            'ecommerce': {
                'add': {
                    'products': []
                }
            }
        };

    if (opts && opts.fulfillmentMethod) {
        productObject.fulfillment_method =  opts.fulfillmentMethod;
    } else if (!productObject.fulfillment_method) {
        productObject.fulfillment_method = 'Ship to Home';
    }

    obj.ecommerce.add.products.push($.extend(productObject,quantityObj));
    dataLayer.push(obj);

    //this is GA360
    if (productObject) {
        productObject.quantity = opts.qty;
        var pageViewDataLayer = dataLayer.filter(pageView => pageView.event === 'page_view');
        productObject.item_list_id = 'madewell:' + pageViewDataLayer[0].page_breadcrumb;
        productObject.item_list_name = opts.clickLocation || getClickLocation();

        if (productObject.stock_status === 'out of stock') {
            productObject.fulfillment_issue = 'out of stock';
            productObject.can_be_fulfilled = 'no';
        } else {
            productObject.fulfillment_issue = undefined;
            productObject.can_be_fulfilled = 'yes';
        }

        if (productObject) {
            fillUndefinedParameters(productObject);
        }

        var clickLocation = opts.clickLocation || getClickLocation();
        var obj = {
            event: 'add_to_cart',
            fulfillment_method: opts.fulfillmentMethod,
            click_location: clickLocation,
            ecommerce: {
                currency: opts.currency || productObject.local_currency,
                items: [productObject]
            }
        };
        pushToDataLayer({ ecommerce: null })
        pushToDataLayer(obj);
    }
}

/**
 *
 * @param {Object} productObject
 * @param {String|Number} quantity
 */
function removeFromCart (productObject, opts) {
    //deprecated this is GAv1
    var quantityObj = {'quantity': opts.qty},
    obj = {
        'event': 'removeFromCart',
        'ecommerce': {
            'remove': {
                'products': []
            }
        }
    };

    obj.ecommerce.remove.products.push($.extend(productObject,quantityObj));
    dataLayer.push(obj);

    if (!productObject.fulfillment_method) {
        productObject.fulfillment_method = 'Ship to Home';
    }

    //this is GA360
    if (productObject) {
        productObject.quantity = opts.qty;
        if (productObject.stock_status === 'out of stock') {
            productObject.fulfillment_issue = 'out of stock';
            productObject.can_be_fulfilled = 'no';
        } else {
            productObject.fulfillment_issue = undefined;
            productObject.can_be_fulfilled = 'yes';
        }

        if (productObject) {
            fillUndefinedParameters(productObject);
        }
        var clickLocation = opts.clickLocation || getClickLocation();
        var obj = {
            event: 'remove_from_cart',
            fulfillment_method: opts.fulfillmentMethod,
            click_location: clickLocation,
            ecommerce: {
                currency: opts.currency || productObject.local_currency,
                items: [productObject]
            },
        };
        pushToDataLayer({ ecommerce: null })
        pushToDataLayer(obj);
    }
}

/**
 * @description Click event for Select New Arrivals via Loyalty Sign Up
 */
 function selectNewArrival (e) {
    var arrivalLabel = e.target.innerText.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
    if (arrivalLabel) {
        pushToDataLayer({
            'event': 'select_new_arrivals',
            'click_label': arrivalLabel
        });
    }
}

/**
 * @description Click event for set store when users sets a preferred store for the first time or change store when they already have one set.
 */
function storeCompleted (element, location, storeSelected) {
    if ($(element).hasClass('select-store-button') && !$(element).hasClass('selected-store') && !$(element).prop('disabled')) {
        pushToDataLayer({ecommerce: null}); // Clear previous ecommerce object
        pushToDataLayer({
            'event': storeSelected ? 'change_store_completed' : 'set_store_completed',
            'location': location,
            'method': getUserStatus().includes('guest') ? 'visit' : 'account',
            'store_locale': $(element).siblings('.store-name').text(),
            'store_city': $(element).data('city'),
            'store_address': $(element).data('address')
        });
    }
}

/**
 * @description Get user status from page_view event
 */
function getUserStatus () {
    var pageViewDataLayer = dataLayer.filter(pageView => pageView.event === 'page_view');
    if(pageViewDataLayer.length > 0){
        return pageViewDataLayer[0].user_status;
    }
}

/**
 * @description Convenience method for creating a click event.
 * @param {String} event
 * @param {String} eventCategory
 * @param {String} eventAction
 * @param {String} eventLabel
 */
function pushEvent (event, eventCategory, eventAction, eventLabel) {
    if ('dataLayer' in window) {
        pushToDataLayer({
            'event': event,
            'eventCategory': eventCategory,
            'eventAction': eventAction,
            'eventLabel': eventLabel
        });
    } else {
        window.console.error('dataLayer is not defined!')
    }
}

function signInTapped (e) {
    if (!e.target.classList.contains('add-to-favorites')) {
        pushToDataLayer({
            event: 'sign_in_tapped'
        });
    }
}

function accountSignEvent (e, eventName) {
    var signButton = $(e.target).closest('[data-method]');
    if (!signButton.length) {
        return;
    }
    var signMethod = tls.getCookie("signMethod") || (signButton.data('method') == 'form' ? 'email' : signButton.data('method'));
    pushToDataLayer({
        event: eventName,
        method: signMethod == 'form' ? 'email' : signMethod
    });
    tls.setCookie('signMethod', signMethod, 87900*30*12);
}

function accountSignOutEvent (eventName) {
    var signMethod = tls.getCookie("signMethod");
    pushToDataLayer({
        event: eventName,
        method: signMethod
    });
    tls.setCookie('signMethod', undefined);
}

function plpApplyFilter (e) {
    var $target = $(e.target);
    var $refLink = $target.closest('.js-ref-link');
    if ($refLink.length) {
        pushToDataLayer({
            'event': 'product_list_filter',
            'filter_key': $target.parents('.refinement, .toplevel-filters-unit').find('.refinement-header, .btn-toggle-refinement').data('name'),
            'filter_value': $refLink.data('value')
        });
    }
}

/**
 * @description loops through the homepage content slots, and assign a string to the data-promo-id attribute
 */
function addPromoIdToHomePageItems () {
    var $promoItems = $(document).find('[data-tracking]');
    var $promoItem;
    $promoItems.each(function(index) {
        $promoItem = $(this);
        if ($promoItem.attr('data-tracking')) {
            $promoItem.attr('data-promo-id', `nav_homepage_content_${index}`);
        }
    });
}

/**
 * @description Set up product interaction handling.
 * @param {String} changeType The type of change (fit, swatch, size).
 * @param {String} selector The ul element selector to listen for events.
 * @param {Object} container The container element.
 * @param {Object} productObj The product object.
 */
function handleProductInteraction(changeType, selector, container, productObj) {
    $(document).on('click', selector + ' .swatchanchor', function (e) {
        var previousValue = container.find(selector + ' li.selected').data('value');
        var newValue = $(this).parent().data('value');
        pushToDataLayer({
            'event': 'pdp_interaction',
            'change_type': changeType,
            'item_name_event': productObj.item_name,
            'item_id_event': productObj.item_id,
            'previous_value': previousValue,
            'new_value': newValue
        });
    });
}

function fillUndefinedParameters(item){
    var itemList = getProductFindingMethod(item.item_id);

    item.item_category ??= undefined;
    item.item_category2 ??= undefined;
    item.item_category3 ??= undefined;
    item.item_category4 ??= undefined;
    item.item_category5 ??= undefined;
    item.coupon ??= undefined;
    item.personalization ??= undefined;
    item.is_personalized ??= "no"
    item.fulfillment_issue ??= undefined;
    item.size ??= undefined;
    item.item_variant ??= undefined;
    item.item_list_id = itemList.itemListId || item.item_list_id || 'madewell:' + getClickLocation();
    item.item_list_name = itemList.itemListName || item.item_list_name || getClickLocation();
    item.quantity ??= 1;
    item.item_tile??= itemList.itemTile;
    item.item_image_setup??= itemList.itemImageSetup;
    item.item_image_type??= itemList.itemImageType;
}

function pushImpression(productListing, listName, listID) {
    var gridTiles = {};
    if (productListing.hasClass("cart-recommendations")) {
        gridTiles = productListing.find('.cart-rec-container:not(.visually-hidden)')?.find('.grid-tile');
    } else {
        gridTiles = productListing.find('.grid-tile');
    }
    if (gridTiles.length > 0) {
        gridTiles.each(function() {
            var gtmData = $(this).find('a.thumb-link')?.data('gtmdata');
            if (gtmData) {
                gtmData.item_list_id = listID || undefined;
                gtmData.item_list_name = listName || undefined;
                delete gtmData.local_currency;
                fillUndefinedParameters(gtmData);
                window.gtm_impression_products[gtmData.item_id] = gtmData;
            }
        });
    }

    var recentlyViewedTiles = productListing.find('.mt-merch-item');
    if (recentlyViewedTiles.length > 0) {        
        recentlyViewedTiles.each(function() {
            var gtmData = {};
            gtmData.item_id = $(this).data('pid')?.split('_')[0] || undefined;
            gtmData.color_code = $(this).data('pid')?.split('_')[1] || undefined;
            gtmData.style_color = $(this).data('pid') || undefined;
            gtmData.item_name = $(this).find('.mt-title')?.text()?.trim() || undefined;
            gtmData.item_variant = $(this).data('sku')?.toString().trim() || undefined;
            gtmData.item_list_id = listID || undefined;
            gtmData.item_list_name = listName || undefined;
            fillUndefinedParameters(gtmData);
            window.gtm_impression_products[gtmData.item_id] = gtmData;
        });
    }
}

function setProductFindingMethod (
    itemId,
    itemListId,
    itemListName,
    itemTile,
    itemImageSetup,
    itemImageType,
  ) {
    const productFindingMethods = getProductFindingMethods();
    productFindingMethods[itemId] = [
        itemListId || "",
        itemListName || "",
        itemTile || "",
        itemImageSetup || "",
        itemImageType || "",
    ];
    
    sessionStorage.setItem("mw_pfm", JSON.stringify(productFindingMethods));
}

function getProductFindingMethods () {
    try {
        return JSON.parse(sessionStorage.getItem("mw_pfm") || "{}");
    } catch (e) {
        return {};
    }
}

function getProductFindingMethod (itemId) {
    const productFindingMethods = getProductFindingMethods();
    const [listId, listName, itemTile, itemImageSetup, itemImageType] = productFindingMethods[itemId] || [];

    return {
        itemListId: listId || undefined,
        itemListName: listName || undefined,
        itemTile: itemTile || undefined,
        itemImageSetup: itemImageSetup || undefined,
        itemImageType: itemImageType || undefined,
    }
};

/**
 * @description Initialize the tag manager functionality
 * @param {String} nameSpace The current name space
 */
exports.init = function (nameSpace) {
    events.all();
    if (nameSpace && events[nameSpace]) {
        events[nameSpace]();
    }
};

exports.changeStore = changeStore;
exports.pushToDataLayer = pushToDataLayer;
