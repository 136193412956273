'use strict';

var dialog = require('./dialog'),
    page = require('./page'),
    util = require('./util'),
    validator = require('./validator'),
    wishlist = require('./wishlist_status'),
    registrationModel = require('./registrationmodal');


/**
 * @private
 * @function
 * @description checks the cookie to show signin modal.
 */
function initLoginCookieEvents() {
    var $cookie = util.getCookie("showOptinSecondPageLoad");
    var $location = util.getCookie("esw.location");
    var $showModal = ('pageContext' in window && 'ns' in window.pageContext) && ['cart','checkout'].indexOf(window.pageContext.ns) === -1;
    if ($location !== 'US' || ($cookie && $cookie === 'false' || !$showModal || window.User.authenticated)) {
        return false;
    }

    if ($cookie) {
        /*if ($showModal && !window.User.authenticated && window.User.registered) {
            $('.user-account.login-modal').eq(0).trigger('click');
        } else */
        if ($showModal && window.User.anonymous) {
            registrationModel.loadRegistration();
            util.createCookie('signMethod', 'second page visit email');
        }
        util.createCookie('showOptinSecondPageLoad', false);
    } else if (!$cookie) {
        util.createCookie('showOptinSecondPageLoad', true);
    }
}

/**
 * @private
 * @function
 * @description Binds the events for any click that initiates the login modal.
 */
function initializeEvents() {
    // Any login link - header, footer, or otherwise - will initiate this modal.
    window.onQuikLinksClick = function(e) {
        var $registrationPg = $('.registration-container'),
            $registrationModal = $('.registration-container.modal'),
            $loginPg = $('.login-page');
        if (($registrationPg.length && !$registrationModal.length) || ($loginPg.length)) {
            return;
        }
        if (!window.User.authenticated) {
            e.preventDefault();
            var redirectOrigin;
            if (e && e.currentTarget && e.currentTarget.href) {
                if (e.currentTarget.href.indexOf('original=/') > -1) {
                    redirectOrigin = e.currentTarget.href.substring(e.currentTarget.href.indexOf('original=/') + 'original=/'.length);
                }
                if (e.currentTarget.href.indexOf('redirectOrigin=') > -1) {
                    redirectOrigin = e.currentTarget.href.substring(e.currentTarget.href.indexOf('redirectOrigin=') + 'redirectOrigin='.length);
                }
            }
            var $this = $(e.currentTarget),
                $url,
                $hasClass;
            if ($this.data('href')) {
                $url = $this.data('href');
            } else {
                $url = $this.attr('href');
            }
            if ($this.hasClass('add-to-favorites')) {
                $hasClass = true;
                $url = $url + '&source=favorites';
            } else if ($this.hasClass('add-to-wishlist') || $this.hasClass('save-later')) {
                $hasClass = true;
                if ($this.hasClass('save-later')) {
                    $url = $url + '&source=save4later';
                    var uuid = $this.parents('.cart-row').data('uuid');
                    if (uuid) {
                        $url = $url + '&uuid=' + uuid;
                    }
                }
            } else if ($this.hasClass('header-favorites-icon') || $this.hasClass('header-orders') || $this.closest('.header-nav-menu').length) {
                $hasClass = true;
            } else {
                $hasClass = $this.hasClass('footer-login');
            }
            if (!$this.closest('.header-nav-menu').length) {
                if ($this.hasClass('user-account') && !$this.hasClass('header-favorites-icon') && !$this.hasClass('header-orders')) {
                    $url = $url + '&source=accountIcon';
                } else if ($this.parents('.cart-rewards').length != 0) {
                    $url = $url + '&source=cartLoyalty';
                } else if ($(this).parents('.save-for-later-container').length != 0) {
                    $url = $url + '&source=wishlist';
                }
            }

            if (redirectOrigin) {
                $url = util.appendParamToURL($url, 'redirectOrigin', redirectOrigin);
            }
            var data = $this.data();
            loadLoginModal($url, $hasClass, data);
        }
    }

    window.openSignInModal = function() {
        $('.login-modal').eq(0).click();
    }

    $(document).on('click', '.login-modal', function(e){
        window.onQuikLinksClick(e);
    });

    $(document).on('click', '[name$="_requestpassword_send"]', function (e) {
        var $requestPasswordForm = $('[name$="_requestpassword"]');
        var $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
        validator.init();
        if (!$requestPasswordForm.valid()) {
            return;
        }
        e.preventDefault();
        var data = $requestPasswordForm.serialize();
        // add form action to data
        data += '&' + $submit.attr('name') + '=';
        // make sure the server knows this is an ajax request
        if (data.indexOf('ajax') === -1) {
            data += '&format=ajax';
        }
        $.ajax({
            type: 'POST',
            url: $requestPasswordForm.attr('action'),
            data: data,
            success: function (response) {
                if (typeof response === 'object' &&
                        !response.success &&
                        response.error === 'CSRF Token Mismatch') {
                    page.redirect(Urls.csrffailed);
                } else if (typeof response === 'string') {
                    dialog.$container.html(response);
                }
            },
            failure: function () {
                dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
            }
        });
    });

    if (window.User.authenticated && document.querySelector('.header-nav-menu')) {
        var observer = new MutationObserver(function(mutationRecords) {
            if (mutationRecords.length > 0 && $('.header-nav-menu .user-account').length) {
                $('.header-nav-menu .user-account').removeClass('user-account');
            }
        });
        
        // observe everything except attributes
        observer.observe(document.querySelector('.header-nav-menu'), {
            childList: true, // observe direct children
            subtree: true, // lower descendants too
            characterDataOldValue: true // pass old data to callback
        });
    }
}

/**
 * @private
 * @function
 * @description Handles the loading of the Login form into the Modal.
 */
function loadLoginModal(linkUrl, redirectRequired, data) {
    if (!window.User.authenticated) {
        var isGated = $('body').hasClass('gated');
        var isBackroom = $('body').hasClass('backroom');
        var url = window.Urls.loginModal;
        var isTabView = $('.ui-dialog .signinout-tab');
        // setup gate url if nec
        if (isGated) url = window.Urls.loginModalGate;
        if (isGated && isBackroom) url = window.Urls.loginModalGateBackroom;
        var isUrlValid = (linkUrl && (typeof linkUrl === 'string' || linkUrl instanceof String));
        var original = (redirectRequired && linkUrl) ? linkUrl : window.location.href;
        url = util.appendParamToURL(url, 'original', original);

        // Special redirect via href of the link.
        if (isUrlValid) {
            if (linkUrl.indexOf('&source=accountIcon') > -1) {
                url = util.appendParamToURL(url, 'source', 'accountIcon');
            } else if (linkUrl.indexOf('&source=wishlist') > -1) {
                url = util.appendParamToURL(url, 'source', 'wishlist');
            } else if (linkUrl.indexOf('&source=favorites') > -1) {
                url = util.appendParamToURL(url, 'source', 'favorites');
            } else if (linkUrl.indexOf('&source=save4later') > -1) {
                url = util.appendParamToURL(url, 'source', 'save4later');
            } else if (linkUrl.indexOf('&source=cartLoyalty') > -1) {
                url = util.appendParamToURL(url, 'source', 'cartLoyalty');
            } else if (linkUrl.indexOf('&source=orders') > -1) {
                url = util.appendParamToURL(url, 'source', 'orders');
            } else if (linkUrl.indexOf('redirectOrigin=') > -1) {
                var redirectOrigin = linkUrl.substring(linkUrl.indexOf('redirectOrigin=') + 'redirectOrigin='.length);
                url = util.appendParamToURL(url, 'redirectOrigin', redirectOrigin);
            }
        }

        if (data) {
            if (data.pid) {
                url = util.appendParamToURL(url, 'pid', data.pid);
            } else if (data.masterproductid) {
                url = util.appendParamToURL(url, 'pid', data.masterproductid);
            }
            if (data.colorid) {
                url = util.appendParamToURL(url, 'colorid', data.colorid);
            }
        }

        var uuid = util.getUrlParameter(linkUrl, 'uuid');
        if (uuid) {
            url = util.appendParamToURL(url, 'uuid', uuid);
        }

        if (isTabView.length) {
            url = util.appendParamToURL(url, 'format', 'ajax');
            url = util.appendParamToURL(url, 'registrationincluded', 'true');
            $.get(url, function(response) {
                $('.sign-in-out .signinout-title').height($('.sign-in-out .signinout-title').height()).append($(response).filter('.signinout-title').html());
                $('.sign-in-out .signinout-body-container').height($('.sign-in-out .signinout-body-info').height());
                $('.sign-in-out .signinout-body').append($(response).filter('.signinout-body').html());
                loginFormInit();
            });
        } else {
            var options = {
                url: url,
                options: {
                    height: 622,
                    width: 'auto',
                    maxHeight: 625,
                    maxWidth: 375,
                    classes: {
                        'ui-dialog': 'sign-in-out login-in' + (isGated ? ' no-close gate': '')
                    },
                    closeOnEscape: !isGated,
                    open: function() {
                        var $signinoutClose = $('.sign-in-out .ui-dialog-titlebar-close').clone();
                        $('.sign-in-out .ui-dialog-titlebar-close').remove();
                        $('.sign-in-out .ui-dialog-titlebar').append($signinoutClose);
                    }
                },
                callback: function() {
                    loginFormInit();
                    $('.create-account.create-account-tab-view').trigger('click');
                    var $checkoutEmailField = $('.checkout-shipping input[name$="_emailorderupdate"]');
                    if ($checkoutEmailField.length && $checkoutEmailField.hasClass('active')) {
                        $checkoutEmailField.removeClass('active')
                        $('.sign-in-out input[name$="login_username"]').val($checkoutEmailField.val());
                        $('.sign-in-out input[name$="login_password"]').val('');
                    }
                }
            }
            dialog.open(options);
        }
    }
}

/**
 * @private
 * @function
 * @description Binds the events of the Login Form, once loaded into the modal.
 */
function loginFormInit() {
    var $form = $('.modal-login-form'),
        $remember = $form.find('#dwfrm_login_rememberme'),
        $me = $form.find('#rememberme');

    // Initialize plugins.
    validator.init();
    // Allow the user to hide/show their password.
    // O-auth binding for which icon is clicked
    $(document).on('click', '.oAuthIcon', function () {
        $('#OAuthProvider').val(this.id);
    });

    // Handle form submission and response, within the modal.
    $form.on('submit', handleFormSubmission);

    // Allow for password reset modal.
    $(document).on('click', '#password-reset', resetPassword);

    // Allow for the faux checkbox state.
    $remember.on('change', function () {
        $me.val($remember.attr('checked'));
    });

    AppleID.auth.init({
        clientId : SitePreferences.APPLE_LOGIN_CLIENT_ID,
        scope : SitePreferences.APPLE_LOGIN_SCOPE,
        redirectURI: SitePreferences.APPLE_LOGIN_REDIRECT_URL,
        state : SitePreferences.APPLE_LOGIN_STATE
    });
}

/**
 * @private
 * @function
 * @description Handles the submission of the Login Form.
 */
function handleFormSubmission(e) {
    e.preventDefault();
    var $this = $(this);

    if (!$this.valid()) {
        return false
    }

    $('<input/>').attr({
        type: 'hidden',
        name: $this.find('button[type="submit"]').attr('name'),
        value: 'loginmodal'
    }).appendTo($this);

    $this.find('.form-action-button:visible').addClass('button-loader');

    $.ajax({
        type: 'POST',
        url: util.appendParamToURL($this.attr('action'), 'format', 'ajax'),
        data: $this.serialize()
    })
    .done(function (data) {
        if (typeof data === 'object' && typeof data.success) {
            if (data.redirectLink && (typeof data.redirectLink === 'string' || data.redirectLink instanceof String)) {
                data.isFavorites = data.redirectLink.indexOf('&source=favorites') != -1;
                data.isSave4later = data.redirectLink.indexOf('&source=save4later') !== -1;
                if (data.isFavorites) {
                    wishlist.customerLoginHandler(e);
                } else if (data.isSave4later) {
                    wishlist.save4laterLogin(e, data);
                } else {
                    page.redirect(data.redirectLink);
                }
            } else {
                page.refresh();
            }
        } else {
            if ($(data).find('.signinout-tab[data-action="login"]').length) {
                $('.signinout-tab[data-action="login"]').html($(data).find('.signinout-tab[data-action="login"]').html());
            } else {
                $('#dialog-container').html(data);
            }
            loginFormInit();
            $this.find('.form-action-button:visible').removeClass('button-loader');
        }
    });
}

/**
 * @private
 * @function
 * @description Handles the Password Rest flow, from the Login Form modal.
 */
function resetPassword(e) {
    e.preventDefault();
    dialog.close();
    var isGated = $('body').hasClass('gated');
    var $email = $(this).closest('form').find('.email').val();
    var url = $(e.target).attr('href') || $(e.target).attr('data-href');
    dialog.open({
        url: url,
        options: {
            width: 'auto',
            height: 622,
            classes: {
                'ui-dialog': 'password-reset-modal ' + (isGated ? ' no-close gate': '')
            },
            closeOnEscape: !isGated,
            open: function () {
                setTimeout(function () {
                    validator.init();
                });

                if ($email !== '') {
                    $('[name$="_requestpassword"] .email').val($email);
                }
            }
        }
    });
}

/**
 * EXPOSED METHODS
 */
module.exports = {
    /**
     * @public
     * @function
     * @description Init event(s) for the Login Model.
     */
    init: function () {
        initializeEvents();
        initLoginCookieEvents();
    },
    loginModal: function(url, hasClass) {
        loadLoginModal(url, hasClass);
    }
}
