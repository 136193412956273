'use strict'

var activeSlideClass = 'is-filter-selected';
var orderedAppliedFilters = [];

//scroll functionality 
function scrollBy($container, scrollAmount, duration = 600) {
    var $scroller = $container.find('[x-ref=scroller]');
    var currentScroll = $scroller.scrollLeft();
    var newScroll = currentScroll + scrollAmount;
    $scroller.animate({scrollLeft: newScroll}, duration, 'easeInOutCubic');
}

// hide/show arrows logic
function bindScrollArrows($container){
    function checkScroll() {
        var $scroller = $container.find('[x-ref=scroller]');
        if ($scroller.length) {
            var isStart = $scroller.scrollLeft() <= 1;
            var isEnd = $scroller.scrollLeft() + $scroller.width() >= $scroller[0].scrollWidth;
            var $prev = $container.find('.prev');
            var $next = $container.find('.next');
    
            if (isStart) {
                $prev.hide();
            } else {
                $prev.show();
            }
    
            if (isEnd) {
                $next.hide();
            } else {
                $next.show();
            }
        }
    }
    $container.find('[x-ref=scroller]').off('scroll.arrows').on('scroll.arrows', checkScroll);
    checkScroll();
}

function initApplyFilters($container) {
    //filter function on image click
    $container.off('click.refinement-id-filter').on('click.refinement-id-filter', '.refinement-id-filter', function (e) {
        e.preventDefault();
        var filterValue = $(this).data('refinement-value');
        var $borderElement = $(this).children('div').eq(0);
        var isActive = $borderElement.hasClass(activeSlideClass);
        var $button;

        if (!isActive) {
            $borderElement.addClass(activeSlideClass);
            orderedAppliedFilters.push(filterValue);
            $button = $('[data-refinement-id="categories"] [data-value]').filter(function (index, el) {
                return $(el).data('value') === filterValue;
            });
        } else {
            $borderElement.removeClass(activeSlideClass);
            $button = $('.breadcrumb-refinement-value[data-value]').filter(function (index, el) {
                return $(el).data('value') === filterValue;
            }).eq(0).find('a');

            orderedAppliedFilters = orderedAppliedFilters.filter(function(value) {
                return value !== filterValue
            });
        }

        if ($button.length > 0) {
            $button.eq(0).trigger('click');
        }
    }).on('mouseenter', '.banner-slide', function (e) {
        if ($(this).find('video:visible').length) {
            $(this).find('video').get(0).play();
        }
    }).on('mouseleave', '.banner-slide', function (e) {
        if ($(this).find('video').length) {
            $(this).find('video').get(0).pause();
        }
    });

    $container.find('.banner-slide video').each(function() {
        if ($('.menu-toggle').is(':visible')) {
            $(this).get(0).play();
        }
    });
}

function detectAppliedFilters($container) {
    $container.find('.refinement-id-filter').each(function (index, slide) {
        $(slide).children('div').eq(0).removeClass(activeSlideClass);
    });
    
    $('.breadcrumb-refinement-value[data-value]').each(function(index, el) {
        var filterValue = $(el).data('value');
        $container.find('.refinement-id-filter').each(function (index, slide) {
            var isActive = $(slide).data('refinement-value') === filterValue;
            if (isActive) {
                $(slide).children('div').eq(0).addClass(activeSlideClass);
            }
        });
    });
}

var displayCondensedVisualNav = $('.filters-content-slot-carousel').data('displaycondensedvisualnav');
var $duplicated = null;
function initDrawerBannerCategory() {
    setTimeout(() => {
        var $container = $('.filters-content-slot-carousel'); 
        if (!$container.length) return;

        if ($container.find('.category-banner').length === 0 && displayCondensedVisualNav) {
            if($('.category-banner').eq(0).find('.banner-slide').length > 1){
                $duplicated = $('.category-banner').eq(0).clone(false);
                if($duplicated.find('.denim-refresh-name').length > 0) {
                    $duplicated.find('.denim-refresh-name').text($duplicated.data('catname'));
                } else if ($duplicated.find('.curvy-title-h1').length > 0) {
                    $duplicated.find('.curvy-title-h1').remove();
                    var curvyTitle = $('<div></div>').addClass('denim-refresh-name').text($duplicated.data('catname'));
                    curvyTitle.appendTo($duplicated.find('.banner-title > .denim-category-banner-title'));
                }
                $duplicated.find('.category-curvy-toggle').remove();
                $duplicated.find('[x-ref=container] > button').eq(0).addClass('drawer-prev');
                $duplicated.find('[x-ref=container] > button').eq(1).addClass('drawer-next');
                //added this selector to separate from the other one in the expand drawer functionality
                $duplicated.find('[x-ref=container]').addClass('drawer-refinement-carousel');
                $duplicated.find('.banner-title > .denim-category-banner-title').addClass('refinement-carousel');
                //remove the elements we don't need inside the drawer
                $duplicated.find('.back-button-parent-category').remove();
                $duplicated.find('.change-model-container').remove();
                $container.removeAttr('style');
            } else {
                $container.hide();
                return
            }
            
            // in case images didn't load, force them to load
            $duplicated.find('img[data-src]').each(function () { 
                $(this).attr('src', $(this).attr('data-src'));
            });
            
            // append to filters nav
            $container.append($duplicated);
        }
        
        //calculate the scroll amount with the slide width 
        function getScrollAmount() { 
            return 2 * $container.find('[x-ref=scroller] > div').eq(0).width(); 
        }
        
        //position de arrows in the image height center
        $(function arrowPosition(){
            $container.find('[x-ref=container] > button').css('top', '115px');
        })
        
        // slide prev button 
        $container.off('click.prev').on('click.prev', 'button.drawer-prev', function() { 
            var scrollAmount = getScrollAmount(); 
            scrollBy($container, -scrollAmount, 400); 
        });
        // slide next button
        $container.off('click.next').on('click.next', 'button.drawer-next', function () {
            var scrollAmount = getScrollAmount(); 
            scrollBy($container, scrollAmount, 400); 
        });
        
        initApplyFilters($container);
        detectAppliedFilters($container);
        bindScrollArrows($container);
    }, 100);
}

function initHeaderBannerCategory() {
    var $container = $('.category-banner'); 
    if (!$container.length || ($container.hasClass('denim-category-banner') &&
        $container.find('.banner-slide').length === 1)) {
            $('.denim-category-banner-alt-title').addClass('visible');
            return;
        };

    $container.addClass('visible');
    if($('.banner-slide').length > 0) {
        if ($('.category-curvy-toggle').length) {
            if ($('.category-curvy-toggle').hasClass('d-none')) {
                $.ajax({
                    type: 'GET',
                    url: window.Urls.getCurvyBarStatus,
                    success: function (result) {
                        if (result && result.curvyEnforced) {
                            $('.category-curvy-toggle').removeClass('d-none');
                            if ($('.curvyTile').length) {
                                $('.curvyTile').parent().remove();
                            }
                            if ($('#categories-curvy_jeans').length) {
                                $('#categories-curvy_jeans').remove();
                            }
                        }
                    }
                });
            } else {
                if ($('.curvyTile').length) {
                    $('.curvyTile').parent().remove();
                }
                if ($('#categories-curvy_jeans').length) {
                    $('#categories-curvy_jeans').remove();
                }
            }
            
        }
        $('[x-ref=container]').show();
        $container.addClass('is-denim-bar');
        if($container.find('.banner-slide').length > 1) {
            $('.back-button-parent-category').addClass('visible');
            $container.find('.change-model-container').addClass('visible');
        }
    }
    if($('.banner-slide').length < 6){
        $('.scroller.scrollbar').addClass('center-slides');
    }
    
    //calculate the scroll amount with the slide width 
    function getScrollAmount() { 
        return $container.find('[x-ref=scroller]').width(); 
    }

    //position de arrows in the image height center
    $(function arrowPosition(){
        var imgH = $('#slide-img').height();
        var btnH = $container.find('[x-ref=container] > button').height();
        $container.find('[x-ref=container] > button').css('top', (imgH - btnH) / 2);
    });

    //change model image updates functionality 
    $(document).off('click.choose-model', '.changel-model-select-label').on('click.choose-model', '.changel-model-select-label', function(e) {
        var $container = $(this).parents('.change-model-container');
        $container.toggleClass('active');
        var isExpanded = $container.hasClass('active');

        if (isExpanded) {
            $(document).off('click.close-choose-model').on('click.close-choose-model', function(e) {
                if ($(e.target).closest('.change-model-container').length === 0) {
                    $container.removeClass('active');
                    $(document).off('click.close-choose-model');
                }
            });
        }
    });    
    $(document).off('click.select-model', '.btn-choose-model').on('click.select-model', '.btn-choose-model', function(e) {
        e.preventDefault();
        var $previousSelected = $('.option-selected');
        $previousSelected.removeClass('option-selected');
        $(this).addClass('option-selected');
        var $dropdown = $(this).parents('.change-model-container');
        var model = $(this).attr('data-value');
        if(model === '') {
            $dropdown.addClass('default-selected');
        } else {
            $dropdown.removeClass('default-selected');
        }
        var $drawerContainer = $('.filters-content-slot-carousel');
        $dropdown.find('.dropdown-choose-model').text($(this).text());
        $dropdown.toggleClass('active');
        function updateImages($c) {
            $c.find('.scroller .model-view-container').each(function(e) {
                var $this = $(this);
                var details = $this.data('model-details');
                if (details && details[model]) {
                    $this.find('img').attr('src', details[model].modelImage);
                    if(details[model].modelVideo){
                        if (!$this.find('video').length) {
                            $this.prepend(`<video loop muted playsinline><source type="video/mp4"/></video>`);
                        }
                        $this.find('source').attr('src', details[model].modelVideo);
                        if ($('.menu-toggle').is(':visible')) {
                            $this.find('video').get(0).play();
                        }
                    } else {
                        $this.find('video').remove();
                    }
                }
            });
        }
        updateImages($container);
        updateImages($drawerContainer);
    });

    // slide prev button
    $container.off('click.prev').on('click.prev', 'button.prev', function() { 
        var scrollAmount = getScrollAmount(); 
        scrollBy($container, -scrollAmount, 600); 
    });

    // slide next button
    $container.off('click.next').on('click.next', 'button.next', function () {
        var scrollAmount = getScrollAmount(); 
        scrollBy($container, scrollAmount, 600); 
    });

    if ($container.find('img').length > 2) {
        $container.addClass('active');
    }

    var timeOutId;
    $('.scroller').off('scroll.scrollbar-visibility').on('scroll.scrollbar-visibility', function() {
        clearTimeout(timeOutId);
        $('[x-ref=scroller]').addClass('is-scrolling');

        timeOutId = setTimeout(function() {
            $('[x-ref=scroller]').removeClass('is-scrolling');
        }, 300)
    });

    initApplyFilters($container);
    detectAppliedFilters($container);
    bindScrollArrows($container);
}

exports.initHeaderBannerCategory = initHeaderBannerCategory;
exports.initDrawerBannerCategory = initDrawerBannerCategory;
