'use strict';

var dialog = require('../dialog'),
    util = require('../util'),
    quickview = require('../quickview'),
    registration = require('./registration'),
    bluecore = require('../bluecore');

/**
 * @function
 * @description Initialize events for slider
 */
function sflSliderInit() {
    var sflSlider = $('.sfl-items-list').not('.slick-initialized');
    $(sflSlider).slick({
        infinite: false,
        arrows: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        slide: '.sfl-item-3',
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    slide: '.sfl-item-2'
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false,
                    slide: '.sfl-item-3'
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    slide: '.sfl-item-2'
                }
            }
        ]
    });
}
/**
 * @function
 * @description Launches the SFL error modal (maximum number of products already in Save For Later)
 */
function launchQtyExceededModal(plid) {
    var url = util.appendParamsToUrl(Urls.sflQuantityExceeded, {plid: plid});
    dialog.open({
        url: url,
        options: {
            width: 375,
            dialogClass: 'sfl-max',
            open: function() {
                $(this).find('.product-usd').removeClass('hide-USD');
            }
        }
    });
}
/**
 * @function
 * @description Checks if this is an SFL redirect and completes cart action if required
 */
function initSflRedirectActions() {
    const isSFLredirect = $('#sflRedirect').val() === 'true';
    
    // window.User.sflMaxed will only be true if a guest customer attempted to add a product to SFL, 
    // then logged into an account that already has max number of SFL items in list
    if (isSFLredirect && window.User.authenticated && window.User.sflMaxed) {
        // trigger the SFL error modal
        $('[data-uuid="' + window.User.sflMaxed + '"]').find('.save-for-later').trigger('click');
    }
    
    // window.User.sflEdit will only be true if an unauthenticated customer attempted to edit an SFL product, 
    // and has been returned to the cart after login
    if (isSFLredirect && window.User.authenticated && window.User.sflEdit) {
        // remove the saved PLID from session
        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: util.appendParamToURL(Urls.sflSetEditItem),
            success: function () {
                $('html,body').animate({scrollTop: ($('.save-for-later-container').offset().top)-150}, 500);
                // trigger the edit product modal for that product
                $('[data-plid="' + window.User.sflEdit + '"]').find('.item-edit-details').trigger('click');
            }
        });
    }

    // sessionStorage.sflWishlistItem will contain a PLID value if an unauthenticated customer attempted to save an SFL product to their wishlist, 
    // and has been returned to the cart after login
    if (isSFLredirect && window.User.authenticated && sessionStorage.sflWishlistItem) {
        $('html,body').animate({scrollTop: ($('.save-for-later-container').offset().top)-150}, 500);
        $('.sfl-item[data-plid="' + sessionStorage.sflWishlistItem + '"]').find('.add-to-favorites').trigger('click');
        // remove the saved PLID from session
        sessionStorage.sflWishlistItem = false;
    }
    
    // if this is not a redirect from login after an SFL action, clean up SFL-related session attributes
    if (!isSFLredirect) {
        window.User.sflMaxed = false;
        window.User.sflEdit = false;
        sessionStorage.sflWishlistItem = false;
    }
}
exports.init = function () {
    $('.sfl-disabled').click(function () {
        var $cartRow = $(this).closest('tr');
        if(window.User.authenticated) {
            launchQtyExceededModal($cartRow.data('uuid'));
        }
    });
    
    $('.sfl-items-list').on('click', '.item-edit-details', function (e) {
        e.preventDefault();
        if (window.User.authenticated) {
            quickview.show({
                url: $(this).data('href'),
                source: 'cart'
            });
        } else {
            // save the PLID for the item an unauthenticated customer is trying to edit, then redirect to login
            $.ajax({
                type: 'GET',
                dataType: 'html',
                url: util.appendParamToURL(Urls.sflSetEditItem, 'editPlid', $(this).closest('.sfl-item').data('plid')),
                success: function () {
                    window.location.assign(util.appendParamToURL(Urls.loginShow, 'original', Urls.sflLoginReturn));
                }
            });
        }
    });
    
    var isEvents = require('../interaction-studio-events');
    $('.sfl-item a.add-to-cart').on('click', function() {
        var pid = $(this).data('productid'),
            count = 1,
            salesprice = $(this).parents('.sfl-item-details').find('.price-sales').data('price');
        var variantData = $(this).parents('.sfl-item-details').find('input[name=variantData]').val() || '';
        if (variantData !== null && variantData !== undefined && variantData !== '') {
            try {
                variantData = JSON.parse(variantData);
            } catch (error) {
                variantData = {};
            }
        } else {
            variantData = {};
        }
        var sku ='';
        var styleColor = '';
        var priceLocal = '';
        var variant = '';
        if (variantData && variantData.sku) {
            sku = variantData.sku;
        }
        if (variantData && variantData.priceLocal) {
            priceLocal = variantData.priceLocal;
        }
        if (variantData && variantData.variant) {
            variant = variantData.variant;
        }
        if (variantData && variantData.styleColor) {
            styleColor = variantData.styleColor;
        }

        isEvents.moveToBag(pid, count, salesprice, priceLocal, sku, variant, styleColor);

        bluecore.pushAddItem($(this).data('productid'), $(this).data('masterproductid'));

    });

    $('.sfl-user-actions a.remove').on('click', function() {
        var pid = $(this).data('masterproductid');
        var count = 1;
        var salesprice = $(this).parents('.sfl-item-details').find('.price-sales').data('price');
        isEvents.removeCartItem(pid, count, salesprice, true);
    });

    registration.init();
    sflSliderInit();
    initSflRedirectActions();
};
