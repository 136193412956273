'use strict';

var _ = require('lodash'),
    tagmanager = require('googletags/tagmanager');

var $form, $continue, $requiredInputs, validator, fakeContinue;
var isBilling = false;

var hasEmptyRequired = function () {
    // filter out only the visible fields
    var requiredValues = $requiredInputs.filter(':visible').map(function () {
        return $(this).val();
    });
    return _(requiredValues).includes('');
};

var validateForm = function () {
    // only validate form when all required fields are filled to avoid
    // throwing errors on empty form
    if (!validator) {
        return;
    }
    if (!hasEmptyRequired()) {
        if (validator.form()) {
            $continue.removeAttr('disabled');
            fakeContinue.removeClass('disabled').removeAttr('disabled');
            fakeContinue.attr('aria-disabled', 'false');
        }
    } else {
        $continue.attr('disabled', 'disabled');
        fakeContinue.addClass('disabled');
        fakeContinue.attr('aria-disabled', 'true');
    }
    if ($('.no-shipping-methods').size() > 0) {
        $continue.attr('disabled', 'disabled');
        fakeContinue.addClass('disabled');
        fakeContinue.attr('aria-disabled', 'true');
    }
    if (isBilling) {
        if ($('#noPaymentNeeded').val() == 'true') {
            $continue.removeAttr('disabled');
            fakeContinue.removeClass('disabled');
            fakeContinue.attr('aria-disabled', 'false');
        } else if ($('input[name$="_selectedPaymentMethodID"]:checked').val()){
            $continue.removeAttr('disabled');
            fakeContinue.removeClass('disabled');
            fakeContinue.attr('aria-disabled', 'false');
        } else {
            $continue.attr('disabled', 'disabled');
            fakeContinue.addClass('disabled');
            fakeContinue.attr('aria-disabled', 'true');
        }
    }
};

var validateEl = function () {
    if ($(this).val() === '') {
        $continue.attr('disabled', 'disabled');
        fakeContinue.addClass('disabled');
        fakeContinue.attr('aria-disabled', 'true');
    } else {
        // enable continue button on last required field that is valid
        // only validate single field
        if (validator.element(this) && !hasEmptyRequired()) {
            $continue.removeAttr('disabled');
            fakeContinue.removeClass('disabled');
            fakeContinue.attr('aria-disabled', 'false');
        } else {
            $continue.attr('disabled', 'disabled');
            fakeContinue.addClass('disabled');
            fakeContinue.attr('aria-disabled', 'true');
        }
    }
    if ($('.no-shipping-methods').size() > 0) {
        $continue.attr('disabled', 'disabled');
        fakeContinue.addClass('disabled');
    }
    if (isBilling) {
        if ($('#noPaymentNeeded').val() == 'true') {
            $continue.removeAttr('disabled');
            fakeContinue.removeClass('disabled');
            fakeContinue.attr('aria-disabled', 'false');
        } else if ($('input[name$="_selectedPaymentMethodID"]:checked').val()){
            $continue.removeAttr('disabled');
            fakeContinue.removeClass('disabled');
            fakeContinue.attr('aria-disabled', 'false');
        } else {
            $continue.attr('disabled', 'disabled');
            fakeContinue.addClass('disabled');
            fakeContinue.attr('aria-disabled', 'true');
        }
    }
};

/*
 * clearAddressForm - clears address fields, except country
 * does not affect selections for other fields on shipping/billing form, like gift options, Use for Billing, email opt-in or CC fields
 */
var clearAddressForm = function () {
    // clear the value for all applicable address fields
    var addressFields = $form.find('[name*="_addressFields"]');
    for (var i=0; i < addressFields.length; i++) {
        var field = addressFields[i];
        if ($(field).is('[name$="_country"]')) {
            continue;
        }
        $(field).val('');
    }
    // reset the value of the address list dropdown, if this is a registered customer with saved addresses
    $('.clear-status').text(Resources.SUCCESS_CLEAR_FIELD);
    $form.find('[name$="_addressList"]').val('');
}

function errorGA360 () {
    try {
        var ga360Error = $('#ga360Error').val();
        if (ga360Error && ga360Error.length) {
            ga360Error = JSON.parse(ga360Error);
            tagmanager.pushToDataLayer({ecommerce: null});
            tagmanager.pushToDataLayer({
                'event': 'error_occurred',
                'error_code': ga360Error.code,
                'error_msg_tech': (ga360Error.code === ga360Error.message) ? undefined : ga360Error.message
            });
        }
    } catch (e) {
        window.console.log(e);
    }
}

var init = function (opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    if (opts.isBilling && opts.isBilling == true) {
        isBilling = true;
    }
    $form = $(opts.formSelector);
    $continue = $(opts.continueSelector);
    fakeContinue = $(opts.fakeContinue);
    validator = $form.validate();
    $requiredInputs = $('.required', $form).find(':input');
    validateForm();
    // start listening
    $requiredInputs.on('change', validateEl);
    $requiredInputs.filter('input').on('blur', _.debounce(validateEl, 200));
    // clear form button
    $('.clear-address-form').on('click', function(e) {
        e.preventDefault();
        clearAddressForm();
    });
    errorGA360();
};

exports.init = init;
exports.validateForm = validateForm;
exports.validateEl = validateEl;
