module.exports = function() {
    $('.eswGetPrice').each(function () {
        var el = $(this);
        var url = el.data('url');
    
        $.ajax({
            url : url,
            success : function (response) {
                el.replaceWith(response);
            }
        });
    });
};
