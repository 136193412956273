'use strict';

var _ = require('lodash'),
    dialog = require('../dialog'),
    TPromise = require('promise'),
    util = require('../util'),
    page = require('../page'),
    tooltip = require('../tooltip'),
    progress = require('../progress'),
    StoreHelper = require('../store'),
    templates = require('../templates');

var newLine = '\n';
var storeTemplate = function (store, selectedStoreId, selectedStoreText) {
    let hours = '';
    if (store.hours && store.hours.length > 0) {
        hours += '<p class="store-tile-hours">';
        for (var i = 0; i < store.hours.length; i++) {
            var hrsText = store.hours[i];
            hours += hrsText.replace(/-0?/, ' - ').replace() + '<br/>';
        }
        hours += '</p>';
    }
    let availability = '';
    if (store.quantity > 0 && store.status.indexOf('SDP') > -1) {
        if (store.limited) {
            availability = '<p class="store-tile-availability limited" role="text">' + Resources.ISPU_ONLY + ' ' + store.quantity + ' ' + Resources.ISPU_UNITSINSTOCK + '</p>';
        } else {
            availability = '<p class="store-tile-availability" role="text">' + Resources.ISPU_INSTOCK + '</p>';
        }
    }
    return [
        `<li class="store-tile ${store.storeId} ${store.storeId == selectedStoreId ? ' selected' : ''} ${store.status === 'NA' ? ' unavailable' : ''} ${store.status === 'SDP' ? ' available-today' : ''}">
            <h4 class="store-name">${store.name}</h4>
            <p class="store-distance">(${store.distance} ${Resources.MILES_ABBREV})</p>
            <p class="store-address">${store.address1}
                <span class="down-arrow"></span>
            </p>
            <div class="store-address-info">
                <p>${store.city}, ${store.stateCode} ${store.postalCode}</p>
                <p><a target="_blank" href="https://google.com/maps/?q=${store.lat},${store.long}" title=${Resources.GET_DIRECTIONS}>${Resources.GET_DIRECTIONS}</a></p>
                ${hours}
            </div>
            ${availability}
            <p class="store-status ${store.status}" role="text">${Resources['ISPU_' + store.status]}</p>
            <button class="select-store-button ${store.storeId == selectedStoreId ? ' selected-store' : ''}"
                    data-store-id="${store.storeId}"
                    data-city="${store.city}"
                    data-address="${store.address1}"
                    data-country-code="${store.countryCode}"
                    ${store.status === 'NA' ? 'disabled=disabled' : ''}>
                        ${store.storeId == selectedStoreId ? selectedStoreText : Resources.SELECT_STORE}
            </button>
        </li>`
    ].join(newLine);
};

var storeListTemplate = function (stores, selectedStoreId, selectedStoreText, pid, title) {
    title = title || Resources.STORE_NEAR_YOU;
    if (stores && stores.length) {
        stores = stores.length > 6 ? stores.slice(0,6) : stores;
        var oneStoreMsg = window.User.storeId ? Resources.ISPU_ONE_STORE_ONLY : '';
        return [
            '<div id="preferred-store-panel" class="store-inventory-search store-list-template">',
            '  <h2 id="dialog-title">' + title + '</h2>',
            '  <p class="single-store-msg">' +oneStoreMsg + '</p>',
            '  <div class="zip-retry-form-row">',
            '    <div class="zip-retry-input-wrapper">',
            '      <label for="zipCode" title="Zip code" class="visually-hidden">Zip code</label>',
            '      <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode" aria-label="Zip code"/>',
            '      <input type="hidden" id="ispu-pid" name="ispu-pid" value="' +pid+ '"/>',
            '    </div>',
            '    <button class="search-by-user-zip inventory-search-by-user-zip">' + Resources.SEARCH_ANOTHER_ZIP + '</button>',
            '  </div>',
            '  <div class="zip-sort-options">',
            '    <span class="zip-sort-results-label">' + Resources.SORT_ZIP_RESULTS_BY + '</span>',
            '    <div class="form-row label-inline zip-sort-option-item zip-sort-results-nearby">',
            '      <input id="zip_sort_option_nearby" type="radio" class="zip-sort-option" name="zip_sort_options" value="nearby" checked="checked">',
            '      <label for="zip_sort_option_nearby">' + Resources.SORT_ZIP_OPTION_NEARBY + '</label>',
            '    </div>',
            '    <div class="form-row label-inline zip-sort-option-item zip-sort-results-pickup-asap">',
            '      <input id="zip_sort_option_pickup_asap" type="radio" class="zip-sort-option" name="zip_sort_options" value="pickup_asap">',
            '      <label for="zip_sort_option_pickup_asap">' + Resources.SORT_ZIP_OPTION_PICKUP_ASAP + '</label>',
            '    </div>',
            '  </div>',
            '<div class="store-list-container">',
            '  <ul class="store-list">',
            _.map(stores, function (store) {
                return storeTemplate(store, selectedStoreId, selectedStoreText);
            }).join(newLine),
            '  </ul>',
            '</div>',
            '<div class="store-list-fine-print">' + Resources.ISPU_PICKUP_MSG + '</div>'
        ].join(newLine);
    } else if (User.previousStores && User.previousStores.length) {
        return retryWithStoresTemplate(User.previousStores, selectedStoreId, selectedStoreText);
    } else {
        return retryTemplate();
    }
};

var initStoreTiles = function () {
    var $asapPickups = $('.ispu-select-store').find('.available-today');
    if ($asapPickups.length) {
        $asapPickups.last().addClass('last-available-today');
    } else {
        $('.ispu-select-store').find('.store-list-container').append('<p class="error-none-available-today">' + Resources.NOT_AVAILABLE_TODAY + '</p>')
    }
    $('.ispu-select-store').on('click', '.store-address .down-arrow', function () {
        var $expandableArea = $(this).parent().next('.store-address-info');
        $(this).toggleClass('expanded');
        if ($(this).hasClass('expanded')) {
            $expandableArea.show(200);
        } else {
            $expandableArea.hide(200);
        }
    });
    $('.ispu-select-store').on('change', '.zip-sort-option', function () {
        if ($(this).val() === 'pickup_asap') {
            $('.store-list-container').addClass('pickup-asap');
        } else {
            $('.store-list-container').removeClass('pickup-asap');
        }
    });
};

var retryWithStoresTemplate = function(stores, selectedStoreId, selectedStoreText) {
    var prevStores = stores.length > 6 ? stores.slice(0,6) : stores;
    var oneStoreMsg = window.User.storeId ? Resources.ISPU_ONE_STORE_ONLY : '';
    return  [
        '<div id="preferred-store-panel" class="store-inventory-search zip-retry">',
        '     <h2 id="dialog-title">' + Resources.STORE_NEAR_YOU + '</h2>',
        '     <p class="single-store-msg">' + oneStoreMsg + '</p>',
        '     <div class="zip-retry-form-row">',
        '     <div class="zip-retry-inpu-wrapper">',
        '           <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode" aria-label="Zip code"/>',
        '     </div>',
        '     <button class="search-by-user-zip inventory-search-by-user-zip">' + Resources.SEARCH_ANOTHER_ZIP + '</button>',
        '     </div>',
        '</div>',
        '<div class="no-results">' + Resources.TRY_ANOTHER_ZIP + '</div>',
        '<div class="store-list-container">',
        '<ul class="store-list">',
        _.map(prevStores, function (store) {
            return storeTemplate(store, selectedStoreId, selectedStoreText);
        }).join(newLine),
        '</ul>',
        '</div>'
    ].join(newLine);
}

var retryTemplate = function(message) {
    message = message || Resources.TRY_ANOTHER_ZIP
    return  [
        '<div id="preferred-store-panel" class="store-inventory-search zip-retry zip-retry-template">',
        '     <h2 id="dialog-title">' + Resources.STORE_NEAR_YOU + '</h2>',
        '     <div class="zip-retry-form-row">',
        '     <div class="zip-retry-inpu-wrapper">',
        '           <label for="zipCode" title="Zip code" class="visually-hidden">Zip code</label>',
        '           <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode" aria-label="Zip code"/>',
        '     </div>',
        '     <button class="search-by-user-zip inventory-search-by-user-zip">' + Resources.SEARCH_ANOTHER_ZIP + '</button>',
        '     </div>',
        '</div>','<div class="no-results">' + message + '</div>'
    ].join(newLine);
}

var zipPromptTemplate = function () {
    return [
        '<div id="preferred-store-panel" class="store-inventory-search zip-prompt-template">',
        '     <h2 id="dialog-title">' + Resources.STORE_NEAR_YOU + '</h2>',
        '      <p>' + Resources.ENTER_ADDRESS_INFO + '</p>',
        '    <label for="zipCode" title="Zip code" class="visually-hidden">Zip code</label>',
        '    <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode" aria-label="Zip code"/>',
        '</div>'
    ].join(newLine);
};

var enterNewZipTemplate = function () {
    return [
        '<div id="preferred-store-panel" class="store-inventory-search zip-enter-new-zip">',
        '     <h3 class="get-it-by-hed">' + Resources.GETBY_ZIPDIALOG_HED + '</h3>',
        '     <p class="get-it-by-dek">' + Resources.GETBY_ZIPDIALOG_LABEL + '</p>',
        '     <label for="zipCode" title="Zip code" class="visually-hidden">Zip code</label>',
        '     <input type="text" id="user-zip" placeholder="' + Resources.GETBY_ZIPDIALOG_PLACEHOLDER + '" name="zipCode" aria-label="Zip code"/>',
        '     <button id="get-it-by-update-zip" type="button">' + Resources.GETBY_ZIPDIALOG_BUTTON + '</button>',
        '</div>'
    ].join(newLine);
};

var showServiceError = function () {
    var $container = $('.store-list-container'),
        message = Resources.TRY_LATER,
        messageHtml = '<div class="no-results">' + message + '</div>'
    $container.removeClass('.loading-template');
    $container.empty().append(messageHtml);
    storeinventory.checkStoreSelection();
}

var storeinventory = {
    setPreferredStore: function (storeId) {
        User.storeId = storeId;
        $.ajax({
            url: Urls.setPreferredStore,
            type: 'POST',
            data: {storeId: storeId}
        });
        $('#checkStoreInventory').show();
    },
    zipPrompt: function (callback) {
        var self = this;
        dialog.open({
            html: zipPromptTemplate(),
            options: {
                modal: true,
                width: 'auto',
                buttons: [{
                    text: Resources.SEARCH,
                    click: function () {
                        var zipCode = $('#user-zip').val();
                        if (StoreHelper.validateZipCode(zipCode)) {
                            StoreHelper.setUserZip(zipCode);
                            self.updateGetItByHtml(zipCode);
                            if (callback) {
                                callback(zipCode);
                            }
                        }
                    }
                }],
                open: function () {
                    $('#user-zip').on('keypress', function (e) {
                        if (e.which === 13) {
                            // trigger the search button
                            $('.ui-dialog-buttonset .ui-button').trigger('click');
                        }
                    });
                }
            }
        });
    },
    enterNewZip: function (callback) {
        var self = this;
        dialog.open({
            html: enterNewZipTemplate(),
            options: {
                modal: true,
                width: 'auto',
                dialogClass: 'get-it-by-dialog',
                open: function () {
                    $('#get-it-by-update-zip').on('click', function () {
                        var zipCode = $('#user-zip').val();
                        if (StoreHelper.validateZipCode(zipCode)) {
                            StoreHelper.setUserZip(zipCode);
                            self.updateGetItByHtml(zipCode);
                            $('body').trigger('trigger:hybridpdpheight');
                            if (callback) {
                                callback(zipCode);
                            }
                            dialog.close();
                        }
                    });
                    $('#user-zip').on('keypress', function (e) {
                        if (e.which === 13) {
                            // trigger the submit button
                            $('#get-it-by-update-zip').trigger('click');
                        }
                    });
                }
            }
        });
    },
    updateGetItByHtml: function (zip) {
        if ($('#getitbycontainer').length === 0) return;
        progress.show($('#getitbycontainer'));
        new window.google.maps.Geocoder().geocode({
            address: zip
        }).then((result) => {
            let stateCode = '';
            if (result.results.length > 0) {
                result.results[0].address_components.forEach((element) => {
                    if (element.types.indexOf('administrative_area_level_1') !== -1) {
                        stateCode = element.short_name;
                    }
                });
            }
            $.ajax({
                url: util.appendParamsToUrl(Urls.getItBy, {
                    pid: $('input#pid').val(),
                    isExcludedProduct: $('#getitbycontainer').data('isexcluded'),
                    zip: zip,
                    stateCode: stateCode
                }),
                dataType: 'html',
                success: function(data) {
                    if (data) {
                        $('#getitbycontainer').html(data);
                        var $personalizationDetails = $('.personalization-details');
                        if ($personalizationDetails.length && !$personalizationDetails.hasClass('hidden')) {
                            $('.get-it-by-all-info').addClass('get-it-by-monogramming-enabled');
                        }
                        storeinventory.initGetItByTooltip();
                        storeinventory.updateLocalGetItByCutOffTime();
                    }
                },
                always: function () {
                    progress.hide();
                }
            });
        }).catch((e) => {
            console.log('Geocode was not successful for the following reason: ' + e); // eslint-disable-line
            progress.hide();
        });
    },
    zipRetry: function (callback, pid, selectedStoreText, selectedStoreId) {
        var self = this;
        dialog.open({
            html: User.previousStores && User.previousStores.length ? retryWithStoresTemplate(User.previousStores, selectedStoreId, selectedStoreText) : retryTemplate(),
            options: {
                modal: true,
                dialogClass: 'ispu-select-store ispu-zip-retry',
                width: 'auto',
                buttons: [{
                    click: function () {
                        var zipCode = $('#user-zip').val();
                        if (StoreHelper.validateZipCode(zipCode)) {
                            StoreHelper.setUserZip(zipCode);
                            self.updateGetItByHtml(zipCode);
                            if (callback) {
                                callback(zipCode);
                                dialog.close();
                            }
                        }
                    }
                }],
                open: function () {
                    self.updateZipcodeField();
                    $('.zip-retry-form-row button').on('click', function() {
                        $('.ui-dialog-buttonset .ui-button').trigger('click');
                    });

                    initStoreTiles();

                    $('#user-zip').on('keypress', function (e) {
                        if (e.which === 13) {
                            // trigger the search button
                            $('.ui-dialog-buttonset .ui-button').trigger('click');
                        }
                    });

                    $('.select-store-button').on('click', function (e) {
                        e.preventDefault();
                        var storeId = $(this).data('storeId');

                        // if the store is already selected, don't select again
                        if (storeId === selectedStoreId) { return; }
                        $('.store-list .store-tile.selected').removeClass('selected')
                            .find('.select-store-button').text(Resources.SELECT_STORE);
                        $(this).text(selectedStoreText)
                            .closest('.store-tile').addClass('selected');

                        var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');

                        if (window.pageContext.ns == 'cart') {
                            var $plis = $('.cart-row');
                            $plis.each(function() {
                                pid = $(this).find('a.set-preferred-store').data('pid');
                                storeinventory.updateFooterInfo(newStoreId, pid);
                            });
                        } else {
                            var isMonogrammed = false;
                            var personalizedOption = $('[data-optionid="isPersonalized"]').find(':selected').attr('value');
                            if (personalizedOption != null && typeof personalizedOption != 'undefined') {
                                if ($('[data-optionid="isPersonalized"]').find(':selected').attr('value').toLowerCase() == 'yes') {
                                    isMonogrammed = true;
                                }
                            }
                            storeinventory.updateFooterInfo(newStoreId, pid, isMonogrammed);
                            self.setAddToCartAttributes(newStoreId, User.previousStores);
                        }
                        dialog.close();
                    });
                }
            }
        });

    },
    getStoresInventory: function (pid) {
        return TPromise.resolve($.ajax({
            url: util.appendParamsToUrl(Urls.storesInventory, {
                pid: pid,
                zipCode: User.zip
            }),
            dataType: 'json'
        }));
    },
    getStoreInventory: function (pid,storeId) {
        return TPromise.resolve($.ajax({
            url: util.appendParamsToUrl(Urls.getStoreInventory, {
                pid: pid,
                storeId: storeId
            }),
            dataType: 'json'
        })).then(function (data) {
            if (data && data.status) {
                var statusMsg = '<div class="isavail ' +data.status+ '" data-status="' +data.status+ '">' +Resources['ISPU_' + data.status]+ '</div>'
                $('#checkStoreInventory').hide();
                $('.store-info-wrap').append(statusMsg);
            }
        });
    },
    updateZipcodeField: function () {
        var zipcode = null
        $.ajax({
            type: 'GET',
            url: Urls.getZipCode,
            success: function (data) {
                // if no data object is included in the response, return here to prevent JS errors
                if (!data || !data[0]) { return; }
                zipcode = data[0].zip;
                $('input#user-zip').val(zipcode);
                if (zipcode == null || typeof zipcode == 'undefined') {
                    $.ajax({
                        type: 'GET',
                        url: Urls.getGeolocation,
                        success: function (data) {
                            if (data && data.location) {
                                zipcode = data.location.zip;
                                $('input#user-zip').val(zipcode);
                            }
                        }
                    });
                }
            }
        });
    },
    /**
     * @description open the dialog to select store
     * @param {Array} options.stores
     * @param {String} options.selectedStoreId
     * @param {String} options.selectedStoreText
     * @param {Function} options.continueCallback
     * @param {Function} options.selectStoreCallback
     **/
    selectStoreDialog: function (options) {
        var self = this;
        var stores = options.stores,
            selectedStoreId = options.selectedStoreId,
            selectedStoreText = options.selectedStoreText,
            pid = options.pid;
        var titleIfExisting = options.title ? options.title : dialog && dialog.isActive() ? dialog.$container.find('#dialog-title').text() : undefined;
        var storeList = storeListTemplate(stores, selectedStoreId, selectedStoreText, pid, titleIfExisting);
        if (stores && stores.length) {
            if (stores.indexOf('empty') > -1) {
                dialog.open({
                    html: retryTemplate(Resources.TRY_LATER),
                    options: {
                        height: 'auto'
                    }
                });
                return;
            }
            dialog.open({
                html: storeList,
                options: {
                    modal: true,
                    width: '100%', // CSS to handle max width
                    dialogClass: 'ispu-select-store',
                    close: function () {
                        var isPDP = $('.pt_product-details').length;
                        var isStoreUnchanged = User.storeId == selectedStoreId ? true : false;
                        // only handle store selection if a new store has been chosen
                        if (!isStoreUnchanged) {
                            storeinventory.checkStoreSelection();
                        }
                        if (!User.storeId) {
                            $('input[value="home"]').trigger('click');
                            $('.set-preferred-store').not('.hidden').addClass('hidden');
                            if (isPDP) {
                                $('.swatchanchor').each(function(){
                                    var href = $(this).attr('href').replace('&ispu=true', '');
                                    $(this).attr('href', href);
                                });
                            }
                        }
                        if ('ApplePaySession' in window && !isPDP && !isStoreUnchanged) {
                            // we need to refresh in order to update the Apple Pay session based on delivery option selection
                            page.refresh();
                        }
                    },
                    title: titleIfExisting,
                    open: function () {
                        initStoreTiles();
                        $('.select-store-button').off('click.select-store').on('click.select-store', function (e) {
                            e.preventDefault();
                            var storeId = $(this).data('storeId');
                            var isPDP = $('.pt_product-details').length;
                            // if the store is already selected, don't select again
                            if (storeId === selectedStoreId) { return; }
                            $('.store-list .store-tile.selected').removeClass('selected')
                                .find('.select-store-button').text(Resources.SELECT_STORE);
                            $(this).text(selectedStoreText)
                                .closest('.store-tile').addClass('selected');
                            var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');
                            User.storeId = newStoreId;
                            $('#checkStoreInventory').show();

                            if (options.continueCallback) {
                                options.continueCallback();
                            }

                            var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');
                            if (window.pageContext.ns === 'cart') {
                                var $plis = $('.cart-row');
                                $plis.each(function() {
                                    var isMonogrammed = $(this).hasClass('personalized');
                                    pid = $(this).find('[data-pid]').eq(0).data('pid');
                                    var plid = $(this).find('[data-uuid]').eq(0).data('uuid');
                                    storeinventory.updateFooterInfo(newStoreId, pid, isMonogrammed, plid);
                                });
                            } else {
                                var isMonogrammed = false;
                                var personalizedOption = $('[data-optionid="isPersonalized"]').find(':selected').attr('value');
                                if (personalizedOption != null && typeof personalizedOption != 'undefined') {
                                    if ($('[data-optionid="isPersonalized"]').find(':selected').attr('value').toLowerCase() == 'yes') {
                                        isMonogrammed = true;
                                    }
                                }
                                storeinventory.updateFooterInfo(newStoreId, pid, isMonogrammed);
                                self.setAddToCartAttributes(newStoreId, stores);
                            }
                            if (User.storeId) {
                                $('.set-preferred-store a, a.set-preferred-store, .js-set-preferred-store.set-preferred-store').html('Change Store');
                                if (!isPDP) {
                                    // retrigger the ISPU selection for lines that were previously forced to Home because selected store status was NA
                                    var $cartLines = $('.selected-store-availability[data-status="NA"]').closest('tr');
                                    $cartLines.each(function() {
                                        if (!$(this).find('input[value="store"]').is(':checked')) {
                                            $(this).find('input[value="store"]').trigger('click');
                                        }
                                    });
                                }
                            }
                            $('body').trigger('trigger:hybridpdpheight');
                            dialog.close();
                        });

                        const bindSearchByZip = function() {
                            $('.store-inventory-search .search-by-user-zip').off('click.inventory-search').on('click.inventory-search', function (e) {
                                // there's another binding in store.js that would trigger unless we prevent it
                                e.stopPropagation();

                                var zipCode = $('#user-zip').val();
                                var loadingOptions = {
                                    modal: true,
                                    width: 'auto',
                                    height: 'auto',
                                    dialogClass: 'ispu-select-store'
                                };

                                if (StoreHelper.validateZipCode(zipCode)) {
                                    User.previousStores = stores;

                                    dialog.open({
                                        html: templates.findStoreTemplate(null, 'store-inventory-search'),
                                        options: loadingOptions
                                    });
                                    StoreHelper.setUserZip(zipCode);
                                    self.selectStore(pid);

                                    // once the "searching dialog" we need to rebind
                                    // the search-by-zip click, otherwise
                                    // the user would get stuck if the zipcode backend service fails
                                    // or something like that
                                    bindSearchByZip()
                                }
                            });
                        }

                        bindSearchByZip();

                        $('.search-new-zip button').off('click.search-zip').on('click.search-zip', function(e) {
                            e.stopPropagation();

                            var zipCode = $('#user-zip').val();
                            if (StoreHelper.validateZipCode(zipCode)) {
                                StoreHelper.setUserZip(zipCode);
                                self.updateGetItByHtml(zipCode);
                                if (options.reSearchCallback) {
                                    dialog.close();
                                    options.reSearchCallback(zipCode);
                                }
                            }
                        });

                        $('#user-zip').on('keypress', function (e) {
                            if (e.which === 13) {
                                // trigger the search button
                                var zipCode = $('#user-zip').val();
                                if (StoreHelper.validateZipCode(zipCode)) {
                                    StoreHelper.setUserZip(zipCode);
                                    self.updateGetItByHtml(zipCode);
                                    if (options.reSearchCallback) {
                                        dialog.close();
                                        options.reSearchCallback(zipCode);
                                    }
                                }
                            }
                        });
                        $('#user-zip').on('keypress', function (e) {
                            if (e.which === 13) {
                                // trigger the search button
                                $('.search-by-user-zip').trigger('click');
                            }
                        });
                        // on cart page, populate the hidden input field with the pid for use when searching by another zip code
                        if (typeof pid != 'undefined') {
                            $('.pt_cart input#pid').val(pid);
                        }

                        self.updateZipcodeField();
                    }
                }
            });
        } else {
            self.zipRetry(function() {
                storeinventory.selectStore();
            }, pid, selectedStoreText, selectedStoreId);
        }
    },
    shippingLoad: function () {
        var $checkoutForm = $('.address');
        $checkoutForm.off('click');
        $checkoutForm.on('click', 'input[name$="_shippingAddress_isGift"]', function () {
            $(this).parent().siblings('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val());
        });
    },
    setAddToCartAttributes: function (storeId, stores) {
        var store;
        for (var i=0; i < stores.length; i++) {
            store = stores[i];
            if (store.storeId == storeId) {
                var availStatus = store.status.indexOf('STS') > -1 ? 'false' : 'true';
                $('#availableInStore').val(availStatus);
                $('#fromStoreId').val(store.storeId);
                if (store.status == 'SDP') {
                    $('#pickupDay').val('today');
                } else if (store.status == 'SDP_tomorrow') {
                    $('#pickupDay').val('tomorrow');
                } else {
                    $('#pickupDay').val('');
                }
                break;
            }
        }
    },
    updateFooterInfo: function (storeID, pid, isMonogrammed, plid) {
        var url = window.Urls.storeInfoFooter;
        if (storeID) {
            url += '?storeID=' + storeID;
            if (pid) {
                url += '&pid=' + pid;
            }
            if (plid) {
                url += '&plid=' + plid;
            }
            if (isMonogrammed) {
                url += '&isMonogrammed=true'
            }
        }

        if (window.pageContext.ns == 'product') {
            url = util.appendParamToURL(url, 'location', 'pdp');
        }
        $.get(url, function (data) {
            if (window.pageContext.ns == 'cart') {
                var $pli = $('button.set-preferred-store[data-pid="' +pid+ '"]').closest('tr'),
                    isHomeDelivery = $pli.find('.delivery-option:checked').val() == 'home';

                $pli.find('.store-info-wrap').replaceWith(data);
                if (isHomeDelivery) {
                    $pli.find('.selected-store-address, .selected-store-name, .isavail').addClass('hidden');
                }
                /*
                 * if this lineitem is not disabled for ISPU,
                 * remove the disabled attributes and classes form the In Store delivery option
                 * if this lineitem was previously selected for ISPU, attempt to restore that selection
                 */
                if ($pli.find('input[name="lineIsPickupDisabled"]').val() == 'false') {
                    $pli.find('.instore-delivery').removeClass('unavailable');
                    $pli.find('input[value="store"]').removeAttr('disabled').removeClass('unavailable');
                    if ($pli.find('input[name="lineAtCapacityFlag"]').val() == 'true') {
                        $pli.find('input[value="store"]').trigger('click');
                    }
                }
            } else if(window.pageContext.ns == 'product') {
                if ($('#pdpMain').find('.store-info-wrap').length == 0) {
                    $('#pdpMain').find('.in-store-pickup-error').after('<div class="store-info-wrap"></div>');
                }
                $('#pdpMain').find('.store-info-wrap').replaceWith(data);
                $('.ispuCapacityMessage:not(".hidden")').addClass('hidden');
                $('body').trigger('trigger:hybridpdpheight');
            } else {
                $('.store-info-wrap').replaceWith(data).addClass('hidden');
                $('.add-to-cart-wrap').addClass('no-flex');
                $('.delivery-options-wrap').removeClass('hidden');
                $('.availability-message-in-store-pickup').hide();
            }
        }).done(function(){
            if (window.pageContext.ns == 'product') {
                $('#checkStoreInventory').off('click').on('click', function(e) {
                    e.preventDefault();
                    var pid = $('#pid').val();
                    var storeId = User.storeId;
                    storeinventory.getStoreInventory(pid,storeId).done(function() {
                        if ($('.isavail').length < 1) {
                            $('.ispu-service-error').text(Resources.TRY_LATER);
                        }
                    });
                });
            }
        });
    },
    selectStore: function (reSearchPid) {
        var self = this;
        var params = null;
        var isMonogrammed = false;
        var personalizedOption = $('[data-optionid="isPersonalized"]').find(':selected').attr('value');
        if (personalizedOption != null && typeof personalizedOption != 'undefined') {
            if ($('[data-optionid="isPersonalized"]').find(':selected').attr('value').toLowerCase() == 'yes') {
                isMonogrammed = true;
            }
        }
        var pid = $('input#pid').val();
        if (reSearchPid) {
            pid = reSearchPid;
        }
        if (window.mapsLoaded) {
            var geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({'address': User.zip}, function (results, status) {
                if (status === window.google.maps.GeocoderStatus.OK && results[0].geometry.location) {
                    var location = results[0].geometry.location;
                    params = {
                        lat: location.lat(),
                        long: location.lng(),
                        pid: pid,
                        isMonogrammed: isMonogrammed
                    };
                    self.getStoresAjax(params);
                } else {
                    storeinventory.selectStoreDialog({
                        stores: [],
                        selectedStoreId: User.storeId,
                        selectedStoreText: Resources.PREFERRED_STORE,
                        pid: pid
                    });
                }
            });
        } else {
            params = {
                pid: pid,
                zipCode: User.zip,
                isMonogrammed: isMonogrammed
            };
            self.getStoresAjax(params);
        }
    },
    showStoresByGeolocation: function (lat, long, pid, personalized) {
        var self = this;
        var isMonogrammed;
        var personalizedOption = $('[data-optionid="isPersonalized"]').find(':selected').attr('value');
        if (personalizedOption != null && typeof personalizedOption != 'undefined') {
            isMonogrammed = personalized ? personalized : $('[data-optionid="isPersonalized"]').find(':selected').attr('value').toLowerCase() == 'yes';
        } else {
            isMonogrammed = personalized ? personalized : false;
        }
        var params = {
            lat: lat,
            long: long,
            pid: pid,
            isMonogrammed: isMonogrammed,
            shipToStore: true
        };

        $.ajax({
            url: util.appendParamsToUrl(Urls.loadByLatLong, params),
            dataType: 'json',
            success: function (stores) {
                storeinventory.selectStoreDialog({
                    stores: stores,
                    selectedStoreId: User.storeId,
                    selectedStoreText: Resources.PREFERRED_STORE,
                    pid: pid,
                    lat: params.lat,
                    long: params.long,
                    selectStoreCallback: function () {
                        var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');
                        storeinventory.updateFooterInfo(newStoreId, pid, params.isMonogrammed);
                        self.setAddToCartAttributes(newStoreId, stores);
                        self.setPreferredStore(newStoreId);
                        $('.change-store').removeClass('withoutAddress');
                    }
                });
                //if store is unavailable disable its select-store-button
                $('.store-tile.unavailable').find('.select-store-button').attr('disabled', 'disabled');
            },
            error: function () {
                return showServiceError();
            }
        });
    },
    getStoresAjax: function (params) {
        var self = this;
        $.ajax({
            url: util.appendParamsToUrl(Urls.storesInventory, params),
            dataType: 'json',
            success: function (stores) {
                storeinventory.selectStoreDialog({
                    stores: stores,
                    selectedStoreId: User.storeId,
                    selectedStoreText: Resources.PREFERRED_STORE,
                    pid: params.pid,
                    lat: params.hasOwnProperty('lat') ? params.lat : null,
                    long: params.hasOwnProperty('long') ? params.long : null,
                    zipCode: params.hasOwnProperty('zipCode') ? params.zipCode : null,
                    continueCallback: function () {
                        self.setAddToCartAttributes(User.storeId, stores);
                    },
                    reSearchCallback: function () {
                        storeinventory.selectStore();
                    },
                    selectStoreCallback: function () {
                        var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');
                        storeinventory.updateFooterInfo(newStoreId, params.pid, params.isMonogrammed);
                        self.setAddToCartAttributes(newStoreId, stores);
                        self.setPreferredStore(newStoreId);
                        $('.change-store').removeClass('withoutAddress');
                    }
                });
                //if store is unavailable disable its select-store-button
                $('.store-tile.unavailable').find('.select-store-button').attr('disabled', 'disabled');
            },
            error: function() {
                storeinventory.selectStoreDialog({
                    stores: [],
                    selectedStoreId: User.storeId,
                    selectedStoreText: Resources.PREFERRED_STORE,
                    pid: params.pid
                });
            }
        });
    },
    checkStoreSelection: function () {
        var isStoreSelected = User.storeId,
            isPDP = $('.pt_product-details').length;
        var params = {
            fromcart: $('.pt_cart').length > 0
        };
        if (!isStoreSelected) {
            // if no store is selected, change selection to home delivery
            $('input[value="home"]').prop('checked', true);
            if (isPDP) {
                $('a.set-preferred-store').addClass('hidden');
                $('.swatchanchor').each(function (){
                    var href = $(this).attr('href').replace(/&ispu=true/g,'');
                    $(this).attr('href', href);
                });
            }
        } else {
            $.ajax({
                url: util.appendParamsToUrl(Urls.setStorePickup, params),
                success: function () {
                    if (isPDP) {
                        if ($('.isavail').data('status') != 'NA') {
                            $('input[value="store"]').removeAttr('disabled');
                            $('input[value="store"]').trigger('click');
                            $('label.NA').removeClass('NA');
                        }
                    }
                }
            });
        }
    },
    initGetItByTooltip: function () {
        tooltip.create($(document).find('.get-it-by-tooltip'));
    },
    updateLocalGetItByCutOffTime: function () {
        if ($('.cutoff-time').length > 0) {
            var date = new Date(Number($('.cutoff-time').text()));
            var hour = date.toLocaleTimeString('en-us',{hour: 'numeric', timeZoneName: 'short'});
            hour = hour.replace(/(d*) (AM|PM)/, '$1$2'); // remove space between '9' and 'PM'. result = 9PM
            //time format will be like 9PM PST
            $('.localtime').html(hour);
        }
    },
    init: function () {
        $('.footer-container').on('click', '.preferred-store', function (e) {
            e.preventDefault();
            if (!User.zip) {
                storeinventory.zipPrompt(function () {
                    storeinventory.selectStore();
                });
            } else {
                storeinventory.selectStore();
            }
        });

        this.updateLocalGetItByCutOffTime();

        $('#product-content').on('click', '.set-zip-code', function (e) {
            e.preventDefault();
            storeinventory.enterNewZip();
        });
        $('body').on('click', function(){
            var widgetOverlay = $('.ispu-select-store + .ui-widget-overlay').length;
            if (widgetOverlay > 0) {
                $('body').addClass('dialog-open');
            } else {
                $('body').removeClass('dialog-open');
            }
        });
        var sizeSelected = $('.size .selectable.selected').length;
        var isPDP = $('.pt_product-details').length;
        if (isPDP && !sizeSelected > 0) {
            $('.change-store').addClass('withoutAddress');
        }
        //add a class for the error message so it can be repositioned if there is no store/address on the pdp
        var addressText = $('.selected-store-address').text();
        if (addressText == '') {
            $('.in-store-pickup-error').addClass('withoutAddress');
            $('.change-store').addClass('withoutAddress');
        }
        // notAvailForIspuMessage
        $('.notAvailForIspuMessage').each(function() {
            var notEligible = $(this),
                $cartRow = $(this).closest('tr');
            if (notEligible.length > 0) {
                $cartRow.find('.instore-delivery').addClass('notEligible');
            }
        })

        // init Get It By tooltip
        storeinventory.initGetItByTooltip();
    }
};

module.exports = storeinventory;
